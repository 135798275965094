// @flow
import React, { Component } from 'react';

import { Button, Grid, ImageList, ImageListItem, Typography  } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import ModalImage from "react-modal-image";
import type { Dispatch } from 'redux';

import { setCurrentScreen } from '../../app/actions';
import { setDisplayAddToCampaign } from '../../campaigns';
import { CURRENT_DISPLAYING_SCREEN } from '../../app/constants';
import { AddToCampaignDialog } from '../../favorites';
import { addToFavorites, setSelectedSpaceName } from '../../favorites/actions';
import { connect } from '../../redux';

const styles = theme => ({
    descriptionTitle: {
		color: '#878C9F',
    	fontWeight: '500',
	},
    gallery: {
		flexWrap: 'nowrap',
		minHeight: '100%',
		'& div:nth-child(1)': {
			height: '100%',
			minHeight: '100%',
			'& img': {
				objectFit: 'contain',
				width: '100%',
			}
		}
	},
	galleryContainer: {
		display: 'flex',
	    flexWrap: 'wrap',
	    justifyContent: 'space-around',
	    overflow: 'hidden',
	},
    left: {
		marginLeft: '2.5rem',
		textAlign: 'left',
	},
    main: {
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '1.7rem',
        paddingTop: '0.2rem',
        width: '79%'
    },
    mainContent:{
        paddingBottom: '7rem',
    },
    mt: {
        marginTop: '3.7rem',
    },
    multispaceImage: {
		// height: '100%',
	},
    multispaceResume: {
		backgroundColor: '#111111',
		color: '#eeeeee',
		marginBottom: '1.3rem',
		paddingBottom: '1rem',
		paddingTop: '2.3rem',
	},
    multispaceTitle: {
		fontSize: '1.3rem'
	},
    optionButton: {
        padding: '0.5rem'
    },
    right: {
        textAlign: 'right',
    },
    spaceDescription: {
		color: '#333333',
		fontSize: '1rem',
		paddingRight: '1.2rem',
		textAlign: 'left'
	},
    spaceGallery: {
		height: '11rem',
		margin: '0',
        marginBottom: '2rem',
		// overflowX: 'scroll',
        // overflowY: 'hidden',
		width: '100%',
		'& > div > div': {
			float: 'left',
			height: '100%',
			marginRight: '5px'
		},
        '& .__react_modal_image__modal_content img': {
            objectFit: 'scale-down!important'
        }
	},
    spaceImage: {
        objectFit: 'scale-down!important'
    },
    spaceTitle: {
		fontSize: '1.1rem',
		fontWeight: 'bold'
	}

});


function numberFormat(value, decimalPositions, decimalSeparator, thousandsSeparator){

	decimalPositions = isNaN(decimalPositions = Math.abs(decimalPositions)) ? 2 : decimalPositions;
    decimalSeparator = decimalSeparator === undefined ? "." : decimalSeparator;
    thousandsSeparator = thousandsSeparator === undefined ? "," : thousandsSeparator;
    let sign = value < 0 ? "-" : "";
    let i = String(parseInt(value = Math.abs(Number(value) || 0).toFixed(decimalPositions)));
	let k = i.length;
    let j = k > 3 ? k % 3 : 0;

   return sign + (j ? i.substr(0, j) + thousandsSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandsSeparator) + (decimalPositions ? decimalSeparator + Math.abs(value - i).toFixed(decimalPositions).slice(2) : "");

}


type Props = {

    _multispaceResume: Object,
    _multispaceSpace: Object,
	_userAuth: Object,
	dispatch: Dispatch

}


class Multispace extends Component<Props> {


    constructor(props){

        super(props);

        this._addToCampaign = this._addToCampaign.bind(this);
        this._addToFavorites = this._addToFavorites.bind(this);

    }


    render(){

        const {  _multispaceResume, _multispaceSpace, classes } = this.props;

        const multispaceImages = _multispaceResume.images && _multispaceResume.images.length ? _multispaceResume.images : ['https://testing.adxpace.com/spaces/wp-content/uploads/2021/07/adxpace_favicon-424x280.png'];


        return (

            <>

                <Grid className={ classes.mainContent } container>

                    <Grid  item xs={12}>

                        <Grid alignItems="center" className={ classes.multispaceResume } container justifyContent="center" spacing={ 3 }>

                            <Grid item md={ 2 } xs={ 11 }>

                                <div className={ classes.galleryContainer }>

                                    <ImageList className={ classes.gallery } rows={ 1 } cols={ 1.5 }>

                                    {
                                        multispaceImages.map((image, index) => (

                                            <ImageListItem   key={ `HolderImage_${ index }` }>
                                                <ModalImage className={ classes.multispaceImage } hideDownload={ true } small={ image } large={ image } alt="Foto del multiespacio" />
                                            </ImageListItem>

                                        ))
                                    }

                                    </ImageList>
                                </div>



                            </Grid>

                            <Grid item md={ 4 } xs={ 10 }>
                                <Typography className={ classes.multispaceTitle } component="h3">
                                    { _multispaceResume.post_title }
                                </Typography>
                                <Typography className={ classes.multispaceDescription } component="p">
                                    { _multispaceResume.metadata?._cth_calle } { _multispaceResume.metadata?._cth_numero }{ _multispaceResume.metadata?._cth_colonia && <span>, Col. { _multispaceResume.metadata?._cth_colonia }</span> }{ _multispaceResume.metadata?._cth_municipio && <span>, { _multispaceResume.metadata?._cth_municipio }</span> }{ _multispaceResume.metadata?._cth_ciudad && <span>, { _multispaceResume.metadata?._cth_ciudad }</span> }{ _multispaceResume.metadata?._cth_estado && <span>, { _multispaceResume.metadata?._cth_estado }</span> }{ _multispaceResume.metadata?._cth_codigo_postal && <span>, { _multispaceResume.metadata?._cth_codigo_postal }</span> }
                                </Typography>
                            </Grid>

                            <Grid className={ classes.left } item xs={ 12 }>
                                <Button variant="contained" className={ classes.backButton } startIcon={ <ArrowBackIosIcon /> } onClick={ e => this._navigateToMultispace() }>
                                    Volver a listado del multiespacio
                                </Button>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid alignItems="flex-start" className={ classes.main } container justifyContent="center" spacing={ 1 }>

                        <Grid item xs={ 12 } md={ 6 }></Grid>

                        <Grid className={ classes.right } item xs={ 12 } md={ 6 }>

                            <Typography className={ classes.descriptionTitle } component="p">
                                ID-ADXpace
                            </Typography>
                            <Typography className={ classes.descriptionContent } component="p">
                                ADX-{ _multispaceSpace.ID }
                            </Typography>

                        </Grid>

                        <Grid item xs={ 12 } md={ 5 }>

                            <div className={ classes.spaceGallery }>

                                <div style={ { height: '100%' } }>

                                    <ImageList className={ classes.gallery } rows={ 1 } >

                                    {
                                        _multispaceSpace.images?.length > 0 ?
                                        _multispaceSpace.images?.map((pic, i) =>

                                            <ImageListItem   key={ `ChildImage_${ i }` }>
                                                <ModalImage className={ classes.spaceImage } hideDownload={ true } small={ pic.guid } large={ pic.guid } alt="Foto del espacio" />
                                            </ImageListItem>

                                        )
                                        :
                                        <ModalImage className={ classes.spaceImage } hideDownload={ true } small="https://testing.adxpace.com/spaces/wp-content/uploads/2021/07/adxpace_favicon-424x280.png" alt="Foto del multiespacio" />
                                    }

                                    </ImageList>



                                </div>

                            </div>

                            <div>
                                <Typography className={ classes.descriptionTitle } component="p">
                                    * Las im&aacute;genes e informaci&oacute;n del espacio son responsabilidad del proveedor.
                                </Typography>
                            </div>

                            <div>
                                <Typography className={ classes.multispaceTitle } component="p">
                                    { _multispaceSpace.post_title }
                                </Typography>
                            </div>


                            <Grid className={ classes.mt } container >

                                <Grid item xs={ 12 } md={ 5 }>
                                    <Button color="primary" variant="contained" className={ classes.optionButton } startIcon={ <FavoriteBorderIcon /> } onClick={ e => this._addToFavorites() }>
                                        Agregar a favoritos
                                    </Button>
                                </Grid>

                                <Grid item xs={ 1 }></Grid>

                                <Grid item xs={ 12 } md={ 5 }>
                                    <Button color="primary" variant="contained" className={ classes.optionButton } startIcon={ <MenuOpenIcon /> } onClick={ e => this._addToCampaign() }>
                                        Agregar a campa&ntilde;a
                                    </Button>
                                </Grid>

                            </Grid>

                        </Grid>

                        <Grid item xs={ 12 } md={ 7 }>

                            <Grid container justifyContent="flex-start" spacing={ 5 }>

                                {
                                    _multispaceSpace.metadata?._cth_base &&
                                    <Grid className={ classes.spaceDescription } item xs={ 5 }>
                                        <Typography className={ classes.descriptionTitle } component="p">
                                            Base
                                        </Typography>
                                        <Typography className={ classes.descriptionContent } component="p">
                                            { _multispaceSpace.metadata._cth_base }
                                        </Typography>
                                    </Grid>
                                }

                                {
                                    _multispaceSpace.metadata?._cth_estructura &&
                                    <Grid className={ classes.spaceDescription } item xs={ 5 }>
                                        <Typography className={ classes.descriptionTitle } component="p">
                                            Estructura
                                        </Typography>
                                        <Typography className={ classes.descriptionContent } component="p">
                                            { _multispaceSpace.metadata._cth_estructura }
                                        </Typography>
                                    </Grid>
                                }

                                {
                                    _multispaceSpace.metadata?._cth_altura &&
                                    <Grid className={ classes.spaceDescription } item xs={ 5 }>
                                        <Typography className={ classes.descriptionTitle } component="p">
                                            Altura
                                        </Typography>
                                        <Typography className={ classes.descriptionContent } component="p">
                                            { _multispaceSpace.metadata._cth_altura }
                                        </Typography>
                                    </Grid>
                                }

                                {
                                    _multispaceSpace.metadata?._cth_tamano_pantalla &&
                                    <Grid className={ classes.spaceDescription } item xs={ 5 }>
                                        <Typography className={ classes.descriptionTitle } component="p">
                                            Tama&ntilde;o de pantalla (pulgadas)
                                        </Typography>
                                        <Typography className={ classes.descriptionContent } component="p">
                                            { _multispaceSpace.metadata._cth_tamano_pantalla }
                                        </Typography>
                                    </Grid>
                                }


                                {
                                    _multispaceSpace.metadata?._cth_pixeles &&
                                    <Grid className={ classes.spaceDescription } item xs={ 5 }>
                                        <Typography className={ classes.descriptionTitle } component="p">
                                            Pixeles
                                        </Typography>
                                        <Typography className={ classes.descriptionContent } component="p">
                                            { _multispaceSpace.metadata._cth_pixeles }
                                        </Typography>
                                    </Grid>
                                }


                                {
                                    _multispaceSpace.metadata?._cth_observaciones &&
                                    <Grid className={ classes.spaceDescription } item xs={ 11 }>
                                        <Typography className={ classes.descriptionTitle } component="p">
                                            Observaciones
                                        </Typography>
                                        <Typography className={ classes.descriptionContent } component="p">
                                            { _multispaceSpace.metadata._cth_observaciones }
                                        </Typography>
                                    </Grid>
                                }


                                {
                                    _multispaceSpace.metadata?._cth_nivel_socioeconomico_zona &&
                                    <Grid className={ classes.spaceDescription } item xs={ 5 }>
                                        <Typography className={ classes.descriptionTitle } component="p">
                                            Nivel socioecon&oacute;mico de la zona
                                        </Typography>
                                        <Typography className={ classes.descriptionContent } component="p">
                                            { _multispaceSpace.metadata._cth_nivel_socioeconomico_zona }
                                        </Typography>
                                    </Grid>
                                }


                                {
                                    _multispaceSpace.metadata?._cth_nivel_socioeconomico_audiencia &&
                                    <Grid className={ classes.spaceDescription } item xs={ 5 }>
                                        <Typography className={ classes.descriptionTitle } component="p">
                                            Nivel socioecon&oacute;mico de la audiencia
                                        </Typography>
                                        <Typography className={ classes.descriptionContent } component="p">
                                            { _multispaceSpace.metadata._cth_nivel_socioeconomico_audiencia }
                                        </Typography>
                                    </Grid>
                                }


                                {
                                    _multispaceSpace.metadata?._cth_tipo_establecimiento &&
                                    <Grid className={ classes.spaceDescription } item xs={ 5 }>
                                        <Typography className={ classes.descriptionTitle } component="p">
                                            Tipo de establecimiento
                                        </Typography>
                                        <Typography className={ classes.descriptionContent } component="p">
                                            { _multispaceSpace.metadata._cth_tipo_establecimiento }
                                        </Typography>
                                    </Grid>
                                }


                                {
                                    _multispaceSpace.metadata?._cth_nombre_establecimiento &&
                                    <Grid className={ classes.spaceDescription } item xs={ 5 }>
                                        <Typography className={ classes.descriptionTitle } component="p">
                                            Nombre del establecimiento
                                        </Typography>
                                        <Typography className={ classes.descriptionContent } component="p">
                                            { _multispaceSpace.metadata._cth_nombre_establecimiento }
                                        </Typography>
                                    </Grid>
                                }


                                {
                                    _multispaceSpace.metadata?._cth_numero_muebles &&
                                    <Grid className={ classes.spaceDescription } item xs={ 5 }>
                                        <Typography className={ classes.descriptionTitle } component="p">
                                            N&uacute;mero de muebles
                                        </Typography>
                                        <Typography className={ classes.descriptionContent } component="p">
                                            { _multispaceSpace.metadata._cth_numero_muebles }
                                        </Typography>
                                    </Grid>
                                }


                                {
                                    _multispaceSpace.metadata?._cth_numero_caras &&
                                    <Grid className={ classes.spaceDescription } item xs={ 5 }>
                                        <Typography className={ classes.descriptionTitle } component="p">
                                            N&uacute;mero de caras
                                        </Typography>
                                        <Typography className={ classes.descriptionContent } component="p">
                                            { _multispaceSpace.metadata._cth_numero_caras }
                                        </Typography>
                                    </Grid>
                                }


                                {
                                    _multispaceSpace.metadata?._cth_audiencia_diaria &&
                                    <Grid className={ classes.spaceDescription } item xs={ 5 }>
                                        <Typography className={ classes.descriptionTitle } component="p">
                                            Audiencia diaria
                                        </Typography>
                                        <Typography className={ classes.descriptionContent } component="p">
                                            { _multispaceSpace.metadata._cth_audiencia_diaria }
                                        </Typography>
                                    </Grid>
                                }


                                {
                                    _multispaceSpace.metadata?._cth_afluencia_anual &&
                                    <Grid className={ classes.spaceDescription } item xs={ 5 }>
                                        <Typography className={ classes.descriptionTitle } component="p">
                                            Afluencia anual
                                        </Typography>
                                        <Typography className={ classes.descriptionContent } component="p">
                                            { _multispaceSpace.metadata._cth_afluencia_anual }
                                        </Typography>
                                    </Grid>
                                }


                                {
                                    _multispaceSpace.metadata?._cth_clave_ibope &&
                                    <Grid className={ classes.spaceDescription } item xs={ 5 }>
                                        <Typography className={ classes.descriptionTitle } component="p">
                                            Clave IBOPE
                                        </Typography>
                                        <Typography className={ classes.descriptionContent } component="p">
                                            { _multispaceSpace.metadata._cth_clave_ibope }
                                        </Typography>
                                    </Grid>
                                }


                                {
                                    _multispaceSpace.metadata?._cth_ibope_glp &&
                                    <Grid className={ classes.spaceDescription } item xs={ 5 }>
                                        <Typography className={ classes.descriptionTitle } component="p">
                                            IBOPE GLP
                                        </Typography>
                                        <Typography className={ classes.descriptionContent } component="p">
                                            { _multispaceSpace.metadata._cth_ibope_glp }
                                        </Typography>
                                    </Grid>
                                }


                                {
                                    _multispaceSpace.metadata?._cth_ibope_svs &&
                                    <Grid className={ classes.spaceDescription } item xs={ 5 }>
                                        <Typography className={ classes.descriptionTitle } component="p">
                                            IBOPE SVS
                                        </Typography>
                                        <Typography className={ classes.descriptionContent } component="p">
                                            { _multispaceSpace.metadata._cth_ibope_svs }
                                        </Typography>
                                    </Grid>
                                }


                                {
                                    _multispaceSpace.metadata?._cth_visibilidad &&
                                    <Grid className={ classes.spaceDescription } item xs={ 5 }>
                                        <Typography className={ classes.descriptionTitle } component="p">
                                            Visibilidad
                                        </Typography>
                                        <Typography className={ classes.descriptionContent } component="p">
                                            { _multispaceSpace.metadata._cth_visibilidad }
                                        </Typography>
                                    </Grid>
                                }


                                {
                                    _multispaceSpace.metadata?._cth_calificacion &&
                                    <Grid className={ classes.spaceDescription } item xs={ 5 }>
                                        <Typography className={ classes.descriptionTitle } component="p">
                                            Calificaci&oacute;n
                                        </Typography>
                                        <Typography className={ classes.descriptionContent } component="p">
                                            { _multispaceSpace.metadata._cth_calificacion }
                                        </Typography>
                                    </Grid>
                                }


                                {
                                    _multispaceSpace.metadata?._cth_descuento_volumen &&
                                    <Grid className={ classes.spaceDescription } item xs={ 5 }>
                                        <Typography className={ classes.descriptionTitle } component="p">
                                            Descuento por volumen
                                        </Typography>
                                        <Typography className={ classes.descriptionContent } component="p">
                                            { _multispaceSpace.metadata._cth_descuento_volumen === 'a:1:{i:0;s:1:"y";}' ? 'SI' : 'NO'}
                                        </Typography>
                                    </Grid>
                                }

                                <Grid className={ classes.spaceDescription } item xs={ 12 }></Grid>


                                {
                                    _multispaceSpace.metadata?._cth_price &&
                                    <Grid className={ classes.spaceDescription } item xs={ 5 } md={ 4 }>
                                        <Typography className={ classes.descriptionTitle } component="p">
                                            Precio:
                                        </Typography>
                                        <Typography className={ classes.descriptionContent } component="p">
                                            <strong>$ { numberFormat(_multispaceSpace.metadata._cth_price, 0) }</strong>
                                        </Typography>
                                    </Grid>
                                }


                                {
                                    _multispaceSpace.metadata?._cth_periodo_contratación &&
                                    <Grid className={ classes.spaceDescription } item xs={ 5 } md={ 4 }>
                                        <Typography className={ classes.descriptionTitle } component="p">
                                            Per&iacute;odo de contrataci&oacute;n:
                                        </Typography>
                                        <Typography className={ classes.descriptionContent } component="p">
                                            { _multispaceSpace.metadata._cth_periodo_contratación } d&iacute;as
                                        </Typography>
                                    </Grid>
                                }


                                {
                                    _multispaceSpace.metadata?._cth_periodo_pago &&
                                    <Grid className={ classes.spaceDescription } item xs={ 5 } md={ 4 }>
                                        <Typography className={ classes.descriptionTitle } component="p">
                                            Per&iacute;odo de pago:
                                        </Typography>
                                        <Typography className={ classes.descriptionContent } component="p">
                                            { _multispaceSpace.metadata._cth_periodo_pago } d&iacute;as
                                        </Typography>
                                    </Grid>
                                }

                            </Grid>

                        </Grid>

                    </Grid>

                </Grid>

                {
                    this.props._displayAddToCampaign && <AddToCampaignDialog />
                }

            </>

        );

    }


    _addToCampaign(){

        const { _multispaceSpace, dispatch } = this.props;

        dispatch(setSelectedSpaceName(_multispaceSpace.post_name));
        dispatch(setDisplayAddToCampaign(true));

    }


    _addToFavorites(){

        const { _multispaceSpace, _userAuth, dispatch } = this.props;

        dispatch(addToFavorites({ token: _userAuth.token, adTitle: _multispaceSpace.post_name }));

    }


    _navigateToMultispace(){

        this.props.dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.MULTISPACES_LIST));

    }

}


function _mapStateToProps(state){

	const { userAuth } = state['base/auth'];
    const { displayAddToCampaign } = state['base/campaigns'];
	const { multispaceResume, multispaceSpace } = state['base/multispaces'];

	return {
        _displayAddToCampaign: displayAddToCampaign,
        _multispaceResume: multispaceResume,
		_multispaceSpace: multispaceSpace,
		_userAuth: userAuth
	}

}


export default connect(_mapStateToProps)(withStyles(styles)(Multispace));
