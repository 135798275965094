//	@flow

import { getMultispaceSpaces } from '../api/spaces';
import { setToastMessage, setToastVisible } from '../notifications';
import { MiddlewareRegistry } from '../redux';


import { setMultispaceResume, setMultispaceSpace, setMultispaceSpaces } from './actions';
import { GET_MULTISPACE_SPACES } from './actionTypes';


MiddlewareRegistry.register(store => next => action => {


	switch (action.type) {

        case GET_MULTISPACE_SPACES:
            return _getMultispaceSpaces(store, next, action);

        default:
			return(next(action));

    }

});



async function _getMultispaceSpaces(store, next, action){

    const { dispatch } = store;

	const { multispaceId, childId } = action;

    try {

        const multispacesResponse = await getMultispaceSpaces(multispaceId).catch(e=> {

			console.error(e);

			dispatch(setToastMessage('Error obteniendo listado de espacios del multiespacio: No pudimos comunicarnos con el servidor. Verifique que hay una conexión activa a Internet.'));
			return dispatch(setToastVisible(true));

		});

        let { err, data, status } = multispacesResponse.data;

		if(err || status === 'Error'){



			dispatch(setToastMessage(`Error al obtener listado de espacios del multiespacio: ${ err }`));
			return dispatch(setToastVisible(true));

		}

        dispatch(setMultispaceResume(data.multispace));
        dispatch(setMultispaceSpaces(data.spaces));


		if(childId){

			let space = data.spaces.find(e => e.ID === childId);

			if(space){

				dispatch(setMultispaceSpace(space));

			}

		}



        return next(action);


    } catch(e) {

        console.error(e);

		dispatch(setToastMessage(`Error obteniendo listado de espacios del multiespacio: ${ e.message }.`));
		return dispatch(setToastVisible(true));

    }

}
