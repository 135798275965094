// @flow
import React, { Component } from 'react';

import { Container, AppBar, Card, CardActionArea, Grid, IconButton, Link, Toolbar, Typography  } from '@material-ui/core';


import { withStyles } from '@material-ui/core/styles';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import FavoriteIcon from '@material-ui/icons/Favorite';
import type { Dispatch } from 'redux';

import { setCurrentScreen } from '../../app/actions';
import { CURRENT_DISPLAYING_SCREEN } from '../../app/constants';
import { Campaign } from '../../campaigns';
import { Campaigns } from '../../campaigns';
import { Favorites } from '../../favorites';
import { setIframePath } from '../../home/actions';
import { connect } from '../../redux';

import { setMySpacesScreen } from '../actions';
import { SPACES_SCREENS } from '../constants';
import header_img from '../images/hero-bg.jpg';



const styles = theme => ({
	activeLink: {
		backgroundColor: '#912015',
		color: 'white',
		fontSize: '0.9rem',
		fontWeight: 500,
		marginRight: theme.spacing(2),
		paddingBottom: '0.7rem',
		paddingLeft: '0.9rem',
		paddingRight: '0.9rem',
		paddingTop: '0.7rem',
		'&:hover': {
			backgroundColor: '#912015',
			textDecoration: 'none',
		}
	},

	cardActionArea: {
		height: '100%',
		padding: '0.5rem',
		'&:hover': {
			backgroundColor: '#ffe1db'
		}
	},

	cardContentArea: {
		height: '13rem',
		marginLeft: '2.5%',
		marginBottom: '1.5rem',
		width: '95%'
	},

	cardsContainer: {
		marginTop: '3rem'
	},

	headerDiv: {
        backgroundImage: `url(${header_img})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
	},
    headerContainer: {
        padding: theme.spacing(3,0,3),

	},
    headerTitle: {
        color: "#FFFFFF"
	},
    headerSlogan: {
        color: "#FFFFFF"
	},
	title: {
		color: 'white',
		fontSize: '0.9rem',
		fontWeight: 500,
		marginRight: theme.spacing(2),
		paddingBottom: '0.7rem',
		paddingLeft: '0.9rem',
		paddingRight: '0.9rem',
		paddingTop: '0.7rem',
		'&:hover': {
			backgroundColor: '#912015',
			textDecoration: 'none',
		}
	},

});



type Props = {

	_currentMySpacesScreen: Number,
	_userData: Object,
	dispatch: Dispatch

}


class MySpaces extends Component<Props> {


	constructor(props){

		super(props);

		this.state = {
			currentlyDisplaying: ''
		}

		this._displayFavorites = this._displayFavorites.bind(this);
		this._displayMySpaces = this._displayMySpaces.bind(this);
		this._gotoSpacesSearch = this._gotoSpacesSearch.bind(this);

	}


	componentDidMount(){



	}



	render(){

		const { _currentMySpacesScreen, _userData, classes } = this.props;

		const { currentlyDisplaying } = this.state;

		const { innerHeight } = window;

		const height = innerHeight - window.convertRemToPixels(4.5);

		return (

			<Container disableGutters id="spacesContainer" maxWidth={ false } style={{ height: height, overflowX: 'hidden', overflowY: 'scroll', padding: 0, paddingBottom: '2rem' }}>

				<main>
					<div className={ classes.headerDiv } >
						<Container maxWidth="xl" className={ classes.headerContainer }>

							<Typography variant="h1" className={ classes.headerTitle } align="center" color="textPrimary" gutterBottom>
								Mis Espacios
							</Typography>
							<Typography variant="h6" className={ classes.headerSlogan } align="center" color="textPrimary" paragraph >
								Aqui podrás administrar tus espacios de interés, solicitar cotizaciones y contratar!
							</Typography>

						</Container>
					</div>

				</main>


				<AppBar style={{ position: 'sticky', top: 0 }}>

					<Toolbar>

						<Grid container direction="row" justifyContent="flex-start" alignItems="center" variant="inherit">

							<Grid item xs={ 6 } sm={ 5 } md={ 3 } lg={ 2 } >

								<Link href="#" className={ currentlyDisplaying === 'favorites' ? classes.activeLink : classes.title } onClick={ () => this._displayFavorites() }>
									<IconButton edge="start" className={ classes.MenuLink } color="inherit" aria-label="favoritos"><FavoriteIcon />&nbsp;&nbsp;Mis favoritos</IconButton>
								</Link>

							</Grid>

							<Grid item xs={ 6 } sm={ 5 } md={ 3 } lg={ 2 } >

								<Link href="#" className={ currentlyDisplaying === 'campaigns' ? classes.activeLink : classes.title } onClick={ () => this._displayMySpaces() }>
									<IconButton edge="start" className={ classes.MenuLink } color="inherit" aria-label="mis espacios"><BookmarkIcon />&nbsp;&nbsp;Mis campa&ntilde;as</IconButton>
								</Link>

							</Grid>

						</Grid>

					</Toolbar>

				</AppBar>

				{
					_currentMySpacesScreen === SPACES_SCREENS.FAVORITES && <Favorites />
				}

				{
					_currentMySpacesScreen === SPACES_SCREENS.CAMPAIGNS && <Campaigns />
				}

				{
					_currentMySpacesScreen >= SPACES_SCREENS.CAMPAIGN_CONTRACTS && <Campaign />
				}

				{

					_currentMySpacesScreen === SPACES_SCREENS.MENU &&
					<Container maxWidth="md" style={{ marginTop: '2.3rem' }}>

						<Typography variant="h6" gutterBottom>

							Bienvenido { _userData.name } { _userData.lastname }.

						</Typography>

						<Typography variant="h5" gutterBottom>

							¿Qu&eacute; deseas hacer?

						</Typography>


						<Grid className={ classes.cardsContainer } container direction="row" justifyContent="center" alignItems="center" variant="inherit">


							<Grid item md={ 4 } xs={ 12} >

								<Card className={ classes.cardContentArea }>
									<CardActionArea className={ classes.cardActionArea } onClick={ () => this._displayMySpaces() }>

										<Typography variant="h5">

											Administrar mis campa&ntilde;as

										</Typography>

									</CardActionArea>
								</Card>

							</Grid>

							<Grid item md={ 4 } xs={ 12} >

								<Card className={ classes.cardContentArea }>
									<CardActionArea className={ classes.cardActionArea } onClick={ () => this._gotoSpacesSearch() }>

										<Typography variant="h5">

											Buscar espacios

										</Typography>

									</CardActionArea>
								</Card>

							</Grid>




							<Grid item md={ 4 } xs={ 12} >

								<Card className={ classes.cardContentArea }>
									<CardActionArea className={ classes.cardActionArea } onClick={ () => this._displayFavorites() }>

										<Typography variant="h5">

											Ver mis favoritos

										</Typography>

									</CardActionArea>
								</Card>

							</Grid>

						</Grid>

					</Container>

				}

			</Container>

		);

	}


	_displayFavorites(){

		this.props.dispatch(setMySpacesScreen(SPACES_SCREENS.FAVORITES));

	}


	_displayMySpaces(){

		this.props.dispatch(setMySpacesScreen(SPACES_SCREENS.CAMPAIGNS));

	}


	_gotoSpacesSearch(){

		this.props.dispatch(setIframePath('/index.php/adxpace-resultados-busqueda/'));
		this.props.dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.WELCOME));

	}

}


function _mapStateToProps(state){

	const { userData } = state['base/auth'];
	const { currentMySpacesScreen } = state['base/mySpaces'];

	return {
		_currentMySpacesScreen: currentMySpacesScreen,
		_userData: userData
	}

}



export default connect(_mapStateToProps)(withStyles(styles)(MySpaces));
