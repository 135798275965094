//  @flow
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import type { Dispatch } from 'redux';


import { connect } from '../../redux';

import { CURRENT_DISPLAYING_SCREEN } from '../constants';
import { DialogContainer, openDialog } from '../../dialog';
import { NoticeDialog } from '../../notifications';
import { setDisplayAddToCampaign } from '../../campaigns';
import { addToCampaignFromCampaign, addToFavorites, setSelectedSpaceName } from '../../favorites/actions';
import { Footer } from '../../footer';
import { Header } from '../../header';
import { Home, setIframePath } from '../../home';
import { ForgotPassword, Login, PasswordReset } from '../../login';
import { Multispace, MultispacesList } from '../../multispaces';
import { getMultispaceSpaces } from '../../multispaces/actions.js';
import { MySpaces } from '../../mySpaces';
import { Registration } from '../../registration';
import { SpaceDetails } from '../../spaces';
import { Toast } from '../../notifications';

import { setCurrentPost, setCurrentScreen, setCurrentSearchTerm, setLastSearchTerm, setLoaderHidden } from '../actions';

import '../styles/App.css';



type Props = {

	_currentScreen: Number,
	_userAuth: Object,
	_userData: Object,
	_userLoggedIn: Boolean,


	/**
	* The redux {@code dispatch} function.
	*/
	dispatch: Dispatch<any>

}

class App extends Component<Props> {


    installPrompt = null;


	constructor(props: Props){

		super(props);



		this._getCurrentScreen = this._getCurrentScreen.bind(this);
		this._onMessageReceived = this._onMessageReceived.bind(this);

		window.addEventListener("message", e => this._onMessageReceived(e), false);

    }



    componentDidMount(){

    	if(this.props._currentScreen === CURRENT_DISPLAYING_SCREEN.WELCOME){

    		this.props.dispatch(setLoaderHidden(false));

    	}

    }



	_getCurrentScreen(){

	    switch (this.props._currentScreen) {

				case CURRENT_DISPLAYING_SCREEN.LOGIN:
					return <Login />


				case CURRENT_DISPLAYING_SCREEN.REGISTRATION:
					return <Registration />


				case CURRENT_DISPLAYING_SCREEN.CONTROL_PANEL_USER:
					return <MySpaces />


				case CURRENT_DISPLAYING_SCREEN.WELCOME:
					return <Home />

				case CURRENT_DISPLAYING_SCREEN.FORGOT_PASSWORD:
					return <ForgotPassword />

				case CURRENT_DISPLAYING_SCREEN.SPACE_DETAIL:
					return <Multispace />

				case CURRENT_DISPLAYING_SCREEN.MULTISPACES_LIST:
					return <MultispacesList />


	        default:
					console.log(`Screen still not active.`);
					return <Home key={ 'home' } />
					// return null;

	    }

    }





    render(){

        return(

				<Router>
					<div className="App" id="main-container">
						<Header userData={ this.props._userData } logout={ this.destroySession } />

							<Switch>
								<Route exact path={["/spaceDetails/:spaceId"]} component={ SpaceDetails } />
								<Route exact path={["/resetPassword/:token"]} component={ PasswordReset } />
								<Route render={ () => this._getCurrentScreen() } />
							</Switch>

						<Footer />

					</div>
					<DialogContainer />
					<Toast />
				</Router>
        )

    }


	_onMessageReceived(event){

		const { _currentScreen, _lastSearchTerm, _userAuth, _userLoggedIn, dispatch } = this.props;

		let data;

		if(typeof event.data === 'string')

			data = JSON.parse(event.data);

		else {

			data = event.data;

		}


		if(!data.message) return;

		const { message } = data;

		const { pathname } = message.location;

		const lIndex = pathname.indexOf('/listing/');

		let postName

		if(lIndex > -1){

			postName = pathname.substr(lIndex + 9);

			let paramsIndex = postName.indexOf('/');

			if(paramsIndex){

				postName = postName.substr(0, paramsIndex);

			}

		}


		switch (message.type) {

			case 'loaded':

				if(!_userLoggedIn){

					dispatch(setCurrentPost(postName));
					dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.LOGIN));

				}

			break;


			case 'favoriteAd':

				if(postName){

					dispatch(addToFavorites({ token: _userAuth.token, adTitle: postName }));

				}


			break;


			case 'addToCampaign':

				if(postName){

					dispatch(setSelectedSpaceName(postName));

					if(_currentScreen === CURRENT_DISPLAYING_SCREEN.WELCOME){

						dispatch(setDisplayAddToCampaign(true));

					} else {

						dispatch(addToCampaignFromCampaign({
							adTitle: postName,
							token: _userAuth.token
						}));

					}
				}

			break;


			case 'hideLoader':

				console.log('hide loader message received.');
				dispatch(setLoaderHidden(true));

			break;


			case 'loadMultispace':

				dispatch(getMultispaceSpaces(message.postId));
				dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.MULTISPACES_LIST));

			break;


			case 'searchPath':

				const lastSearchTerm = this.props._currentSearchTerm;
				dispatch(setLastSearchTerm(lastSearchTerm));
				dispatch(setCurrentSearchTerm(message.location.pathname + message.searchPath));

				console.log('lastSearchTerm: ', lastSearchTerm);
				console.log('Setting current search term: ', message.location.pathname + message.searchPath);

			break;


			case 'requestToGoBack':

				dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.WELCOME));
				this.props.history?.push('/');
				dispatch(setIframePath(this.props._currentSearchTerm));
				// dispatch(setIframePath(this.props._lastSearchTerm));

				const iframeEl = document.querySelector('#mainContentsFrame');

				var url = iframeEl.src;

				console.log('iframe url: ', url);

				iframeEl.src = 'about:blank';
				setTimeout(function() {
				    iframeEl.src = url;
				}, 10);


				console.log('Request to go back: ');
				console.log('Current search term: ', this.props._currentSearchTerm);
				// console.log('Last search term: ', this.props._lastSearchTerm);

			break;


			case 'showNotice':

				dispatch(openDialog(NoticeDialog, {
					modalHeader: message.dialogHeader || '',
					modalMessage: message.notice
				}));

			break;

			default:

		}

	}

}


function _mapStateToProps(state){

	const { currentScreen, currentSearchTerm, lastSearchTerm } = state['base/app'];
	const { userAuth, userData, userLoggedIn } = state['base/auth'];

	return {
		_currentScreen: currentScreen,
		_currentSearchTerm: currentSearchTerm,
		_lastSearchTerm: lastSearchTerm,
		_userAuth: userAuth,
		_userData: userData,
		_userLoggedIn: userLoggedIn
	}

}

export default connect(_mapStateToProps)(App);
