export const CURRENT_DISPLAYING_SCREEN = {
	WELCOME: 0,
	LOGIN: 1,
	REGISTRATION: 2,
	CONTROL_PANEL_USER: 3,
	CONTROL_PANEL_ADMIN: 4,
	FORGOT_PASSWORD: 5,
	MULTISPACES_LIST: 6,
	SPACE_DETAIL: 7
}
