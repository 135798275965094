// @flow
import React, { Component } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from "@date-io/moment";
import { withStyles } from '@material-ui/core/styles';
import { Button, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    DatePicker
  } from "@material-ui/pickers";

import moment from "moment";

import type { Dispatch } from 'redux';

import { requestQuotation } from '../actions';
import { Dialog, NoticeDialog, setToastMessage, setToastVisible } from '../../notifications';
import { connect } from '../../redux';


const styles = theme => ({

	addButton: {
		borderWidth: '2px',
		fontSize: '1.5rem',
		marginTop: '1rem',
		padding: '0.5rem 1rem',
		position: 'sticky',
		top: '39rem'
	},

	mapContainer:{

		backgroundColor: "#cccccc",
		height:'50vh',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '8px',
		position: 'sticky !important',
		top: '7rem',
		width:'97%',

	},

	requestButton: {
		backgroundColor: '#efefef',
		borderColor: '#E83022',
		borderWidth: '2px',
		fontSize: '1.1rem',
		marginBottom: '1rem',
		marginTop: '1rem',
		padding: '0.5rem 1rem',
	}

});


const to2 = (num) => num < 10 ? `0${ num }` : `${ num }`;


function numberFormat(value, decimalPositions, decimalSeparator, thousandsSeparator){

	decimalPositions = isNaN(decimalPositions = Math.abs(decimalPositions)) ? 2 : decimalPositions;
    decimalSeparator = decimalSeparator === undefined ? "." : decimalSeparator;
    thousandsSeparator = thousandsSeparator === undefined ? "," : thousandsSeparator;
    let sign = value < 0 ? "-" : "";
    let i = String(parseInt(value = Math.abs(Number(value) || 0).toFixed(decimalPositions)));
	let k = i.length;
    let j = k > 3 ? k % 3 : 0;

   return sign + (j ? i.substr(0, j) + thousandsSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandsSeparator) + (decimalPositions ? decimalSeparator + Math.abs(value - i).toFixed(decimalPositions).slice(2) : "");

}


const dateFns = new DateFnsUtils ();


class GroupRequest extends Component {


	constructor(props){

		super(props);

		this.state = {
			availableSpaces: [ ],
			selectedSpaces: [ ]
		}

		this._isSpaceSelected = this._isSpaceSelected.bind(this);
		this._onQuotationRequest = this._onQuotationRequest.bind(this);
		this._onSelectAll = this._onSelectAll.bind(this);
		this._onSelectSpace = this._onSelectSpace.bind(this);


	}


	componentDidMount(){

		const { _detailedCampaigns, _editingCampaignIndex } = this.props;

		const _editingCampaignSpaces = _detailedCampaigns[_editingCampaignIndex].spaces;

		const availableSpaces = _editingCampaignSpaces.filter(space => space.contractStatus === 1);

		this.setState({
			availableSpaces
		});

	}


	render(){

		const { classes } = this.props;
		const { availableSpaces, selectedSpaces } = this.state;

		const headCells = [
			{ id: 'spaceName', numeric: false , disablePadding: true, label: 'Espacio' },
			{ id: 'contractStartDate', numeric: false, disablePadding: true, label: 'Inicio de contratación' },
			{ id: 'contractEndDate', numeric: false, disablePadding: true, label: 'Fin de contratación' },
			{ id: 'spacePrice', numeric: true, disablePadding: true, label: 'Precio de lista' }
		];

		return (

			<Paper>

				<TableContainer>

					<Table size="small">

						<TableHead>

							<TableCell padding="checkbox">
								<Checkbox
									indeterminate={ selectedSpaces.length > 0 && selectedSpaces.length < availableSpaces.length }
									checked={ availableSpaces.length > 0 && selectedSpaces.length === availableSpaces.length }
									onChange={ this._onSelectAll }
									inputProps={{ 'aria-label': 'Seleccionar todos' }}
								/>
							</TableCell>

							{

								headCells.map((headcell) => (

									<TableCell
										key={ headcell.id }
										align={ headcell.numeric ? 'right' : 'left' }
										padding={ headcell.disablePadding ? 'none' : 'normal' }
									>
										{ headcell.label }
									</TableCell>

								))

							}

						</TableHead>

						<TableBody>

							{

								availableSpaces.map((contractDetails, index) => {

									const isItemSelected = this._isSpaceSelected(contractDetails.spaceId, index);
									const labelId = `table_checkbox-${ index }`;

									const startDate =  contractDetails.contractStartDate ? new Date(contractDetails.contractStartDate) : false;
									const endDate = contractDetails.contractEndDate ? new Date(contractDetails.contractEndDate) : false;

									const displayStartDate = startDate;
									//? `${ to2(startDate.getDate()) }-${ to2(startDate.getMonth() + 1) }-${ startDate.getFullYear() }` : '';

									const displayEndDate = endDate;
									// ? `${ to2(endDate.getDate()) }-${ to2(endDate.getMonth() + 1) }-${ endDate.getFullYear() }` : '';

									return (

										<TableRow
											hover
											onClick={ e => this._onSelectSpace(e, contractDetails.spaceId) }
											role="checkbox"
											tabIndex={ -1 }
											key={ contractDetails.spaceId }
											selected={ isItemSelected }
										>

											<TableCell padding="checkbox">
												<Checkbox checked={ isItemSelected } />
											</TableCell>

											<TableCell id={ labelId } scope="row" padding="none">
												{ contractDetails.spaceName }
											</TableCell>

											<MuiPickersUtilsProvider libInstance={ moment } utils={ MomentUtils }>
					                            <TableCell item md={ 4 }>
					    							<DatePicker
					    								className={ classes.datePicker }
					    								disabled={ false }
					    								format="YYYY-MM-DD"
					    								label="Inicio de Contratación"
					    								margin="normal"
					    								onChange={ v => this._handleStartDateChanged(v, contractDetails.spaceId) }
					    								value={ displayStartDate ? displayStartDate : null }
					    							/>
					                            </TableCell>

					                            <TableCell item md={ 4 }>
					    							<DatePicker
					    								className={ classes.datePicker }
					    								disabled={ false }
					    								format="YYYY-MM-DD"
					    								label="Fin de Contratación"
					    								margin="normal"
					    								onChange={ v => this._handleEndDateChanged(v, contractDetails.spaceId) }
					    								value={ displayEndDate ? displayEndDate : null }
					    							/>
					                            </TableCell>
											</MuiPickersUtilsProvider>


											

											<TableCell align="right">$ { numberFormat(contractDetails.spacePrice) }</TableCell>

										</TableRow>

									)


								})

							}


						</TableBody>

					</Table>

				</TableContainer>

				<center><Button className={ classes.requestButton } variant="contained" disabled={ selectedSpaces.length === 0 } onClick={ this._onQuotationRequest } >Solicitar cotizaci&oacute;n para los espacios seleccionados</Button></center>

			</Paper>

		);

	}


	_handleEndDateChanged(val, id){

		const { availableSpaces } = this.state;

		const spaceIndex = availableSpaces.findIndex(e => e.spaceId === id);

		if(spaceIndex < 0) return;


		let space = availableSpaces[spaceIndex];

		

		val = dateFns.format(new Date(val), `yyyy-MM-dd'T'hh:mm`);

		const endDate = new Date(val);

		const startDate = space.contractStartDate;

		if(startDate && endDate <= startDate){

			const { dispatch } = this.props;

			dispatch(setToastMessage('No es posible terminar un contrato antes de iniciarlo. Verifique que la fecha de fin sea posterior a la fecha de inicio.'));
			return dispatch(setToastVisible(true));

		}


		availableSpaces[spaceIndex].contractEndDate = endDate;

		this.setState({
			availableSpaces
		});

	}


	_handleStartDateChanged(val, id){

		const { availableSpaces } = this.state;

		const spaceIndex = availableSpaces.findIndex(e => e.spaceId === id);

		if(spaceIndex < 0) return;


		let space = availableSpaces[spaceIndex];


		val = dateFns.format(new Date(val), `yyyy-MM-dd'T'hh:mm`);

		const { dispatch } = this.props;

		const startDate = new Date(val);

		const refStartDate = new Date();
			refStartDate.setDate(refStartDate.getDate() + 4);


		if(startDate < refStartDate){

			dispatch(setToastMessage(`La fecha mínima para solicitar una cotización es ${ to2(refStartDate.getDate() + 1) }/${ to2(refStartDate.getMonth() + 1) }/${ refStartDate.getFullYear() }`));
			return dispatch(setToastVisible(true));

		}


		const endDate = space.contractEndDate;

		if(endDate && endDate <= startDate){

			dispatch(setToastMessage('No es posible terminar un contrato antes de iniciarlo. Verifique que la fecha de fin sea posterior a la fecha de inicio.'));
			return dispatch(setToastVisible(true));

		}

		availableSpaces[spaceIndex].contractStartDate = startDate;

		this.setState({
			availableSpaces
		});

	}


	_isSpaceSelected(space){

		const { selectedSpaces } = this.state;

		return selectedSpaces.indexOf(space) !== -1;

	}


	_onSelectAll(e){

		const { availableSpaces } = this.state;

		if(e.target.checked){

			const newSelection = availableSpaces.map((contractDetails, index) => contractDetails.spaceId );

			this.setState({
				selectedSpaces: newSelection
			});

			return;

		}

		return this.setState({
			selectedSpaces: [ ]
		});

	}


	_onSelectSpace(e, contractId){

		const { selectedSpaces } = this.state;

		const selectedIndex = selectedSpaces.indexOf(contractId);

		let newSelection = [ ];

		if(selectedIndex === -1){

			newSelection = newSelection.concat(selectedSpaces, contractId);

		} else if (selectedIndex === 0){

			newSelection.concat(selectedSpaces.slice(1));

		} else if (selectedIndex === selectedSpaces.length -1){

			newSelection = newSelection.concat(selectedSpaces.slice(0, -1));

		} else if(selectedIndex > 0){

			newSelection = newSelection.concat(
				selectedSpaces.slice(0, selectedIndex),
				selectedSpaces.slice(selectedIndex + 1)
			);
		}

		this.setState({
			selectedSpaces: newSelection
		});

	}


	_onQuotationRequest(e){

		const { closeModal, _userAuth, dispatch } = this.props;
		const { availableSpaces, selectedSpaces } = this.state;

		const spacesToRequestQuotation = [ ];

		selectedSpaces.forEach(selectedSpaceIdx => {

			const space = availableSpaces.find(e => e.spaceId === selectedSpaceIdx);

			if(!space.contractStartDate || !space.contractEndDate){

				dispatch(setToastMessage('La fecha de inicio y fecha de fin son necesarias para solicitar una cotización.'));

				return dispatch(setToastVisible(true));

			}

			spacesToRequestQuotation.push(space);

		});

		const groupId = Date.now();

		spacesToRequestQuotation.forEach(space => {
			
			dispatch(requestQuotation({
				contractId: space.contractId,
				endDate: space.contractEndDate,
				groupId,
				startDate: space.contractStartDate,
				token: _userAuth.token
			}));

		});

		closeModal();

	}

}


function _mapStateToProps(state){

	const { userAuth } = state['base/auth'];
	const { detailedCampaigns, editingCampaignIndex } = state['base/campaigns'];
	const { currentMySpacesScreen } = state['base/mySpaces'];

	return {
		_currentMySpacesScreen: currentMySpacesScreen,
		_detailedCampaigns: detailedCampaigns,
		_editingCampaignIndex: editingCampaignIndex,
		_userAuth: userAuth
	}

}


export default connect(_mapStateToProps)(withStyles(styles)(GroupRequest));
