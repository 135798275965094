//  @flow

import { ReducerRegistry } from '../redux';

import { SET_MULTISPACE_ID, SET_MULTISPACE_RESUME, SET_CURRENT_MULTISPACE_SPACE, SET_MULTISPACE_SPACES } from './actionTypes';

const STORE_NAME = 'base/multispaces';

const DEFAULT_STATE = {

	multispaceId: undefined,
	multispaceResume: { },
	multispaceSpace: { },
	multispaceSpaces: [ ]

}


ReducerRegistry.register(
    STORE_NAME,
    (state = DEFAULT_STATE, action) => {

		switch (action.type) {

			case SET_CURRENT_MULTISPACE_SPACE:
				return {
					...state,
					multispaceSpace: action.multispaceSpace
				}

			case SET_MULTISPACE_ID:

				return {
					...state,
					multispaceId: action.multispaceId
				}

			case SET_MULTISPACE_RESUME:

				return {
					...state,
					multispaceResume: action.multispaceResume
				}

			case SET_MULTISPACE_SPACES:
				return {
					...state,
					multispaceSpaces: action.multispaceSpaces
				}

            default:
                return state;

        }

    }

);
