//	@flow

import { SET_MY_SPACES_SCREEN } from './actionTypes';

export function setMySpacesScreen(currentMySpacesScreen){

	return {
		type: SET_MY_SPACES_SCREEN,
		currentMySpacesScreen
	}

}
