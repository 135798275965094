// @flow
import React, { Component } from 'react';

import { Button, Container, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import type { Dispatch } from 'redux';

import { CURRENT_DISPLAYING_SCREEN } from '../../app/constants';
import { setCurrentScreen } from '../../app/actions';

import { login } from '../../auth/actions';

import { connect } from '../../redux';

import billboard_img from '../images/billboard.png';


type Props = {
	dispatch: Dispatch
}


const styles = theme => ({

	container: {
		left: '50%',
		position: 'absolute',
		top: '50%',
		transform: 'translate(-50%, -50%)'
	},

	grow: {
		flexGrow: 1
	},

	headerRight: {
		display: 'flex'
	},

	textField: {
		marginBottom: '1.5rem'
	},

	billboard_img: {
		width: '100px'
	}

});



class Login extends Component<Props> {


	constructor(props){

		super(props);

		this.state = {
			isPasswordBeingDisplayed: false,
			username: '',
			password: '',
			name: '',
			lastname: ''
		}

		this._displayForgotPasswordForm = this._displayForgotPasswordForm.bind(this);
		this._inputChanged = this._inputChanged.bind(this);
		this._loadRegistrationForm = this._loadRegistrationForm.bind(this);
		this._login = this._login.bind(this);
		this._togglePasswordVisibility = this._togglePasswordVisibility.bind(this);

	}


	render(){

		const { classes } = this.props;

		return (

			<Container
				maxWidth='xs'
				className={ classes.container }
			>
				<img className={ classes.billboard_img } src={ billboard_img } alt='' />

				<br></br>
				<br></br>

				<h3>¡Bienvenido!, Inicia Sesión:</h3>

				<form noValidate autoComplete="off">

					<TextField
						className={ classes.textField }
						fullWidth
						id="username"
						label="Correo Electrónico"
						onChange={ this._inputChanged }
						value={ this.state.username }
						variant="outlined" />


						<TextField
							className={ classes.textField }

							fullWidth
							id="password"
							InputProps={{
								endAdornment:
									<InputAdornment position="end">
										<IconButton
											aria-label="Visibilidad de la contraseña"
											onClick={ this._togglePasswordVisibility }
											>
											{ this.state.isPasswordBeingDisplayed ? <VisibilityOff /> : <Visibility /> }
										</IconButton>
									</InputAdornment>

							}}
							label="Contrasena"
							onChange={ this._inputChanged }
							value={ this.state.password }
							type={ this.state.isPasswordBeingDisplayed ? "text" : "password" }
							variant="outlined" />



					<Button variant="contained" color="secondary" className={ classes.actionBtn } onClick={ this._login }>Entrar</Button>


				</form>
				<br></br>
				<br></br>
				<hr></hr>
				<p>¿Olvidaste tu Contraseña? <Button color="secondary" onClick={ this._displayForgotPasswordForm }>RECUPERAR CONTRASEÑA</Button></p>
				<hr></hr>
				<p>¿Eres Nuevo? <Button color="secondary" onClick={ this._loadRegistrationForm }>REGÍSTRATE</Button></p>
				<hr></hr>
				<p>Al continuar, aceptas las <Button color="secondary">Condiciones de uso</Button> y el <Button color="secondary">Aviso de Privacidad</Button> de ADXpace.</p>


			</Container>

		);

	}


	_displayForgotPasswordForm(){

		this.props.dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.FORGOT_PASSWORD));

	}


	_inputChanged(event){

		const { id, value } = event.target;

		this.setState({
			[id]: value
		});

	}


	_loadRegistrationForm(){

		this.props.dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.REGISTRATION));

	}


	_login(){

		const user = {
			username: this.state.username,
			password: this.state.password
		}

		this.props.dispatch(login(user));

	}


	_togglePasswordVisibility(){

		const passwordVisibility = !this.state.isPasswordBeingDisplayed;

		this.setState({
			isPasswordBeingDisplayed: passwordVisibility
		});

	}


}



export default withStyles(styles)(connect()(Login));
