

const styles = {
	buttonGroup:{
		marginTop: 17,
		width: '100%',
		zIndex: 9999999
	},
	cancelButton: {
		backgroundColor: 'rgba(180, 4, 4, 0.9)',
		borderRadius: 12,
		color: '#ccc',
		marginBottom: 15,
		marginLeft: 7,
		marginRight: 13,
		paddingBottom: 17,
		paddingLeft: 17,
		paddingRight: 17,
		paddingTop: 11,
	},
	cancelButtonText: {
		color: "#eeeeee",
		fontSize: 22,
		fontWeight: 'bold',
		textAlign: 'center',
	},

	dialogContainer: {
		backgroundColor: 'rgba(250, 250, 250, 0.7)',
		height: '100%',
		left: 0,
		margin: 0,
		position: 'fixed',
		top: 0,
		width: '100%',
		zIndex: 9999
	},

	modal: {

		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'rgba(0,0,0,0.7)'
	},

	modal_body: {
		alignItems: 'flex-end',
		backgroundColor: 'rgba(255, 255, 255, 0.9)',
		margin: 0,
		minHeight: 40,
		width: '100%',
		padding: 5,
		borderBottomLeftRadius: 25,
		borderBottomRightRadius: 25,
	},

	modal_header: {
		backgroundColor: '#E83022',
		borderTopLeftRadius: 25,
		borderTopRightRadius: 25,
		color: '#f5f5f5',
		fontSize: '1.3rem',
		fontWeight: '500',
		margin: 0,
		padding: 10,
	},


	modal_header_text: {
		color: '#eeeeee',
		fontSize: '1.3rem',
		fontWeight: 'bold',
		textAlign: 'center',
	},

	modal_content: {
		fontSize: 19,
		marginBottom: '2.3rem',
		paddingLeft: '1rem',
		paddingRight: '1rem',
		textAlign: 'justify',
	},

	modal_footer: {
		margin: 0,
		padding: 10,
	},


	modal_view: {

		borderWidth: 1,
		borderRadius: 25,
		borderStyle: 'solid',
		backgroundColor: 'rgba(255, 255, 255, 0.9)',
		elevation: 5,
		// height: '60%',
		left: '50%',
		position: 'absolute',
		textAlign: 'center',
		top: '50%',
		transform: 'translate(-50%, -50%)',
		width: '67%',
	},

	okButton: {
		backgroundColor: '#E83022',
		borderRadius: 12,
		color: '#efefef',
		fontWeight: '600',
		marginBottom: 15,
		paddingBottom: 17,
		paddingLeft: 17,
		paddingRight: 17,
		paddingTop: 11,
		// position: 'absolute',
		// right: 0,
		// width: '43%'
	},

	okButtonText: {
		color: "#eeeeee",
		fontSize: 22,
		fontWeight: 'bold',
		textAlign: 'center',
	}
}

export { styles };
