import axios from 'axios';

import { settings } from './settings';

const SERVER_URL = settings.endpoint;


function createCampaign(token, campaignName){

	const params = new URLSearchParams();

	params.append('campaignName', campaignName);


	const config = {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-access-token': token
		}
	}

	return axios.post(`${ SERVER_URL }/campaigns/create`, params, config);
}


function downloadCampaignSpaces(token, campaignId){

	const config = {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-access-token': token
		},
		responseType: 'blob'
	}

	return axios.get(`${ SERVER_URL }/campaigns/downloadCampaignExcel?campaignId=${ campaignId }`, config);

}



function getCampaigns(token){

	const config = {
		headers: {
			'x-access-token': token
		}
	};

	return axios.get(`${ SERVER_URL }/campaigns/list`, config);

}



function getCampaignsStatus(token){

	const config = {
		headers: {
			'x-access-token': token
		}
	};

	return axios.get(`${ SERVER_URL }/campaigns/getStatusList`, config);

}



function getDetailedCampaigns(token){

	const config = {
		headers: {
			'x-access-token': token
		}
	};

	return axios.get(`${ SERVER_URL }/campaigns/getDetailedList`, config);

}



function removeCampaign(token, campaignId){

	const config = {
		headers: {
			'x-access-token': token
		}
	};

	const params = new URLSearchParams();

	params.append('campaignId', campaignId);

	return axios.post(`${ SERVER_URL }/campaigns/removeCampaign`, params, config);

}



function removeSpaceFromCampaign(token, contractId){

	const config = {
		headers: {
			'x-access-token': token
		}
	};

	const params = new URLSearchParams();

	params.append('id', contractId);

	return axios.post(`${ SERVER_URL }/campaigns/removeSpaceFromCampaign`, params, config);

}



function renameCampaign(token, campaignName, campaignId){

	const config = {
		headers: {
			'x-access-token': token
		}
	};

	const params = new URLSearchParams();

	params.append('campaignName', campaignName);
	params.append('campaignId', campaignId);

	return axios.post(`${ SERVER_URL }/campaigns/renameCampaign`, params, config);

}


function requestMultipleQuotations(token, spaces, contractId){



}


function sendQuotationReservationRequest(token, spaceId, contractId){

	const params = new URLSearchParams();

	params.append('id', contractId);
	params.append('spaceId', spaceId);


	const config = {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-access-token': token
		}
	}

	return axios.post(`${ SERVER_URL }/campaigns/requestQuoteReservation`, params, config);

}


function sendQuotationRequest(token, startDate, endDate, contractId, groupId){

	const params = new URLSearchParams();

	params.append('reqStartDate', startDate);
	params.append('reqEndDate', endDate);
	params.append('id', contractId);
	params.append('status', '2');

	if(groupId){
		params.append('groupId', groupId);
	}


	const config = {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-access-token': token
		}
	}

	return axios.post(`${ SERVER_URL }/campaigns/updateContractStatus`, params, config);

}


function updateContractStatus(token, id, status){

	const params = new URLSearchParams();

	params.append('id', id);
	params.append('status', status);


	const config = {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-access-token': token
		}
	}

	return axios.post(`${ SERVER_URL }/campaigns/updateContractStatus`, params, config);

}

export { createCampaign, downloadCampaignSpaces, getCampaigns, getCampaignsStatus, getDetailedCampaigns, removeCampaign, removeSpaceFromCampaign, renameCampaign, requestMultipleQuotations, sendQuotationRequest, sendQuotationReservationRequest, updateContractStatus };
