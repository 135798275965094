// @flow
import React, { Component } from 'react';

import { Button, Card, CardActionArea, CardContent, CardMedia, Grid, ImageList, ImageListItem, Typography  } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ModalImage from "react-modal-image";
import type { Dispatch } from 'redux';

import { setCurrentPost, setCurrentScreen } from '../../app/actions';
import { CURRENT_DISPLAYING_SCREEN } from '../../app/constants';
import { setIframePath } from '../../home';
import { connect } from '../../redux';

import { setMultispaceSpace } from '../actions';


const styles = theme => ({
	descriptionTitle: {
		color: '#878C9F',
    	fontWeight: '500',
	},
	gallery: {
		flexWrap: 'nowrap',
		minHeight: '100%',
		'& div:nth-child(1)': {
			height: '100%',
			minHeight: '100%',
			'& img': {
				objectFit: 'contain',
				width: '100%',
			}
		}
	},
	galleryContainer: {
		display: 'flex',
	    flexWrap: 'wrap',
	    justifyContent: 'space-around',
	    overflow: 'hidden',
	},
	left: {
		marginLeft: '2.5rem',
		textAlign: 'left',
	},
	mainContent:{
        paddingBottom: '7rem',
    },
	multispaceDescription:{
		textAlign: 'center'
	},
	multispaceImage: {
		height: '100%',
	},
	multispaceResume: {
		backgroundColor: '#111111',
		color: '#eeeeee',
		marginBottom: '1.3rem',
		paddingBottom: '1rem',
		paddingTop: '2.3rem',
	},
	multispaceTitle: {
		fontSize: '1.3rem'
	},
	spaceDescription: {
		color: '#333333',
		fontSize: '1rem',
		paddingRight: '1.2rem',
		textAlign: 'left'
	},
	spaceImage: {
		maxHeight: '13rem'
	},
	spacesList: {
		marginLeft: 'auto',
		marginRight: 'auto',
		maxWidth: '90%'
	},
	spaceTitle: {
		fontSize: '1.1rem',
		fontWeight: 'bold'
	}
});


type Props = {
	_multispaceId: Number,
	_multispaceResume: Object,
	_multispaceSpaces: Array<Object>,
	_userAuth: Object,
	_userLoggedIn: Boolean,
	dispatch: Dispatch
}


class MultispacesList extends Component<Props> {

    constructor(props){

        super(props);

		this._displaySpace = this._displaySpace.bind(this);
		this._navigateToSearch = this._navigateToSearch.bind(this);

    }



	componentDidMount(){



	}


    render(){

		const { _multispaceResume, _multispaceSpaces, classes } = this.props ;

		const multispaceImages = _multispaceResume.images && _multispaceResume.images.length ? _multispaceResume.images : ['https://testing.adxpace.com/spaces/wp-content/uploads/2021/07/adxpace_favicon-424x280.png'];

        return (

            <Grid className={ classes.mainContent } container>

				<Grid  item xs={12}>
					<Grid alignItems="center" className={ classes.multispaceResume } container justifyContent="center" spacing={ 3 }>

						<Grid item md={ 2 } xs={ 11 }>

							<div className={ classes.galleryContainer }>

								<ImageList className={ classes.gallery } rows={ 1 } cols={ 1.5 }>

								{
									multispaceImages.map((image, index) => (

										<ImageListItem   key={ `HolderImage_${ index }` }>
											<ModalImage className={ classes.multispaceImage } hideDownload={ true } small={ image } large={ image } alt="Foto del multiespacio" />
										</ImageListItem>

									))
								}

								</ImageList>
							</div>
						</Grid>

						<Grid item md={ 4 } xs={ 10 }>
							<Typography className={ classes.multispaceTitle } component="h3">
								{ _multispaceResume.post_title } - [ { _multispaceSpaces.length } espacios ]
							</Typography>
							<Typography className={ classes.multispaceDescription } component="p">
								{ _multispaceResume.metadata?._cth_calle } { _multispaceResume.metadata?._cth_numero }{ _multispaceResume.metadata?._cth_colonia && <span>, Col. { _multispaceResume.metadata?._cth_colonia }</span> }{ _multispaceResume.metadata?._cth_municipio && <span>, { _multispaceResume.metadata?._cth_municipio }</span> }{ _multispaceResume.metadata?._cth_ciudad && <span>, { _multispaceResume.metadata?._cth_ciudad }</span> }{ _multispaceResume.metadata?._cth_estado && <span>, { _multispaceResume.metadata?._cth_estado }</span> }{ _multispaceResume.metadata?._cth_codigo_postal && <span>, { _multispaceResume.metadata?._cth_codigo_postal }</span> }
							</Typography>
						</Grid>

						<Grid className={ classes.left } item xs={ 12 }>
							<Button variant="contained" className={ classes.backButton } startIcon={ <ArrowBackIosIcon /> } onClick={ e => this._navigateToSearch() }>
								Volver a resultados de B&uacute;squeda
							</Button>
						</Grid>

					</Grid>
				</Grid>

				<Grid className={ classes.spacesList } container justifyContent="center" spacing={ 7 }>

					{
						_multispaceSpaces.map((space, i) =>

							<Grid item key={`space_${ i }`} xs={ 12 } md={ 3 }>
								<Card>
									<CardActionArea onClick={ e => this._displaySpace(space) }>
										<CardMedia className={ classes.spaceImage }
											component="img" alt="Imagen del espacio" image={ space.images[0]?.guid || `https://testing.adxpace.com/spaces/wp-content/uploads/2021/07/adxpace_favicon-424x280.png` }
										/>
										<CardContent>
											<Typography className={ classes.spaceTitle } component="h3">
												{ space.post_title }
											</Typography>

											<Grid container justifyContent="space-evenly" spacing={ 1 }>

												{
													space.metadata._cth_base &&
													<Grid className={ classes.spaceDescription } item xs={ 5 }>
														<Typography className={ classes.descriptionTitle } component="p">
															Base
														</Typography>
														<Typography className={ classes.descriptionContent } component="p">
															{ space.metadata._cth_base }
														</Typography>
													</Grid>
												}

												{
													space.metadata._cth_estructura &&
													<Grid className={ classes.spaceDescription } item xs={ 5 }>
														<Typography className={ classes.descriptionTitle } component="p">
															Estructura
														</Typography>
														<Typography className={ classes.descriptionContent } component="p">
															{ space.metadata._cth_estructura }
														</Typography>
													</Grid>
												}

												{
													space.metadata._cth_altura &&
													<Grid className={ classes.spaceDescription } item xs={ 5 }>
														<Typography className={ classes.descriptionTitle } component="p">
															Altura
														</Typography>
														<Typography className={ classes.descriptionContent } component="p">
															{ space.metadata._cth_altura }
														</Typography>
													</Grid>
												}

												{
													space.metadata._cth_tamano_pantalla &&
													<Grid className={ classes.spaceDescription } item xs={ 5 }>
														<Typography className={ classes.descriptionTitle } component="p">
															Tama&ntilde;o de pantalla (pulgadas)
														</Typography>
														<Typography className={ classes.descriptionContent } component="p">
															{ space.metadata._cth_tamano_pantalla }
														</Typography>
													</Grid>
												}

											</Grid>

										</CardContent>
									</CardActionArea>
								</Card>
							</Grid>

						)
					}

				</Grid>

            </Grid>

        )

    }


	_displaySpace(space){

		const { _userLoggedIn, dispatch } = this.props;

		if(!_userLoggedIn){

			dispatch(setCurrentPost(space.post_name));
			dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.LOGIN));

		} else {

			this.props.dispatch(setMultispaceSpace(space));
			this.props.dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.SPACE_DETAIL));

		}


	}


	_navigateToSearch(){

		this.props.dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.WELCOME));
		this.props.dispatch(setIframePath(this.props._currentSearchTerm));
		// this.props.dispatch(setIframePath(this.props._lastSearchTerm));

	}


}


function _mapStateToProps(state){

	const { currentScreen, currentSearchTerm, lastSearchTerm } = state['base/app'];
	const { userAuth, userLoggedIn } = state['base/auth'];
	const { multispaceResume, multispaceSpaces } = state['base/multispaces'];

	return {
		_currentSearchTerm: currentSearchTerm,
		_lastSearchTerm: lastSearchTerm,
		_multispaceResume: multispaceResume,
		_multispaceSpaces: multispaceSpaces,
		_userAuth: userAuth,
		_userLoggedIn: userLoggedIn
	}

}



export default connect(_mapStateToProps)(withStyles(styles)(MultispacesList));
