import React, { Component } from 'react';
import { Container } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import loader from "../images/loader.gif";


const styles = theme => ({

	container: {
		left: '50%',
		position: 'absolute',
		top: '50%',
		transform: 'translate(-50%, -50%)',
	},

	loader: {
		maxWidth: '25%',
		maxHeight: '25%'
	},

	message: {
		color: '#000000',
		fontSize: '1.2rem',
		fontWeight: '600'
	}

});


class Loader extends Component {


	render(){

		const { classes } = this.props;

		return (

			<Container className={ classes.container } disableGutters maxWidth={ false }>

				<img className={ classes.loader } src={ loader } alt="Logo adxpace" />

				<p className={ classes.message }>Cargando...</p>


			</Container>

		);

	}

}


export default withStyles(styles)(Loader);