//	@flow

export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';

export const DOWNLOAD_CAMPAIGN = 'DOWNLOAD_CAMPAIGN';

export const GET_CAMPAIGNS = 'GET_CAMPAIGNS';

export const GET_CAMPAIGNS_STATUS = 'GET_CAMPAIGNS_STATUS';

export const GET_DETAILED_CAMPAIGNS_LIST = 'GET_DETAILED_CAMPAIGNS_LIST';

export const REQUEST_QUOTATION = 'REQUEST_QUOTATION';

export const REQUEST_QUOTATION_RESERVATION = 'REQUEST_QUOTATION_RESERVATION';

export const REMOVE_CAMPAIGN = 'REMOVE_CAMPAIGN';

export const REMOVE_CONTRACT_FROM_CAMPAIGN = 'REMOVE_CONTRACT_FROM_CAMPAIGN';

export const RENAME_CAMPAIGN = 'RENAME_CAMPAIGN';

export const SET_CAMPAIGNS = 'SET_CAMPAIGNS';

export const SET_CAMPAIGNS_STATUS = 'SET_CAMPAIGNS_STATUS';

export const SET_CURRENT_EDITING_CAMPAIGN = 'SET_CURRENT_EDITING_CAMPAIGN';

export const SET_DETAILED_CAMPAIGNS = 'SET_DETAILED_CAMPAIGNS';

export const SET_DISPLAY_ADD_TO_CAMPAIGN = 'SET_DISPLAY_ADD_TO_CAMPAIGN';

export const SET_DISPLAYING_NEW_CAMPAIGN_INPUT = 'SET_DISPLAYING_NEW_CAMPAIGN_INPUT';

export const SET_EDITING_CAMPAIGN_INDEX = 'SET_EDITING_CAMPAIGN_INDEX';

export const SET_EDITING_CAMPAIGN_SPACES = 'SET_EDITING_CAMPAIGN_SPACES';

export const UPDATE_CONTRACT_STATUS = 'UPDATE_CONTRACT_STATUS';
