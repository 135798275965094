// @flow

import React, { Component } from 'react';

import { AppBar, Toolbar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import type { Dispatch } from 'redux';

import { setCurrentScreen } from '../../app/actions';
import { CURRENT_DISPLAYING_SCREEN } from '../../app/constants';
import { logoutUser } from '../../auth/actions';
import { setIframePath } from '../../home/actions';
import { setMySpacesScreen } from '../../mySpaces/actions';
import { SPACES_SCREENS } from '../../mySpaces/constants';

import { connect } from '../../redux';

import '../styles/header.css';

import logo from "../images/logo.png";

import AppBarCollapse from "./appBarCollapse";

const styles = theme => ({

	logo: {
		backgroundColor: "#eeeeee",
		borderRadius: "0.3rem",
		height: '2.9rem',
		padding: '0 0.5rem'
	},

	title: {
		color: 'white',
		fontSize: '1.1rem',
		fontWeight: 500,
		marginRight: theme.spacing(2),
		paddingBottom: '0.3rem',
		paddingLeft: '0.7rem',
		paddingRight: '0.7rem',
		paddingTop: '0.3rem',
		'&:hover': {
			backgroundColor: '#912015',
			textDecoration: 'none',
		}
	},

	MenuLink:{

		fontSize: '1.1rem',

	},

	grow: {
		flexGrow: 1
	},

	headerRight: {
		display: 'flex'
	}

});


type Props = {

	_userData: Object,
	_userLoggedIn: Boolean,
	dispatch: Dispatch

}


/*
<Grid item xs={ 2 }>
	<Link href="#" className={ classes.title } onClick={ () => this._goHome() }>
		<IconButton edge="start" className={ classes.MenuLink } color="inherit" aria-label="menu"><HomeIcon />&nbsp;&nbsp;Inicio</IconButton>
	</Link>
</Grid>


<Grid item xs={ 2 }>
	<Link href="#" className={ classes.title } onClick={ () => this._gotoSpacesSearch() }>
		<IconButton edge="start" className={ classes.MenuLink } color="inherit" aria-label="menu"><SearchIcon />&nbsp;&nbsp;Buscar espacios</IconButton>
	</Link>
</Grid>


<Grid item xs={ 2 } style={{ textAlign: 'right' }}>


	{ _userLoggedIn ?

		<Link href="#" className={ classes.title } onClick={ () => this._loadPanel() }>
			Mis espacios
		</Link>

		:

		<Link href="#" className={ classes.title } onClick={ () => this._loadLogin() }>
			Iniciar sesi&oacute;n
		</Link>

	}

</Grid>
*/


/*
<Grid container direction="row" justifyContent="space-between" alignItems="center" variant="inherit">

	<Grid item xs={ 2 }>

		<img className={ classes.logo } src={ logo } alt="Logo adxpace" />

	</Grid>




	<Grid item xs={ 2 }>

		{ _userLoggedIn &&

			<Link href="#"  className={ classes.title }>
				<IconButton aria-controls="sesionMenu" edge="start" className={ classes.MenuLink } color="inherit" aria-label="menu" onClick={ this._displayAccountMenu }><AccountCircleIcon /> &nbsp; { userData.name }</IconButton>
			</Link>

		}


		<Menu
			id="sesionMenu"
			anchorEl={this.state.anchorEl}
			keepMounted
			open={Boolean(this.state.anchorEl)}
			onClose={ this._hideMenu }
		>
			<MenuItem onClick={ this._closeSession }>Cerrar Sesión</MenuItem>

		</Menu>

	</Grid>

</Grid>
*/


class Header extends Component<Props> {

	constructor(props){

		super(props);

		this._closeSession = this._closeSession.bind(this);
		this._displayAccountMenu = this._displayAccountMenu.bind(this);
		this._goHome = this._goHome.bind(this);
		this._gotoSpacesSearch = this._gotoSpacesSearch.bind(this);
		this._hideMenu = this._hideMenu.bind(this);
		this._loadLogin = this._loadLogin.bind(this);


		this.state = {
			anchorEl: null,
			setAnchorEl: 0
		};



		this.handleClose = () => {



			console.log("Cerrando sesion");

		};

	}


    render(){

		const {  classes } = this.props;

        return(
            <div className="header">

					<AppBar position="sticky">

						<Toolbar>

							<img className={ classes.logo } src={ logo } alt="Logo adxpace" />

							<AppBarCollapse history={ this.props.history } />

						</Toolbar>
					</AppBar>
            </div>
        )

    }


	_closeSession(){

		this.props.dispatch(logoutUser());
		this._hideMenu();

	}


	_displayAccountMenu(event){

		this.setState({ anchorEl: event.currentTarget });

	}


	_goHome(){

		this.props.history?.push('/');
		this.props.dispatch(setIframePath('/spaces/index.php'));
		this.props.dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.WELCOME));

	}

	_gotoSpacesSearch(){

		this.props.history?.push('/');
		this.props.dispatch(setIframePath('/index.php/adxpace-resultados-busqueda/'));
		this.props.dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.WELCOME));

	}


	_hideMenu(){

		this.setState({ anchorEl:null });

	}


	_loadLogin(){

		this.props.history?.push('/');
		this.props.dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.LOGIN));

	}


	_loadPanel(){

		const { _userData, _userLoggedIn, dispatch } = this.props;

		if(!_userData.isAdmin && _userLoggedIn){

			dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.CONTROL_PANEL_USER));
			dispatch(setMySpacesScreen(SPACES_SCREENS.MENU));

		}

	}

}



function _mapStateToProps(state){

	const { userData, userLoggedIn } = state['base/auth'];

	return {
		_userData: userData,
		_userLoggedIn: userLoggedIn
	}

}


export default withStyles(styles)(connect(_mapStateToProps)(Header));
