// @flow
import React, { Component } from 'react';
import { Button, Card, CardContent, CardHeader, Grid, ImageList, ImageListItem, Typography  } from '@material-ui/core';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { withStyles } from '@material-ui/core/styles';

import { getLocationTaxonomy, getPostMedia, getSpaceCategories, getSpaceDetails } from '../../api/spaces';
import { mapStyle } from '../../campaigns/mapStyle';
import { connect } from '../../redux';

import logo from "../../header/images/logo.png";
import './spaceDetails.css';


const styles = theme => ({

	alignLeft: {
		fontSize: '14px',
		fontWeight: '600',
		textAlign: 'left'
	},

	alignRight: {
		textAlign: 'right'
	},

	bottomOrangeBorder: {
		borderBottom: 'solid 5px #fc3c2a',
		paddingBottom: '1rem'
	},

	cardHeader: {
		background: '#ffffff',
		marginTop: '0.7rem'
	},

	categoriesContainer: {
		fontSize: '0.9rem',
		marginBottom: '1.5rem',
		marginTop: '1.5rem'
	},

	category: {
		fontSize: '0.8rem',
		textAlign: 'center'
	},

	content: {
		background: '#ffffff',
		fontSize: '0.9rem',
	},

	downloadBtn: {
		background: '#E63626',
		color: '#fbfbfb',
		marginBottom: '3rem',
		marginTop: '0',
		'&:hover': {
			background: '#fc3c2a' ,
			color: '#fff'
		}
	},

	leftBorder: {
		borderLeft: 'solid 2px #000',
		paddingLeft: '1.7rem'
	},

	logo: {
		backgroundColor: "#eeeeee",
		borderRadius: "0.3rem",
		maxHeight: '2.9rem',
		margin: '0.5rem 0 0',
		padding: '0.5rem',
		width: '90%'
	},

	mapContainer:{

		backgroundColor: "#cccccc",
		height:'30vh',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '8px',
		position: 'sticky !important',
		top: '7rem',
		width:'97%',

	},

	mapPrintContainer: {
		backgroundColor: "#cccccc",
		height:'30vh',
		marginLeft: '1rem',
		width:'97%',
	},

	mediaContainer: {
		marginTop: '1.3rem',
		height: '45%'
	},

	priceContainer: {
		border: 'solid 1px #E63626',
		fontSize: '14px',
		fontWeight: '600',
		marginLeft: '5.5in',
		padding: '0.5rem 3rem',
		textAlign: 'center',
		width: '120px'
	},

	printFooter: {
		borderTop: 'solid 5px #fc3c2a',
		fontSize: '12px',
		marginTop: '17px',
		paddingRight: '28px',
		paddingTop: '5px',
		textAlign: 'right'
	},

	root: {
		backgroundColor: '#ffffff',
		height: '11in',
		margin: '1rem auto 1.5rem auto',
		maxHeight: '11in',
		maxWidth: '8.5in',
		minHeight: '11in',
		width: '8.5in'
	},

	small: {
		fontSize: '0.9rem',
		marginTop: '-0.2rem'
	},

	spaceDetails: {
		height: '420px',
		maxHeight: '420px',
		overflow: 'hidden'
	},

	spaceFeature: {
		marginTop: '0.2rem'
	},

	spacePicture: {
		marginBottom: '0.5rem',
		maxWidth: '73%'
	},

	spacePictureMultiple: {
		marginBottom: '0.3rem',
		maxWidth: '50%'
	}

});



function capitalize(str) {

	str = str.trim();
	const lower = str.toLowerCase();
	return str.charAt(0).toUpperCase() + lower.slice(1);

}



function numberFormat(value, decimalPositions, decimalSeparator, thousandsSeparator){

	decimalPositions = isNaN(decimalPositions = Math.abs(decimalPositions)) ? 2 : decimalPositions;
    decimalSeparator = decimalSeparator === undefined ? "." : decimalSeparator;
    thousandsSeparator = thousandsSeparator === undefined ? "," : thousandsSeparator;
    let sign = value < 0 ? "-" : "";
    let i = String(parseInt(value = Math.abs(Number(value) || 0).toFixed(decimalPositions)));
	let k = i.length;
    let j = k > 3 ? k % 3 : 0;

   return sign + (j ? i.substr(0, j) + thousandsSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandsSeparator) + (decimalPositions ? decimalSeparator + Math.abs(value - i).toFixed(decimalPositions).slice(2) : "");

}



class SpaceDetails extends Component {

	constructor(props){

		super(props);

		this.state = {
			categories: [ ],
			displayDownloadBtn: true,
			displayPDF: false,
			err: undefined,
			location: [ ],
			mapMarkers: [ ],
			spaceDetails: { },
			spacePictures: [ ]
		}

		this._downloadDetails = this._downloadDetails.bind(this);
		this._getSpaceDetails = this._getSpaceDetails.bind(this);
		this._renderPrintableDetails = this._renderPrintableDetails.bind(this);

	}


	componentDidMount(){

		const { spaceId } = this.props.match.params;

		this.setState({
			spaceId
		}, () => {

			this._getSpaceDetails();

		});


		window.subscribeToMapa(() => {

			this.google = window.google;

			var mapOptions = {
				zoom: 17,
				disableDoubleClickZoom: true,
				draggable: false,
				scrollwheel: false,
				panControl: false,
				center: new this.google.maps.LatLng( 25.444348 , -100.977792  ),
				mapTypeId: this.google.maps.MapTypeId.TERRAIN,
				disableDefaultUI:true
			};

			this.mapa_ = new this.google.maps.Map( document.getElementById('canvasMapa') ,mapOptions);

			const styledMapOptions = { map: this.mapa_, name: 'minimial' };
			const sMapType = new this.google.maps.StyledMapType( mapStyle, styledMapOptions);
			this.mapa_.mapTypes.set('minimal', sMapType);
			this.mapa_.setMapTypeId('minimal');

		});

	}


	render(){

		const { classes } = this.props;
		const{ categories, displayDownloadBtn, err, location, spaceDetails } = this.state;

		return (

			<>

			<Card className={ classes.root } id="fichaTecnica">

				<Grid container>

					<Grid item xs={ 3 }>
						<img className={ classes.logo } src={ logo } alt="Logo adxpace" />
					</Grid>

					<Grid item xs={ 6 }>
						<CardHeader
					className={ classes.cardHeader }
					title={ err ? 'No se pudo obtener la información del espacio.' : spaceDetails?._cth_id_adxpace ? 'Ficha del espacio ' + spaceDetails._cth_id_adxpace : 'Cargando datos del espacio...' } />
					</Grid>

					<Grid item xs={ 3 }>
						
					</Grid>

				</Grid>

				


				<CardContent className={ classes.content }>

					<Grid container>

						<Grid item xs={ 4 }>

							<ImageList cols={ this.state.spacePictures?.length === 1 ? 1 : 2 } rowHeight={ 200 }>

								{ this.state.spacePictures?.map((pic, i) =>

									<ImageListItem key={ `${ spaceDetails._cth_id_adxpace }_${ i }` }>

										<img src={ pic.guid } alt="Foto del espacio" />

									</ImageListItem>

								)}

							</ImageList>

						</Grid>

						<Grid item xs={ 8 } className={ classes.alignRight } >

							<p className={ classes.small }>Per&iacute;odo de contrataci&oacute;n: &nbsp; <strong>{ spaceDetails._cth_periodo_contratación } d&iacute;as</strong></p>

							<p className={ classes.small }>Per&iacute;odo de pago: &nbsp; <strong>{ spaceDetails._cth_periodo_pago } d&iacute;as</strong></p>

							<p className={ classes.small }>Precio de lista: $ <strong>{ numberFormat(spaceDetails._cth_price, 0) }</strong></p>

							{
								spaceDetails._cth_descuento_volumen !== 'a:0:{}' &&
								
									<p className={ classes.small }><strong>-- Descuento por volumen --</strong></p>
								
							}

							<div id="previewMapContainer">
								<div className={ classes.mapContainer } id="canvasMapa"></div>
							</div>

						</Grid>


						


						<Grid item xs={ 12 } className={ classes.alignLeft } >

							<Grid className={ classes.categoriesContainer } container>

								<Grid item xs={ 12 } >
									<p><strong>Clasificaci&oacute;n: </strong></p>
								</Grid>

								<Grid item xs={ 12 }>

									{
										categories && categories.length > 0 ?

											categories.map((cat, i) =>

												<span className={ classes.category } key={ `category_${i}` }>
													&nbsp; { cat.name } &nbsp; { i < categories.length -1 ? `>>    ` : '' } &nbsp; &nbsp;
												</span>

											)

										: null
									}

								</Grid>

							</Grid>

							{ spaceDetails._cth_calle } { spaceDetails._cth_numero }, { spaceDetails._cth_colonia }, { location.length > 1 ? `${ location[1].name }, ` : ''} { location.length > 0 ? `Estado de ${ location[0].name }, ` : ''} C.  P. { spaceDetails._cth_zip_code }<br/>&nbsp;<br/>
							{ spaceDetails._cth_latitude && `Latitud ${ spaceDetails._cth_latitude },` } 
							{ spaceDetails._cth_longitude && `Longitud ${ spaceDetails._cth_longitude }.`}
							<br/>&nbsp;<br/>

							{ spaceDetails._cth_observaciones } <br/>&nbsp;<br/>

						</Grid>

						{
							spaceDetails._cth_afluencia_anual &&
							<Grid className={ classes.spaceFeature } item xs={ 12 }>
								<Grid item xs={ 6 }> 
									<Typography variant="subtitle1"  align="right" color="textPrimary">
										Afluencia anual:
									</Typography>
								</Grid>
								<Grid item xs={ 6 }> 
									<Typography variant="subtitle1"  align="right" color="textPrimary">
										<strong> { spaceDetails._cth_afluencia_anual && (spaceDetails._cth_afluencia_anual) } </strong><br></br>
									</Typography>
								</Grid>
							</Grid>
						}

						{
							spaceDetails._cth_base &&
							<Grid className={ classes.spaceFeature } item xs={ 12 }>
								<Grid container>
								<Grid item xs={ 6 } className={ classes.alignRight } >
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									Base: &nbsp;
								</Typography>
								</Grid>
								<Grid item xs={ 6 }> 
									<Typography variant="subtitle1"  align="left" color="textPrimary">
										<strong> { spaceDetails._cth_base }m </strong><br></br>
									</Typography>
								</Grid>
								</Grid>
							</Grid>
						}

						{
							spaceDetails._cth_altura &&
							<Grid className={ classes.spaceFeature } item xs={ 12 }>
								<Grid container>
								<Grid item xs={ 6 } className={ classes.alignRight }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									Altura: &nbsp;
								</Typography>
								</Grid>
								<Grid item xs={ 6 } > 
									<Typography variant="subtitle1"  align="left" color="textPrimary">
								<strong> { spaceDetails._cth_altura }m </strong><br></br>
								</Typography>
								</Grid>
								</Grid>
							</Grid>
						}

						{
							spaceDetails._cth_vista &&
							<Grid className={ classes.spaceFeature } item xs={ 12 }>
								<Grid container>
								<Grid item xs={ 6 } className={ classes.alignRight }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									Vista: &nbsp;
								</Typography>
								</Grid>
								<Grid item xs={ 6 } > 
									<Typography variant="subtitle1"  align="left" color="textPrimary">
								<strong> { spaceDetails._cth_vista && (spaceDetails._cth_vista) } </strong><br></br>
								</Typography>
								</Grid>
								</Grid>
							</Grid>
						}

						{
							spaceDetails._cth_tamano_pantalla &&
							<Grid className={ classes.spaceFeature } item xs={ 12 }>
								<Grid container>
								<Grid item xs={ 6 } className={ classes.alignRight }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									Tama&ntilde;o de pantalla: &nbsp;
								</Typography>
								</Grid>
								<Grid item xs={ 6 } > 
									<Typography variant="subtitle1"  align="left" color="textPrimary">
								<strong> { spaceDetails._cth_tamano_pantalla && (spaceDetails._cth_tamano_pantalla) } </strong><br></br>
								</Typography>
								</Grid>
								</Grid>
							</Grid>
						}

						{
							spaceDetails._cth_pixeles &&
							<Grid className={ classes.spaceFeature } item xs={ 12 }>
								<Grid container>
								<Grid item xs={ 6 } className={ classes.alignRight }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									Pixeles: &nbsp;
								</Typography>
								</Grid>
								<Grid item xs={ 6 } > 
									<Typography variant="subtitle1"  align="left" color="textPrimary">
								<strong> { spaceDetails._cth_pixeles && (spaceDetails._cth_pixeles) } </strong><br></br>
								</Typography>
								</Grid>
								</Grid>
							</Grid>
						}


						{
							spaceDetails._cth_estructura &&
							<Grid className={ classes.spaceFeature } item xs={ 12 }>
								<Grid container>
								<Grid item xs={ 6 } className={ classes.alignRight }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									Estructura: &nbsp;
								</Typography>
								</Grid>
								<Grid item xs={ 6 } > 
									<Typography variant="subtitle1"  align="left" color="textPrimary">
								<strong> { spaceDetails._cth_estructura && (spaceDetails._cth_estructura) } </strong><br></br>
								</Typography>
								</Grid>
								</Grid>
							</Grid>
						}


						{
							spaceDetails._cth_orientacion &&
							<Grid className={ classes.spaceFeature } item xs={ 12 }>
								<Grid container>
								<Grid item xs={ 6 } className={ classes.alignRight }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									Orientaci&oacute;n: &nbsp;
								</Typography>
								</Grid>
								<Grid item xs={ 6 } > 
									<Typography variant="subtitle1"  align="left" color="textPrimary">
								<strong> { spaceDetails._cth_orientacion && (spaceDetails._cth_orientacion) } </strong><br></br>
								</Typography>
								</Grid>
								</Grid>
							</Grid>
						}

						
						{
							spaceDetails._cth_vialidad &&
							<Grid className={ classes.spaceFeature } item xs={ 12 }>
								<Grid container>
								<Grid item xs={ 6 } className={ classes.alignRight }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									Vialidad: &nbsp;
								</Typography>
								</Grid>
								<Grid item xs={ 6 } > 
									<Typography variant="subtitle1"  align="left" color="textPrimary">
								<strong> { spaceDetails._cth_vialidad && (spaceDetails._cth_vialidad) } </strong><br></br>
								</Typography>
								</Grid>
								</Grid>
							</Grid>
						}



						{
							spaceDetails._cth_visibilidad &&
							<Grid className={ classes.spaceFeature } item xs={ 12 }>
								<Grid container>
								<Grid item xs={ 6 } className={ classes.alignRight }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									Visibilidad: &nbsp;
								</Typography>
								</Grid>
								<Grid item xs={ 6 } > 
									<Typography variant="subtitle1"  align="left" color="textPrimary">
								<strong> { spaceDetails._cth_visibilidad && (spaceDetails._cth_visibilidad) } </strong><br></br>
								</Typography>
								</Grid>
								</Grid>
							</Grid>
						}


						{
							spaceDetails._cth_nivel_socioeconomico_zona &&
							<Grid className={ classes.spaceFeature } item xs={ 12 }>
								<Grid container>
								<Grid item xs={ 6 } className={ classes.alignRight }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									Nivel socioecon&oacute;mico de la zona: &nbsp;
								</Typography>
								</Grid>
								<Grid item xs={ 6 } > 
									<Typography variant="subtitle1"  align="left" color="textPrimary">
								<strong> { spaceDetails._cth_nivel_socioeconomico_zona && (spaceDetails._cth_nivel_socioeconomico_zona) } </strong><br></br>
								</Typography>
								</Grid>
								</Grid>
							</Grid>
						}


						{
							spaceDetails._cth_nivel_socioeconomico_audiencia &&
							<Grid className={ classes.spaceFeature } item xs={ 12 }>
								<Grid container>
								<Grid item xs={ 6 } className={ classes.alignRight }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									Nivel socioecon&oacute;mico de la audiencia: &nbsp;
								</Typography>
								</Grid>
								<Grid item xs={ 6 } > 
									<Typography variant="subtitle1"  align="left" color="textPrimary">
								<strong> { spaceDetails._cth_nivel_socioeconomico_audiencia && (spaceDetails._cth_nivel_socioeconomico_audiencia) } </strong><br></br>
								</Typography>
								</Grid>
								</Grid>
							</Grid>
						}


						{
							spaceDetails._cth_trafico_vehicular &&
							<Grid className={ classes.spaceFeature } item xs={ 12 }>
								<Grid container>
								<Grid item xs={ 6 } className={ classes.alignRight }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									Tr&aacute;fico vehicular: &nbsp;
								</Typography>
								</Grid>
								<Grid item xs={ 6 } > 
									<Typography variant="subtitle1"  align="left" color="textPrimary">
								<strong> { spaceDetails._cth_trafico_vehicular && (spaceDetails._cth_trafico_vehicular) } </strong><br></br>
								</Typography>
								</Grid>
								</Grid>
							</Grid>
						}


						{
							spaceDetails._cth_audiencia_diaria &&
							<Grid className={ classes.spaceFeature } item xs={ 12 }>
								<Grid container>
								<Grid item xs={ 6 } className={ classes.alignRight }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									Audiencia diaria: &nbsp;
								</Typography>
								</Grid>
								<Grid item xs={ 6 } > 
									<Typography variant="subtitle1"  align="left" color="textPrimary">
								<strong> { spaceDetails._cth_audiencia_diaria && (spaceDetails._cth_audiencia_diaria) } </strong><br></br>
								</Typography>
								</Grid>
								</Grid>
							</Grid>
						}



						{
							spaceDetails._cth_afluencia_anual &&
							<Grid className={ classes.spaceFeature } item xs={ 12 }>
								<Grid container>
								<Grid item xs={ 6 } className={ classes.alignRight }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									Afluencia anual: &nbsp;
								</Typography>
								</Grid>
								<Grid item xs={ 6 } > 
									<Typography variant="subtitle1"  align="left" color="textPrimary">
								<strong> { spaceDetails._cth_afluencia_anual && (spaceDetails._cth_afluencia_anual) } </strong><br></br>
								</Typography>
								</Grid>
								</Grid>
							</Grid>
						}



						{
							spaceDetails._cth_clave_ibope &&
							<Grid className={ classes.spaceFeature } item xs={ 12 }>
								<Grid container>
								<Grid item xs={ 6 } className={ classes.alignRight }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									Clave IBOPE: &nbsp;
								</Typography>
								</Grid>
								<Grid item xs={ 6 } > 
									<Typography variant="subtitle1"  align="left" color="textPrimary">
								<strong> { spaceDetails._cth_clave_ibope && (spaceDetails._cth_clave_ibope) } </strong><br></br>
								</Typography>
								</Grid>
								</Grid>
							</Grid>
						}



						{
							spaceDetails._cth_ibope_glp &&
							<Grid className={ classes.spaceFeature } item xs={ 12 }>
								<Grid container>
								<Grid item xs={ 6 } className={ classes.alignRight }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									IBOPE GLP: &nbsp;
								</Typography>
								</Grid>
								<Grid item xs={ 6 } > 
									<Typography variant="subtitle1"  align="left" color="textPrimary">
								<strong> { spaceDetails._cth_ibope_glp && (spaceDetails._cth_ibope_glp) } </strong><br></br>
								</Typography>
								</Grid>
								</Grid>
							</Grid>
						}



						{
							spaceDetails._cth_ibope_svs &&
							<Grid className={ classes.spaceFeature } item xs={ 12 }>
								<Grid container>
								<Grid item xs={ 6 } className={ classes.alignRight }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									IBOPE SVS: &nbsp;
								</Typography>
								</Grid>
								<Grid item xs={ 6 } > 
									<Typography variant="subtitle1"  align="left" color="textPrimary">
								<strong> { spaceDetails._cth_ibope_svs && (spaceDetails._cth_ibope_svs) } </strong><br></br>
								</Typography>
								</Grid>
								</Grid>
							</Grid>
						}



						{
							spaceDetails._cth_calificacion &&
							<Grid className={ classes.spaceFeature } item xs={ 12 }>
								<Grid container>
								<Grid item xs={ 6 } className={ classes.alignRight }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									Calificaci&oacute;n: &nbsp;
								</Typography>
								</Grid>
								<Grid item xs={ 6 } > 
									<Typography variant="subtitle1"  align="left" color="textPrimary">
								<strong> { spaceDetails._cth_calificacion && (spaceDetails._cth_calificacion) } </strong><br></br>
								</Typography>
								</Grid>
								</Grid>
							</Grid>
						}



						{
							spaceDetails._cth_tipo_establecimiento &&
							<Grid className={ classes.spaceFeature } item xs={ 12 }>
								<Grid container>
								<Grid item xs={ 6 } className={ classes.alignRight }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									Tipo de establecimiento: &nbsp;
								</Typography>
								</Grid>
								<Grid item xs={ 6 } > 
									<Typography variant="subtitle1"  align="left" color="textPrimary">
								<strong> { spaceDetails._cth_tipo_establecimiento && (spaceDetails._cth_tipo_establecimiento) } </strong>
								</Typography>
								</Grid>
								</Grid>
							</Grid>
						}


						{
							spaceDetails._cth_nombre_establecimiento &&
							<Grid className={ classes.spaceFeature } item xs={ 12 }>
								<Grid container>
								<Grid item xs={ 6 } className={ classes.alignRight }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									Nombre del establecimiento: &nbsp;
								</Typography>
								</Grid>
								<Grid item xs={ 6 } > 
									<Typography variant="subtitle1"  align="left" color="textPrimary">
								<strong> { spaceDetails._cth_nombre_establecimiento && (spaceDetails._cth_nombre_establecimiento) } </strong><br></br>
								</Typography>
								</Grid>
								</Grid>
							</Grid>
						}



						{
							spaceDetails._cth_numero_muebles &&
							<Grid className={ classes.spaceFeature } item xs={ 12 }>
								<Grid container>
								<Grid item xs={ 6 } className={ classes.alignRight }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									N&uacute;mero de muebles: &nbsp;
								</Typography>
								</Grid>
								<Grid item xs={ 6 } > 
									<Typography variant="subtitle1"  align="left" color="textPrimary">
								<strong> { spaceDetails._cth_numero_muebles && (spaceDetails._cth_numero_muebles) } </strong><br></br>
								</Typography>
								</Grid>
								</Grid>
							</Grid>
						}



						{
							spaceDetails._cth_numero_caras &&
							<Grid className={ classes.spaceFeature } item xs={ 12 }>
								<Grid container>
								<Grid item xs={ 6 } className={ classes.alignRight }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									N&uacute;mero de caras: &nbsp;
								</Typography>
								</Grid>
								<Grid item xs={ 6 } > 
									<Typography variant="subtitle1"  align="left" color="textPrimary">
								<strong> { spaceDetails._cth_numero_caras && (spaceDetails._cth_numero_caras) } </strong><br></br>
								</Typography>
								</Grid>
								</Grid>
							</Grid>
						}

						{
							spaceDetails._cth_luz !== undefined && spaceDetails._cth_luz !== 'a:0:{}' && <Grid className={ classes.spaceFeature } item xs={ 12 }>
								<Typography variant="subtitle1"  align="center" color="textPrimary">
									<strong>-- Cuenta con luz --</strong><br></br>
								</Typography>
							</Grid>
								
						}

					</Grid>

				</CardContent>

				

			</Card>

				{ displayDownloadBtn && <center><Button className={ classes.downloadBtn } onClick={ this._downloadDetails } variant="contained">Descargar</Button></center> }

			{
				this.state.displayPDF && this._renderPrintableDetails()
			}

			</>

		);

	}


	_downloadDetails(){

		const { spaceId } = this.state;

		this.mapa_.setZoom(17);

		this.setState({
			displayDownloadBtn: false,
			displayPDF: true
		}, () => {


			var mapNode = this.mapa_.getDiv();
			var node = document.querySelector('#mapaPrint');
			node.appendChild(mapNode);


			html2canvas(document.querySelector("#spaceDetails"), { letterRendering: 1, allowTaint: true, useCORS: true })
			.then(canvas => {

		        const imgData = canvas.toDataURL('image/png');
		        const pdf = new jsPDF({
					orientation: "portrait",
					unit: "in",
					format: [8.5, 11]
				});

		        pdf.addImage(imgData, 'PNG', 0, 0, 8.5, 11);
		        pdf.save(`Ficha_espacio-${ spaceId }.pdf`);

		        this.setState({ 
		        	displayDownloadBtn: true, 
		        	displayPDF: false 
		        }, () => {

		        	mapNode = this.mapa_.getDiv();
		        	node = document.querySelector('#previewMapContainer');
		        	node.appendChild(mapNode);

		        });
	    	});

		});

	}



	_getSpaceDetails(){

		const { spaceId } = this.state;

		getLocationTaxonomy(this.props._userAuth.token, spaceId)
		.then(res => {
			if(res.data.categories.length){

				this.setState({
					location: [...res.data.categories]
				});

			}
		}).catch(e => {
			console.error(e);
		});


		getPostMedia(spaceId)
		.then(res => {

			if(res.data.pictures.length){

				this.setState({
					postThumb: res.data.pictures[0].guid,
					spacePictures: res.data.pictures
				});

			}

		}).catch(e => {
			console.error(e);
		});



		getSpaceCategories(this.props._userAuth.token, spaceId)
		.then(res => {
			this.setState({
				categories: [...res.data.categories]
			});
		}).catch(e => {
			console.error(e);
		});



		getSpaceDetails(this.props._userAuth.token, spaceId)
		.then(res => {

			const { err, spaceDetails } = res.data;

			if(err){

				this.setState({
					err
				});

			} else {

				this.setState({
					spaceDetails
				}, () => {

					const google = this.google;

					const position = new google.maps.LatLng(spaceDetails._cth_latitude, spaceDetails._cth_longitude);

					const marker = new google.maps.Marker({
						position,
						title: `${ spaceDetails._cth_calle }${ spaceDetails._cth_numero ? ' ' + spaceDetails._cth_numero : '' }`,
						map: this.mapa_,

					});

					this.setState({
						mapMarkers: [ marker ]
					}, () => {

						const bounds = new google.maps.LatLngBounds();

						this.state.mapMarkers.map(marker =>

							bounds.extend({
								lat: marker.position.lat(),
								lng: marker.position.lng()
							})

						);

						this.mapa_.fitBounds(bounds);

						this.mapa_.setZoom(16);

					});


				});

			}

			


		}).catch(e => {
			console.error(e);
		});

	}


	_renderPrintableDetails(){

		const { classes } = this.props;

		const{ categories, displayDownloadBtn, err, location, spaceDetails } = this.state;

		return (

			<div id="spaceDetails" style={{ fontSize: '0.55rem', height: '11in', maxHeight: '11in', width: '8.5in', maxWidth: '8.5in', padding: '2rem 1.2rem' }} >

				<Grid className={ classes.bottomOrangeBorder } container>

					<Grid item xs={ 3 } style={{ textAlign: 'left' }}>

						<img src={ logo }
						alt="adxpace"
						style={{
							display: "flex",
							alignItems: "left",
							
							marginLeft: "0px",
							maxWidth: '95%',
							padding: "0",
							
						}}
						/>

					</Grid>

					<Grid className={ classes.leftBorder } item xs={ 6 } style={{ textAlign: 'left' }}>
						<Typography variant="body1">SIMPLIFICAMOS LO COMPLICADO</Typography>
						<Typography variant="body2">En la b&uacute;squeda y contrataci&oacute;n de espacios publicitarios.</Typography>
					</Grid>

				</Grid>

				<Grid className={ classes.mediaContainer } container>

					<Grid item xs={ 5 } style={{ textAlign: 'right' }}>

						<ImageList cols={ 1 } rowHeight={ 200 }>

							{ 

								this.state.spacePictures?.map((pic, i) => {
								
									if(i < 2)
										return (

											<ImageListItem key={ `${ spaceDetails._cth_id_adxpace }_${ i }` }>

												<img className={ this.state.spacePictures.length > 2 ? classes.spacePictureMultiple : classes.spacePicture } src={ pic.guid } alt="Foto del espacio" />

											</ImageListItem>

										)		
									
									return null

								})

							}

						</ImageList>

					</Grid>

					<Grid align-items-xs-center item xs={ 6 } style={{ textAlign: 'center' }}>

						<div className={ classes.mapPrintContainer } id="mapaPrint"></div>

					</Grid>

				</Grid>

				<Grid className={ classes.spaceDetails } container>

					<Grid item xs={ 12 } className={ classes.alignLeft }>

						Categor&iacute;as: 

						{
							categories && categories.length > 0 ?

								categories.map((cat, i) =>

									<span className={ classes.category } key={ `category_${i}` }>
										&nbsp; { cat.name } &nbsp; { i < categories.length -1 ? `/` : '' } &nbsp; &nbsp;
									</span>

								)

							: null
						}

						<br/>&nbsp;<br/>

					</Grid>


					<Grid item xs={ 12 } className={ classes.alignLeft } >


						Direcci&oacute;n: { spaceDetails._cth_calle } { spaceDetails._cth_numero }, { spaceDetails._cth_colonia }, { location.length > 1 ? `${ location[1].name }, ` : ''} { location.length > 0 ? `Estado de ${ location[0].name }, ` : ''} C.  P. { spaceDetails._cth_zip_code }
						
						<br/>&nbsp;<br/>

						Observaciones: { spaceDetails._cth_observaciones } <br/>&nbsp;<br/>

						Coordenadas: { spaceDetails._cth_latitude && `Latitud ${ spaceDetails._cth_latitude }, ` } 
						{ spaceDetails._cth_longitude && `Longitud ${ spaceDetails._cth_longitude }.`}
						<br/>&nbsp;<br/>


					</Grid>

					{
						spaceDetails._cth_afluencia_anual &&
						<Grid className={ classes.alignLeft } item xs={ 12 }>
							
							Afluencia anual: { spaceDetails._cth_afluencia_anual && (spaceDetails._cth_afluencia_anual) } <br/>&nbsp;<br/>
							
						</Grid>
					}

					{
						(spaceDetails._cth_base || spaceDetails._cth_altura) &&

						<Grid className={ classes.alignLeft } item xs={ 12 }>

							Medidas: &nbsp; { spaceDetails._cth_base && `${ spaceDetails._cth_base } x ` } { spaceDetails._cth_altura && `${ spaceDetails._cth_altura } m` } <br/>&nbsp;<br/>
							
						</Grid>
					}


					{
						spaceDetails._cth_vista &&
						<Grid className={ classes.alignLeft } item xs={ 12 }>
							
							Vista:  { spaceDetails._cth_vista && (spaceDetails._cth_vista) } <br/>&nbsp;<br/>
							
						</Grid>
					}

					{
						spaceDetails._cth_tamano_pantalla &&
						<Grid className={ classes.alignLeft } item xs={ 12 }>
							
							Tama&ntilde;o de pantalla: { spaceDetails._cth_tamano_pantalla && (spaceDetails._cth_tamano_pantalla) } <br/>&nbsp;<br/>

						</Grid>
					}

					{
						spaceDetails._cth_pixeles &&
						<Grid className={ classes.alignLeft } item xs={ 12 }>
							
							Pixeles: { spaceDetails._cth_pixeles && (spaceDetails._cth_pixeles) } <br/>&nbsp;<br/>

						</Grid>
					}


					{
						spaceDetails._cth_estructura &&
						<Grid className={ classes.alignLeft } item xs={ 12 }>
							
							Estructura: { spaceDetails._cth_estructura && (spaceDetails._cth_estructura) } <br/>&nbsp;<br/>
							
						</Grid>
					}


					{
						spaceDetails._cth_orientacion &&
						<Grid className={ classes.alignLeft } item xs={ 12 }>
							
							Orientaci&oacute;n: { spaceDetails._cth_orientacion && (spaceDetails._cth_orientacion) }<br/>&nbsp;<br/>
						
						</Grid>
					}

					
					{
						spaceDetails._cth_vialidad &&
						<Grid className={ classes.alignLeft } item xs={ 12 }>
							
							Vialidad: { spaceDetails._cth_vialidad && (spaceDetails._cth_vialidad) } <br/>&nbsp;<br/>
							
						</Grid>
					}



					{
						spaceDetails._cth_visibilidad &&
						<Grid className={ classes.alignLeft } item xs={ 12 }>
							
							Visibilidad:  { spaceDetails._cth_visibilidad && (spaceDetails._cth_visibilidad) } <br/>&nbsp;<br/>
							
						</Grid>
					}


					{
						spaceDetails._cth_nivel_socioeconomico_zona &&
						<Grid className={ classes.alignLeft } item xs={ 12 }>
							
							Nivel socioecon&oacute;mico de la zona: { spaceDetails._cth_nivel_socioeconomico_zona && (spaceDetails._cth_nivel_socioeconomico_zona) } <br/>&nbsp;<br/>
							
						</Grid>
					}


					{
						spaceDetails._cth_nivel_socioeconomico_audiencia &&
						<Grid className={ classes.alignLeft } item xs={ 12 }>
							
							Nivel socioecon&oacute;mico de la audiencia: { spaceDetails._cth_nivel_socioeconomico_audiencia && (spaceDetails._cth_nivel_socioeconomico_audiencia) } <br/>&nbsp;<br/>
							
						</Grid>
					}


					{
						spaceDetails._cth_trafico_vehicular &&
						<Grid className={ classes.alignLeft } item xs={ 12 }>
							
							Tr&aacute;fico vehicular: { spaceDetails._cth_trafico_vehicular && (spaceDetails._cth_trafico_vehicular) } <br/>&nbsp;<br/>
							
						</Grid>
					}


					{
						spaceDetails._cth_audiencia_diaria &&
						<Grid className={ classes.alignLeft } item xs={ 12 }>
							
							Audiencia diaria: { spaceDetails._cth_audiencia_diaria && (spaceDetails._cth_audiencia_diaria) } <br/>&nbsp;<br/>
							
						</Grid>
					}



					{
						spaceDetails._cth_afluencia_anual &&
						<Grid className={ classes.alignLeft } item xs={ 12 }>
							
							Afluencia anual: { spaceDetails._cth_afluencia_anual && (spaceDetails._cth_afluencia_anual) } <br/>&nbsp;<br/>
							
						</Grid>
					}



					{
						spaceDetails._cth_clave_ibope &&
						<Grid className={ classes.alignLeft } item xs={ 12 }>
							
							Clave IBOPE: { spaceDetails._cth_clave_ibope && (spaceDetails._cth_clave_ibope) } <br/>&nbsp;<br/>
							
						</Grid>
					}



					{
						spaceDetails._cth_ibope_glp &&
						<Grid className={ classes.alignLeft } item xs={ 12 }>
							
							IBOPE GLP: { spaceDetails._cth_ibope_glp && (spaceDetails._cth_ibope_glp) } <br/>&nbsp;<br/>
							
						</Grid>
					}



					{
						spaceDetails._cth_ibope_svs &&
						<Grid className={ classes.alignLeft } item xs={ 12 }>
							
							IBOPE SVS: { spaceDetails._cth_ibope_svs && (spaceDetails._cth_ibope_svs) } <br/>&nbsp;<br/>
							
						</Grid>
					}



					{
						spaceDetails._cth_calificacion &&
						<Grid className={ classes.alignLeft } item xs={ 12 }>
							
							Calificaci&oacute;n: { spaceDetails._cth_calificacion && (spaceDetails._cth_calificacion) } <br/>&nbsp;<br/>
							
						</Grid>
					}



					{
						spaceDetails._cth_tipo_establecimiento &&
						<Grid className={ classes.alignLeft } item xs={ 12 }>
							
							Tipo de establecimiento: { spaceDetails._cth_tipo_establecimiento && (spaceDetails._cth_tipo_establecimiento) } <br/>&nbsp;<br/>
							
						</Grid>
					}


					{
						spaceDetails._cth_nombre_establecimiento &&
						<Grid className={ classes.alignLeft } item xs={ 12 }>
							
							Nombre del establecimiento: { spaceDetails._cth_nombre_establecimiento && (spaceDetails._cth_nombre_establecimiento) } <br/>&nbsp;<br/>
							
						</Grid>
					}



					{
						spaceDetails._cth_numero_muebles &&
						<Grid className={ classes.alignLeft } item xs={ 12 }>

							N&uacute;mero de muebles: { spaceDetails._cth_numero_muebles && (spaceDetails._cth_numero_muebles) } <br/>&nbsp;<br/>
							
						</Grid>
					}



					{
						spaceDetails._cth_numero_caras &&
						<Grid className={ classes.alignLeft } item xs={ 12 }>

							N&uacute;mero de caras: { spaceDetails._cth_numero_caras && (spaceDetails._cth_numero_caras) } <br/>&nbsp;<br/>
							
						</Grid>
					}

					{
						spaceDetails._cth_luz !== undefined && spaceDetails._cth_luz !== 'a:0:{}' && <Grid className={ classes.alignLeft } item xs={ 12 }>
							
								-- Cuenta con luz --<br/>&nbsp;<br/>
							
						</Grid>
							
					}

					<Grid className={ classes.alignLeft } item xs={ 12 }>

						Per&iacute;odo de contrataci&oacute;n: &nbsp; { spaceDetails._cth_periodo_contratación } d&iacute;as. <br/>&nbsp;<br/>

						Per&iacute;odo de pago: &nbsp; { spaceDetails._cth_periodo_pago } d&iacute;as. <br/>&nbsp;<br/>

					</Grid>

				</Grid>

				<Grid container>

					<Grid item xs={ 8 }></Grid>

					<Grid className={ classes.priceContainer } item xs={ 4 } >

						Tarifa: <br/>
						$ { numberFormat(spaceDetails._cth_price, 0) }

					</Grid>

				</Grid>

				<Grid className={ classes.printFooter } item xs={ 12 }>

					www.adxpace.com / Copyright &copy; / { new Date().getFullYear() }

				</Grid>

			</div>

		)

	}

}


function _mapStateToProps(state){

	const { userAuth } = state['base/auth'];

	return {
		_userAuth: userAuth
	}

}


export default connect(_mapStateToProps)(withStyles(styles)(SpaceDetails));
