import axios from 'axios';

import { settings } from './settings';

const SERVER_URL = settings.endpoint;


function getMultispaceSpaces(multispaceId){

	return axios.get(`${ SERVER_URL }/spaces/getMultispaceSpaces?postId=${ multispaceId }`);

}


function getPostMedia(postId){

	return axios.get(`${ SERVER_URL }/favorites/getPictures?postId=${ postId }`);

}


function getSpaceCalendar(token, spaceId){

	const config = {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-access-token': token
		}
	}

	return axios.get(`${ SERVER_URL }/spaces/getCalendar?spaceId=${ spaceId }`, config);

}


function getSpaceCategories(token, postId){

	const config = {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-access-token': token
		}
	}

	return axios.get(`${ SERVER_URL }/spaces/getCategories?postId=${ postId }`, config);

}



function getLocationTaxonomy(token, postId){

	const config = {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-access-token': token
		}
	}

	return axios.get(`${ SERVER_URL }/spaces/getLocationTaxonomy?postId=${ postId }`, config);

}



function getSpaceDetails(token, postId){

	const config = {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-access-token': token
		}
	}

	return axios.get(`${ SERVER_URL }/spaces/getDetails?postId=${ postId }`, config);

}

export { getLocationTaxonomy, getMultispaceSpaces, getPostMedia, getSpaceCalendar, getSpaceCategories, getSpaceDetails };
