//  @flow

import { ReducerRegistry } from '../redux';

import { SET_IFRAME_PATH } from './actionTypes';


const STORE_NAME = 'base/home';

const DEFAULT_STATE = {

	iframePath: '/'

}


ReducerRegistry.register(
    STORE_NAME,
    (state = DEFAULT_STATE, action) => {

		switch (action.type) {

			case SET_IFRAME_PATH:

				return {
					...state,
					iframePath: action.iframePath
				}


        default:
            return state;

    }

});
