// @flow

import { SET_CURRENT_POST, SET_CURRENT_SCREEN, SET_CURRENT_SEARCH_TERM, SET_LAST_SEARCH_TERM, SET_LOADER_HIDDEN } from './actionTypes';



/**
 * Sets the current displaying screen.
 *
 * @param {number} currentScreen.
 * @returns {{
 *     type: SET_CURRENT_SCREEN,
 *     currentScreen: number
 * }}
 */
export function setCurrentScreen(currentScreen){

    return {
        type: SET_CURRENT_SCREEN,
        currentScreen
    }

}


export function setCurrentPost(currentPost){

	return {
		type: SET_CURRENT_POST,
		currentPost
	}

}


export function setCurrentSearchTerm(currentSearchTerm){

	return {
		type: SET_CURRENT_SEARCH_TERM,
		currentSearchTerm
	}

}



export function setLastSearchTerm(lastSearchTerm){

	return {
		type: SET_LAST_SEARCH_TERM,
		lastSearchTerm
	}

}


export function setLoaderHidden(isLoaderHidden){

	return {
		type: SET_LOADER_HIDDEN,
		isLoaderHidden
	}

}
