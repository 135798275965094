//  @flow

import { ReducerRegistry } from '../redux';

import { SET_CAMPAIGNS, SET_CAMPAIGNS_STATUS, SET_CURRENT_EDITING_CAMPAIGN, SET_DETAILED_CAMPAIGNS, SET_DISPLAY_ADD_TO_CAMPAIGN, SET_DISPLAYING_NEW_CAMPAIGN_INPUT, SET_EDITING_CAMPAIGN_INDEX, SET_EDITING_CAMPAIGN_SPACES } from './actionTypes';


const STORE_NAME = 'base/campaigns';

const DEFAULT_STATE = {

	campaigns: [ ],
	campaignsStatus: { },
	currentEditingCampaign: { },
	detailedCampaigns: [ ],
	displayAddToCampaign: false,
	editingCampaignIndex: undefined,
	editingCampaignSpaces: [ ],
	isDisplayingNewCampaignInput: false,
}


ReducerRegistry.register(
    STORE_NAME,
    (state = DEFAULT_STATE, action) => {

		switch (action.type) {

			case SET_CAMPAIGNS:

				return {
					...state,
					campaigns: action.campaigns
				}


			case SET_CAMPAIGNS_STATUS:
				return {
					...state,
					campaignsStatus: action.campaignsStatus
				}


			case SET_CURRENT_EDITING_CAMPAIGN:
				return {
					...state,
					currentEditingCampaign: action.currentEditingCampaign
				}


			case SET_DETAILED_CAMPAIGNS:
				return{
					...state,
					detailedCampaigns: [...action.detailedCampaigns]
				}

			case SET_DISPLAY_ADD_TO_CAMPAIGN:

				return {
					...state,
					displayAddToCampaign: action.displayAddToCampaign
				}

			case SET_DISPLAYING_NEW_CAMPAIGN_INPUT:

				return {
					...state,
					isDisplayingNewCampaignInput: action.isDisplayingNewCampaignInput
				}


			case SET_EDITING_CAMPAIGN_INDEX:
				return {
					...state,
					editingCampaignIndex: action.editingCampaignIndex
				}


			case SET_EDITING_CAMPAIGN_SPACES:
				return {
					...state,
					editingCampaignSpaces: action.campaign
				}


        default:
            return state;

    }

});
