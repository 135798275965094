//  @flow
import React, { Component } from 'react';
import type { Dispatch } from 'redux';

import { Slide, Snackbar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from '../../redux';

import { setToastVisible } from '../actions';

const styles = theme => ({
	root: {
		border: 'solid outset 3px #eee',
		fontSize: '1.3rem',
		fontWeight: '500',
		padding: '1.3rem 2.5rem'
	}
});


type Props = {

	_isToastVisible: Boolean,
	_toastMessage: String,

	dispatch: Dispatch<any>

}


class SlideTransition extends Component{

	render(){

		return <Slide { ...this.props } direction="down" />

	}

}


class Toast extends Component<Props> {

	constructor(props){

		super(props);

		this._handleClose = this._handleClose.bind(this);

	}


	render() {

		const { _isToastVisible, _toastMessage, classes } = this.props;

		return (

			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				autoHideDuration={ 6000 }
				ContentProps={{
					classes: {
						root: classes.root
					}
				}}
				message={ _toastMessage }
				onClose={ this._handleClose }
				open={ _isToastVisible }
				TransitionComponent={ SlideTransition }
			/>

		);

	}


	_handleClose(){

		this.props.dispatch(setToastVisible(false));

	}


}


function _mapStateToProps(state){

	const { isToastVisible, toastMessage } = state['base/notifications'];

	return {
		_isToastVisible: isToastVisible,
		_toastMessage: toastMessage
	}

}


export default connect(_mapStateToProps)(withStyles(styles)(Toast));
