import React, { useState } from "react";

import { Button, ListItemIcon, Menu, MenuItem, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AppsIcon from '@material-ui/icons/Apps';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';

import { connect } from '../../redux';

import { setCurrentScreen } from '../../app/actions';
import { CURRENT_DISPLAYING_SCREEN } from '../../app/constants';
import { logoutUser } from '../../auth/actions';
import { setIframePath } from '../../home/actions';
import { setMySpacesScreen } from '../../mySpaces/actions';
import { SPACES_SCREENS } from '../../mySpaces/constants';


import ButtonAppBarCollapse from "./buttonAppBarCollapse";


const styles = theme => ({
    root: {
        position: "absolute",
        right: '2rem',
    },
    buttonBar: {
        [theme.breakpoints.down("sm")]: {
            display: "none"
        },
        margin: "10px",
        paddingLeft: "16px",
        right: 0,
        position: "relative",
        width: "100%",
        background: "transparent"
    },
    icon: {
        minWidth: '15px',
        width: '1.9rem',
    },
    menuButton: {
        [theme.breakpoints.up("md")]: {
            marginRight: theme.spacing(3),
        },
        [theme.breakpoints.up("lg")]: {
            marginRight: theme.spacing(15),
        },
        [theme.breakpoints.only("xl")]: {
            marginRight: theme.spacing(30),
        },
        '&:hover': {
			backgroundColor: '#912015',
			textDecoration: 'none',
		}
    },
    menuButtonText: {
        color: 'gray',
		fontSize: '1.1rem',
		fontWeight: 500,
    },
    title: {
		color: 'white',
		fontSize: '1.1rem',
		fontWeight: 500,
        // marginLeft: theme.spacing(1),
		// marginRight: theme.spacing(1),
		paddingBottom: '0.3rem',

		paddingTop: '0.3rem',

	},
    userButton: {
        marginLeft: '1rem'
    },
    whiteIcon: {
        color: 'white'
    }
});

const AppBarCollapse = props => {


    const [anchorEl, setAnchorEl] = useState(null);
    const [ shouldCloseMenu, setShouldCloseMenu ] = useState(false);


    const _closeAppBarMenu = () => {

        setShouldCloseMenu(true);

    }


    const _closeSession = () => {

		props.dispatch(logoutUser());
		_hideMenu();
        _goHome();

	}


    const _displayAccountMenu = (event) => {

		setAnchorEl(event.currentTarget);

	}


    const _goHome = () => {

        if(window.location.href.indexOf('spaceDetails') > -1){

            window.location.href = '/';

        }

		props.history?.push('/');
		props.dispatch(setIframePath('/'));
        props.dispatch(setIframePath('/spaces/index.php'));
		props.dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.WELCOME));

        setShouldCloseMenu(true);

	}


	const _gotoSpacesSearch = () => {

        if(window.location.href.indexOf('spaceDetails') > -1){

            window.location.href = '/';

        }

		props.history?.push('/');
		props.dispatch(setIframePath('/index.php/adxpace-resultados-busqueda/'));
		props.dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.WELCOME));

        setShouldCloseMenu(true);

	}


    const _hideMenu = () => {

        setAnchorEl(null);

    }


    const _loadLogin = () => {

		props.history?.push('/');
		props.dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.LOGIN));

        setShouldCloseMenu(true);

	}


	const _loadPanel = () => {

		const { _userData, _userLoggedIn, dispatch } = props;

		if(!_userData.isAdmin && _userLoggedIn){

            if(window.location.href.indexOf('spaceDetails') > -1){

                window.location.href = '/';

            }

			dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.CONTROL_PANEL_USER));
			dispatch(setMySpacesScreen(SPACES_SCREENS.MENU));

            setShouldCloseMenu(true);

		}

	}




    return (

        <div className={ props.classes.root }>

            <ButtonAppBarCollapse shouldCloseMenu={ shouldCloseMenu } setShouldCloseMenu={ setShouldCloseMenu }>

                <MenuItem onClick={ () => _goHome() }>
                    <ListItemIcon>
                        <HomeIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography className={ props.classes.menuButtonText } variant="inherit">Inicio</Typography>
                </MenuItem>

                <MenuItem onClick={ () => _gotoSpacesSearch() }>
                    <ListItemIcon>
                        <SearchIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography className={ props.classes.menuButtonText } variant="inherit">Buscar espacios</Typography>
                </MenuItem>


                {
                    props._userLoggedIn ?
                    <MenuItem onClick={ () => _loadPanel() }>
                        <ListItemIcon>
                            <AppsIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography className={ props.classes.menuButtonText } variant="inherit">Mis espacios</Typography>
                    </MenuItem>
                    :
                    <MenuItem onClick={ () => _loadLogin() }>
                        <ListItemIcon>
                            <AccountCircleIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography className={ props.classes.menuButtonText } variant="inherit">Iniciar sesi&oacute;n</Typography>
                    </MenuItem>
                }


                {
                    props._userLoggedIn &&
                    <MenuItem onClick={ () => _closeSession() }>
                        <ListItemIcon>
                            <AccountCircleIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography className={ props.classes.menuButtonText } variant="inherit">Cerrar sesi&oacute;n [{ props._userData.name }]</Typography>
                    </MenuItem>
                }

            </ButtonAppBarCollapse>

            <div className={ props.classes.buttonBar } id="appbar-collapse">

                <Button className={ props.classes.menuButton } color="inherit" onClick={ () => _goHome() } >

                    <ListItemIcon className={ props.classes.icon }>
                        <HomeIcon className={ props.classes.whiteIcon } fontSize="small" />
                    </ListItemIcon>

                    <Typography className={ props.classes.title } variant="inherit">Inicio</Typography>

                </Button>


                <Button className={ props.classes.menuButton } color="inherit" onClick={ () => _gotoSpacesSearch() }>

                    <ListItemIcon className={ props.classes.icon }>
                        <SearchIcon className={ props.classes.whiteIcon } fontSize="small" />
                    </ListItemIcon>

                    <Typography className={ props.classes.title } variant="inherit">Buscar espacios</Typography>

                </Button>


                {
                    props._userLoggedIn ?
                    <Button className={ props.classes.menuButton } color="inherit" onClick={ () => _loadPanel() }>

                        <ListItemIcon className={ props.classes.icon }>
                            <AppsIcon className={ props.classes.whiteIcon } fontSize="small" />
                        </ListItemIcon>
                        <Typography className={ props.classes.title } variant="inherit">Mis espacios</Typography>

                    </Button>
                    :
                    <Button className={ props.classes.menuButton } color="inherit" onClick={ () => _loadLogin() }>

                        <ListItemIcon className={ props.classes.icon }>
                            <AppsIcon className={ props.classes.whiteIcon } fontSize="small" />
                        </ListItemIcon>
                        <Typography className={ props.classes.title } variant="inherit">Iniciar sesi&oacute;n</Typography>

                    </Button>

                }


                {

                    props._userLoggedIn &&
                    <>
                        <Button className={ props.classes.userButton } color="inherit" onClick={ _displayAccountMenu }>

                            <ListItemIcon className={ props.classes.icon }>
                                <AccountCircleIcon className={ props.classes.whiteIcon } fontSize="medium" />
                            </ListItemIcon>
                            <Typography className={ props.classes.title } variant="inherit">{ props._userData.name }</Typography>


                        </Button>

                        <Menu
                            id="sesionMenu"
                            anchorEl={ anchorEl }
                            keepMounted
                            open={ Boolean(anchorEl) }
                            onClose={ _hideMenu }
                        >
                            <MenuItem onClick={ _closeSession }>Cerrar Sesión</MenuItem>

                        </Menu>

                    </>

                }



            </div>

        </div>

    )

};


function _mapStateToProps(state){

	const { userData, userLoggedIn } = state['base/auth'];

	return {
		_userData: userData,
		_userLoggedIn: userLoggedIn
	}

}


export default withStyles(styles)(connect(_mapStateToProps)(AppBarCollapse));
