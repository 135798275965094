//  @flow

import { ReducerRegistry } from '../redux';

import { SET_CURRENT_POST, SET_CURRENT_SCREEN, SET_CURRENT_SEARCH_TERM, SET_LAST_SEARCH_TERM, SET_LOADER_HIDDEN } from './actionTypes';
import { CURRENT_DISPLAYING_SCREEN } from './constants';


const STORE_NAME = 'base/app';

const DEFAULT_STATE = {

	currentPost: '',
	currentScreen: CURRENT_DISPLAYING_SCREEN.WELCOME,
	currentSearchTerm: '',
	lastSearchTerm: '',
	isLoaderHidden: true

}


ReducerRegistry.register(
    STORE_NAME,
    (state = DEFAULT_STATE, action) => {

		switch (action.type) {


			case SET_CURRENT_POST:

				return {
					...state,
					currentPost: action.currentPost
				}

        case SET_CURRENT_SCREEN:

            return {
                ...state,
                currentScreen: action.currentScreen
            }


		case SET_CURRENT_SEARCH_TERM:

			return {
				...state,
				currentSearchTerm: action.currentSearchTerm
			}


		case SET_LAST_SEARCH_TERM:
			return {
				...state,
				lastSearchTerm: action.lastSearchTerm
			}


		case SET_LOADER_HIDDEN:
			return {
				...state,
				isLoaderHidden: action.isLoaderHidden
			}

        default:
            return state;

    }

});
