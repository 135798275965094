// @flow
import React, { Component } from 'react';

import { Button, Card, CardContent, CardHeader, Dialog as Modal, Grid, CardActions, Typography  } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DatePicker
  } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Check, Clear, Delete, Send } from '@material-ui/icons';


import moment from "moment";

import ModalImage from "react-modal-image";
import type { Dispatch } from 'redux';


import { getPostMedia, getSpaceCategories, getSpaceDetails } from '../../api/spaces';
import { openDialog } from '../../dialog';
import { Dialog, NoticeDialog, setToastMessage, setToastVisible } from '../../notifications';
import { connect } from '../../redux';

import { removeSpaceFromCampaign, requestQuotation, requestQuotationReservation, updateContractStatus } from '../actions';

import SpaceCalendar from './spaceCalendar';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const styles = theme => ({
	root: {
		backgroundColor: '#f4fafa',
		borderTopLeftRadius: '1.1rem',
		// display: 'flex',
		marginBottom: '1rem',
		marginLeft: '0.5rem',
		marginTop: '0.7rem'
	},
	cardHeader: {
		fontWeight: '800',
		padding: '0.7rem 1rem 0',
		textAlign: 'left',
	},
	categoriesContainer: {
		marginBottom: '1.7rem'
	},
	category: {
		backgroundColor: '#E83022',
		border: 'solid 1px #ccc',
		borderTopRightRadius: '0.5rem',
		borderBottomRightRadius: '0.5rem',
		color: '#fff',
		fontSize: '1.1rem',
		fontWeight: '600',
		padding: '0.7rem 1rem',
		textAlign: 'center',
        [theme.breakpoints.down("xs")]: {
            fontSize: "1rem"
        },
	},
	content: {
		// display: 'flex',
		// flex: '1 0 auto',
		margin: '0.2rem 0.7rem',
		textAlign: 'left'
	},
	cover: {
		backgroundSize: 'contain',
		height: '11rem',
		margin: '0',
		width: '33%',
	},
	datePicker: {
		fontSize: '1.3rem',
		marginRight: '1rem',
		width: '17rem',
		'& .MuiInputLabel-root': {
			fontSize: '1.1rem',
			fontWeight: '500'
		},
		'& .MuiInputBase-root': {
			fontSize: '1.3rem',
			fontWeight: '500'
		}
	},
	details: {
		margin: '0 1rem 1.5rem',
		textAlign: 'left',
		width: '95%'
	},
	divider: {
		borderBottom: 'solid 1px #666',
		marginBottom: '1.7rem',
		paddingBottom: '1.7rem'
	},
	formInfo: {
		fontSize: '1.3rem',
	},
	gallery: {
        float: 'left',
		height: '11rem',
		margin: '0',
		overflowX: 'scroll',
        overflowY: 'hidden',
        padding: 0,
		width: '39%',
		'& > div > div': {
			float: 'left',
			height: '100%',
			marginRight: '5px'
		},
        '& img': {
            width: '200px'
        }
	},
    mt: {
        marginTop: '1.5rem'
    },
    modal: {
        '& .MuiDialog-paperWidthSm': {
            backgroundColor: 'rgba(255, 255, 255, 0.87)',
            borderTopLeftRadius: '1rem',
            borderTopRightRadius: '1rem',
            elevation: 3,
            maxWidth: '900px',
            overflow: 'hidden',
            paddingBottom: '1.5rem',
            paddingLeft: '1rem',
            paddingRight: '1rem'
        }
    },
    notice: {
        fontSize: '1.1rem',
        fontWeight: 600
    },
	rightPanel: {
        float: 'left',
		paddingTop: '1rem',
		textAlign: 'right',
		width: '59%',
        '& strong': {
            [theme.breakpoints.down("xs")]: {
                display: "block"
            },
            [theme.breakpoints.up("sm")]: {
                display: "inline-block"
            },
        }
	},
	spaceFeature: {
		marginTop: '0.7rem',
		textAlign: 'left'
	}
});





function capitalize(str) {

	const lower = str.toLowerCase();
	return str.charAt(0).toUpperCase() + lower.slice(1);

}


function numberFormat(value, decimalPositions, decimalSeparator, thousandsSeparator){

	decimalPositions = isNaN(decimalPositions = Math.abs(decimalPositions)) ? 2 : decimalPositions;
    decimalSeparator = decimalSeparator === undefined ? "." : decimalSeparator;
    thousandsSeparator = thousandsSeparator === undefined ? "," : thousandsSeparator;
    let sign = value < 0 ? "-" : "";
    let i = String(parseInt(value = Math.abs(Number(value) || 0).toFixed(decimalPositions)));
	let k = i.length;
    let j = k > 3 ? k % 3 : 0;

   return sign + (j ? i.substr(0, j) + thousandsSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandsSeparator) + (decimalPositions ? decimalSeparator + Math.abs(value - i).toFixed(decimalPositions).slice(2) : "");

}


const to2 = (num) => num < 10 ? `0${ num }` : `${ num }`;


const dateFns = new DateFnsUtils ();


type Props = {

	_campaignsStatus: Object,
	_userAuth: Object,
	dispatch: Dispatch

}


class SpaceContract extends Component<Props> {

	constructor(props){

		super(props);

		this.state = {
			actionDisabled: false,
			contractId: props.contract.contractId,
			contractStatus: 1,
            displayCalendar: false,
			endDate: null,
			postThumb: '',
			requiresUpdate: false,
			spaceDetails: { },
			startDate: null,
		}

        this._closeModal = this._closeModal.bind(this);
        this._displayCalendar = this._displayCalendar.bind(this);
		this._getDaysToAdd = this._getDaysToAdd.bind(this);
		this._getSpaceDetails = this._getSpaceDetails.bind(this);
		this._handleEndDateChanged = this._handleEndDateChanged.bind(this);
        this._handleMouseOut = this._handleMouseOut.bind(this);
        this._handleMouseOver = this._handleMouseOver.bind(this);
		this._handleStartDateChanged = this._handleStartDateChanged.bind(this);
		this._openInNewTab = this._openInNewTab.bind(this);
		this._renderContractActions = this._renderContractActions.bind(this);
		this._requestQuotation = this._requestQuotation.bind(this);
        this._requestQuotationReservation = this._requestQuotationReservation.bind(this);
		this._requestRemoveFromCampaign = this._requestRemoveFromCampaign.bind(this);
		this._removeSpaceFromCampaign = this._removeSpaceFromCampaign.bind(this);
        this._reserveQuotation = this._reserveQuotation.bind(this);
		this._updateStatus = this._updateStatus.bind(this);
		this._verifyContractDates = this._verifyContractDates.bind(this);

	}


	componentDidMount(){

		this._getSpaceDetails();

	}


	componentDidUpdate(){

		if(this.state.requiresUpdate){

			this.setState({
				requiresUpdate: false
			}, () => {

				this._getSpaceDetails();

			});
		}

	}



	static getDerivedStateFromProps(props, state){

		const newContractId = props.contract.contractId;

		const newContractStatus = props.contract.contractStatus;

		if(state.contractId !== newContractId){

			return {
				contractId: newContractId,
				requiresUpdate: true
			}

		}


		let newState = { };
		let stateUpdateRequired = false;

		if(state.contractStatus !== newContractStatus){

			newState.contractStatus = newContractStatus;

			stateUpdateRequired = true;

		}


		if(stateUpdateRequired){

			return newState;

		}


		return null;

	}



	render(){

		const { _campaignsStatus, _userAuth, classes, contract } = this.props;
		const{ categories, contractStatus, displayCalendar, endDate, spaceDetails, spacePictures, startDate } = this.state;

		let expireDate, reservationExpiresAt;

		if(contract.quotationExpiresAt){

			expireDate = new Date(contract.quotationExpiresAt);

		}

        if(contract.quotationExpiresAt){

            reservationExpiresAt = new Date(contract.reservedQuotationExpiryDate)

        }

		const galleryWidth = spacePictures?.length * 210 || 0;

		return (

			<Card className={ classes.root } onMouseOver={ this._handleMouseOver } onMouseOut={ this._handleMouseOut } >

				<CardHeader
					className={ classes.cardHeader }
					title={ contract.spaceName } />


				<CardContent className={ classes.content }>

					<div className={ classes.gallery }>

						<div style={ { width: galleryWidth + 'px', height: '100%' } }>

							{ this.state.spacePictures?.map((pic, i) =>

									<ModalImage key={ `${ spaceDetails._cth_id_adxpace }_${ i }` } hideDownload={ true } small={ pic.guid } large={ pic.guid } alt={ contract.spaceName } />

							)}

						</div>


					</div>


					<div className={ classes.rightPanel }>

						{
							spaceDetails._cth_id_adxpace &&
							<Typography variant="subtitle1"  align="right" color="textPrimary" gutterBottom >
								C&oacute;digo ADXpace: &nbsp; <strong> { spaceDetails._cth_id_adxpace } </strong>
							</Typography>
						}

						{
							spaceDetails._cth_periodo_contratación &&
							<Typography variant="subtitle1"  align="right" color="textPrimary" gutterBottom >
								Per&iacute;odo de contrataci&oacute;n: &nbsp; <strong> {
									capitalize(spaceDetails._cth_periodo_contratación) } d&iacute;as</strong>
							</Typography>
						}

						{
							spaceDetails._cth_periodo_pago &&
							<Typography variant="subtitle1"  align="right" color="textPrimary" gutterBottom >
								Per&iacute;odo de pago: &nbsp; <strong> {
									capitalize(spaceDetails._cth_periodo_pago) } d&iacute;as</strong>
							</Typography>
						}

						{
							spaceDetails._cth_descuento_volumen !== 'a:0:{}' &&
							<Typography variant="subtitle1"  align="right" color="textSecondary" gutterBottom >
								-- Descuento por volumen --
							</Typography>
						}

						{
							spaceDetails._cth_price &&
							<Typography variant="subtitle1"  align="right" color="textPrimary" gutterBottom >
								Precio de lista: &nbsp; <strong> $ { numberFormat(spaceDetails._cth_price, 0) } </strong>
							</Typography>
						}

					</div>





				</CardContent>

				<Grid className={ classes.details } container>

					<Grid item xs={ 11 }>

						<Typography className={ classes.notice } variant="subtitle1"  align="left" color="textPrimary" gutterBottom >
							* Las im&aacute;genes y la informaci&oacute;n son responsabilidad del propietario del espacio. <br/>&nbsp;<br/>
						</Typography>

						<Grid className={ classes.categoriesContainer } container>

							{
								categories && categories.length > 0 ?

									categories.map((cat, i) =>

										<Grid className={ classes.category } item key={ `category_${i}` } xs={ 5 } sm={ 4 }>
											{ cat.name }
										</Grid>

									)

								: null
							}

						</Grid>

						{ spaceDetails._cth_calle } { spaceDetails._cth_numero }, { spaceDetails._cth_colonia }, { spaceDetails._cth_municipio }, { spaceDetails._cth_zip_code }<br/>&nbsp;<br/>
						{ spaceDetails._cth_observaciones } <br/>&nbsp;<br/>

					</Grid>

					{
						spaceDetails._cth_afluencia_anual &&
						<Grid className={ classes.spaceFeature } item xs={ 5 } md={ 6 }>
							<Typography variant="subtitle1"  align="right" color="textPrimary">
								Afluencia anual: &nbsp; <strong> { spaceDetails._cth_afluencia_anual && capitalize(spaceDetails._cth_afluencia_anual) } </strong><br></br>
							</Typography>
						</Grid>
					}

					{
						spaceDetails._cth_base &&
						<Grid className={ classes.spaceFeature } item xs={ 5 } md={ 4 }>
							<Typography variant="subtitle1"  align="right" color="textPrimary">
								Base: &nbsp; <strong> { spaceDetails._cth_base }m </strong><br></br>
							</Typography>
						</Grid>
					}

					{
						spaceDetails._cth_altura &&
						<Grid className={ classes.spaceFeature } item xs={ 5 } md={ 4 }>
							<Typography variant="subtitle1"  align="right" color="textPrimary">
								Altura: &nbsp; <strong> { spaceDetails._cth_altura }m </strong><br></br>
							</Typography>
						</Grid>
					}

					{
						spaceDetails._cth_vista &&
						<Grid className={ classes.spaceFeature } item xs={ 5 } md={ 4 }>
							<Typography variant="subtitle1"  align="right" color="textPrimary">
								Vista: &nbsp; <strong> { spaceDetails._cth_vista && capitalize(spaceDetails._cth_vista) } </strong><br></br>
							</Typography>
						</Grid>
					}

					{
						spaceDetails._cth_tamano_pantalla &&
						<Grid className={ classes.spaceFeature } item xs={ 5 } md={ 4 }>
							<Typography variant="subtitle1"  align="right" color="textPrimary">
								Tama&ntilde;o de pantalla: &nbsp; <strong> { spaceDetails._cth_tamano_pantalla && spaceDetails._cth_tamano_pantalla }" </strong><br></br>
							</Typography>
						</Grid>
					}



					<Grid className={ classes.divider } item xs={ 12 }>
					</Grid>

					{

						contractStatus > 1 && contract.contractId &&
						<Grid  item xs={ 12 }>
							<Typography className={ classes.formInfo } variant="subtitle1"  align="right" color="textPrimary">
								Id de contrato ADXpace: &nbsp; <strong> { contract.contractId }  </strong> <br></br>
							</Typography>
						</Grid>
					}


					<Grid  item xs={ 12 }>
						<Typography className={ classes.formInfo } variant="subtitle1"  align="right" color="textPrimary">
							Estatus: &nbsp; <strong> { _campaignsStatus[contractStatus] && capitalize(_campaignsStatus[contractStatus]) }  </strong> <br></br>
						</Typography>
					</Grid>


					<Grid className={ classes.spaceFeature } item xs={ 12 }>
						<Typography variant="subtitle1"  align="left" color="textPrimary">
							<strong> Selecciona las fechas de inicio y fin de contrataci&oacute;n de tu adxpace: </strong><br></br>
						</Typography>
					</Grid>


					<Grid  container>
						<MuiPickersUtilsProvider libInstance={ moment } utils={ MomentUtils }>
                            <Grid item md={ 4 }>
    							<DatePicker
    								className={ classes.datePicker }
    								disabled={ contractStatus > 1 }
    								format="YYYY-MM-DD"
    								label="Inicio de Contratación"
    								margin="normal"
    								onChange={ this._handleStartDateChanged }
    								value={ startDate ? startDate : null }
    							/>
                            </Grid>

                            <Grid item md={ 4 }>
    							<DatePicker
    								className={ classes.datePicker }
    								disabled={ contractStatus > 1 }
    								format="YYYY-MM-DD"
    								label="Fin de Contratación"
    								margin="normal"
    								onChange={ this._handleEndDateChanged }
    								value={ endDate ? endDate : null }
    							/>
                            </Grid>
						</MuiPickersUtilsProvider>

                        {
                            contractStatus < 2 &&
                            <Grid className={ classes.mt } item md={ 4 }>
                                <Button color="primary" variant="outlined" onClick={ (e) => this._displayCalendar() }>Calendario del espacio</Button>
                            </Grid>
                        }

					</Grid>

				</Grid>

				{

					contractStatus > 3 && expireDate &&
					<Grid item xs={ 8 }>
						<Typography className={ classes.formInfo } variant="subtitle1"  align="right" color="textPrimary">
							Precio cotizado: &nbsp; <strong> $ { numberFormat(parseFloat(contract.quotationPrice)) }  </strong> <br/>
							Cotización válida hasta: <strong>{ to2(expireDate.getDate()) }-{ to2(expireDate.getMonth() + 1) }-{ expireDate.getFullYear() }</strong> <br/> &nbsp; <br/>
						</Typography>
					</Grid>

				}


				{

					contractStatus > 3 && contract.quotationDoc &&
					<Grid item xs={ 12 }>
						<Button
							variant="contained"
							onClick={ (e) => this._openInNewTab(contract.quotationDoc) }
						> Descargar cotización </Button> <br/> &nbsp; <br/>
					</Grid>

				}


                {

                    contract.quotationReserved && new Date(contract.reservedQuotationExpiryDate) > new Date() ?
                    <Typography className={ classes.formInfo } variant="subtitle2" align="center" color="textPrimary">
                        Espacio reservado. Reservaci&oacute;n vence el <strong>{ to2(reservationExpiresAt.getDate()) }-{ to2(reservationExpiresAt.getMonth() + 1) }-{ reservationExpiresAt.getFullYear() }</strong><br/>&nbsp;<br/>
                    </Typography>
                    : null
                }


				{
					contractStatus > 6 && contract.contractDoc &&
					<Grid item xs={ 12 }>
						<Button
							variant="contained"
							onClick={ (e) => this._openInNewTab(contract.contractDoc) }
						> Descargar contrato </Button> <br/> &nbsp; <br/>
					</Grid>
				}


				{
					contractStatus > 6 && contract.contractInvoice &&
					<Grid item xs={ 12 }>
						<Button
							variant="contained"
							onClick={ (e) => this._openInNewTab(contract.contractInvoice) }
						> Descargar factura </Button>
					</Grid>
				}

				<br/> &nbsp; <br/>

				{ this._renderContractActions() }


                <Modal className={ classes.modal } onClose={ this._closeModal } open={ displayCalendar }>

                    <SpaceCalendar spaceId={ contract.spaceId } spaceName={ `${ spaceDetails._cth_calle } ${ spaceDetails._cth_numero }` } token={ _userAuth.token }/>

                </Modal>

			</Card>

		);

	}


    _closeModal(){

        this.setState({ displayCalendar: false });

    }


    _displayCalendar(){

        this.setState({ displayCalendar: true });

    }



	_getDaysToAdd(){

		let daysToAdd;

		switch(this.state.spaceDetails._cth_periodo?.toLowerCase()){

			case 'mensual':
				daysToAdd = 30;
			break;

			case 'quincenal':
				daysToAdd = 15;
			break;

			default:
				daysToAdd = 15;

		}

		return daysToAdd;

	}


	_getSpaceDetails(){

		const { contract } = this.props;

		getPostMedia(contract.spaceId)
		.then(res => {

			if(res.data.pictures.length){

				this.setState({
					postThumb: res.data.pictures[0].guid,
					spacePictures: res.data.pictures
				});

			}

		}).catch(e => {
			console.error(e);
		});



		getSpaceCategories(this.props._userAuth.token, contract.spaceId)
		.then(res => {
			this.setState({
				categories: [...res.data.categories]
			});
		}).catch(e => {
			console.error(e);
		});



		getSpaceDetails(this.props._userAuth.token, contract.spaceId)
		.then(res => {

			if(contract.contractStartDate && contract.contractEndDate){

				this.setState({

					endDate: new Date(contract.contractEndDate),
					startDate: new Date(contract.contractStartDate)
				});

			}

			const { spaceDetails } = res.data;


			const marker = this.props.setMarker(`${ spaceDetails._cth_calle.trim() } ${ spaceDetails._cth_numero?.trim() }`, spaceDetails._cth_latitude, spaceDetails._cth_longitude);


			this.setState({
				contractStatus: contract.contractStatus,
                marker: `${ spaceDetails._cth_calle.trim() } ${ spaceDetails._cth_numero?.trim() }`,
				spaceDetails: res.data.spaceDetails
			});


		}).catch(e => {
			console.error(e);
		});

	}


	_handleEndDateChanged(val){

		val = dateFns.format(new Date(val), `yyyy-MM-dd'T'hh:mm`);

		const endDate = new Date(val);

		const { startDate } = this.state;

		if(startDate && endDate <= startDate){

			const { dispatch } = this.props;

			dispatch(setToastMessage('No es posible terminar un contrato antes de iniciarlo. Verifique que la fecha de fin sea posterior a la fecha de inicio.'));
			return dispatch(setToastVisible(true));

		}



		this.setState({
			endDate: endDate
		});

	}


    _handleMouseOut(){

        if(this.state.marker){

            this.props.stopMarkerAnimation(this.state.marker);

        }

    }


    _handleMouseOver(){

        if(this.state.marker){

            this.props.animateMarker(this.state.marker);

        }

    }



	_handleStartDateChanged(val){

		val = dateFns.format(new Date(val), `yyyy-MM-dd'T'hh:mm`);

		const { dispatch } = this.props;

		const startDate = new Date(val);

		const refStartDate = new Date();
			refStartDate.setDate(refStartDate.getDate() + 4);


		if(startDate < refStartDate){

			dispatch(setToastMessage(`La fecha mínima para solicitar una cotización es ${ to2(refStartDate.getDate() + 1) }/${ to2(refStartDate.getMonth() + 1) }/${ refStartDate.getFullYear() }`));
			return dispatch(setToastVisible(true));

		}


		const { endDate } = this.state;

		if(endDate && endDate <= startDate){



			dispatch(setToastMessage('No es posible terminar un contrato antes de iniciarlo. Verifique que la fecha de fin sea posterior a la fecha de inicio.'));
			return dispatch(setToastVisible(true));

		}


		this.setState({
			startDate: startDate,
		});

	}


	_openInNewTab(url){

		const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
		if (newWindow) newWindow.opener = null;

	}



	_requestRemoveFromCampaign(){

		this.props.dispatch(openDialog(Dialog, {

			modalHeader: `Confirmar eliminación del espacio en la campaña`,
			modalBody: `Está solicitando eliminar el espacio ${ this.props.contract.spaceName } de esta campaña. Haga clic en el botón Confirmar para continuar con la operación.`,
			onSubmit: this._removeSpaceFromCampaign,
			submitButtonText: `Confirmar`

		}));

	}



	_removeSpaceFromCampaign(){

		const { _userAuth, contract, dispatch } = this.props;

		const { spaceDetails } = this.state;


		this.props.deleteMarker(`${ spaceDetails._cth_calle.trim() } ${ spaceDetails._cth_numero?.trim() }`);

		return dispatch(removeSpaceFromCampaign({
			contractId: contract.contractId,
			token: _userAuth.token
		}));

	}



	_renderContractActions(){

        const { contract } = this.props;
		const { contractStatus } = this.state;

		switch (contractStatus) {
			case 1:

				return (
					<CardActions >

						<Button size="small" variant="outlined" color="primary" onClick={ (e) => this._updateStatus(2) } disabled={ this.state.actionDisabled }>
							<Send />
							Solicitar Cotización
						</Button>

						<Button size="small" variant="outlined" color="primary" onClick={ (e) => this._requestRemoveFromCampaign() }>
							<Delete />
							Eliminar de mi campa&ntilde;a
						</Button>

					</CardActions>
				);

			case 2:
			case 3:
				return (
					<CardActions >
						<Button size="small" variant="outlined" color="primary" onClick={ (e) => this._updateStatus(10) }>
							<Delete />
							Cancelar tr&aacute;mite
						</Button>
					</CardActions>
				)


			case 4:
				return (
					<CardActions >

                        {
                            !contract.quotationReserved &&
                            <Button size="small" variant="outlined" color="primary" onClick={ (e) => this._requestQuotationReservation() } disabled={ this.state.actionDisabled }>
                                Reservar espacio
                            </Button>
                        }



						<Button size="small" variant="outlined" color="primary" onClick={ (e) => this._updateStatus(5) } disabled={ this.state.actionDisabled }>
							<Check /> &nbsp;
							Aceptar cotizaci&oacute;n
						</Button>


						<Button size="small" variant="outlined" color="primary" onClick={ (e) => this._updateStatus(9) } disabled={ this.state.actionDisabled }>
							<Clear /> &nbsp;
							Rechazar cotizaci&oacute;n
						</Button>


					</CardActions>
				);



			default:
				return (
					<CardActions >
						<Button size="small" variant="outlined" color="primary">
							<Delete />
							Cancelar tr&aacute;mite
						</Button>
					</CardActions>
				)
		}

	}



	_requestQuotation(){

		const { _userAuth, contract, dispatch } = this.props;

		return dispatch(requestQuotation({
			contractId: contract.contractId,
			endDate: this.state.endDate,
			startDate: this.state.startDate,
			token: _userAuth.token
		}));

	}



    _requestQuotationReservation(){

        const { contract } = this.props;

        const endDate = new Date(contract.contractEndDate);
        const startDate = new Date(contract.contractStartDate);

        this.props.dispatch(openDialog(Dialog, {

			modalHeader: `Confirmar reservaci&oacute;n de espacio `,
			modalBody: `Está solicitando reservar el espacio ${ contract.spaceName }. Este espacio quedará reservado por las próximas 72 horas para las fechas del ${ to2(startDate.getDate()) }-${ to2(startDate.getMonth() + 1) }-${ startDate.getFullYear() } al ${ to2(endDate.getDate()) }-${ to2(endDate.getMonth() + 1) }-${ endDate.getFullYear() }       Ésta operación sólo puede realizarse una vez por espacio. ¿Está seguro que lo quiere reservar?`,
			onSubmit: this._reserveQuotation,
			submitButtonText: `Confirmar`

		}));

    }


    _reserveQuotation(){

        const { _userAuth, contract, dispatch } = this.props;

        return dispatch(requestQuotationReservation({
            contractId: contract.contractId,
            spaceId: contract.spaceId,
            token: _userAuth.token
        }));

    }



	_updateStatus(status){

		if(status === 2){

			this._verifyContractDates();

		} else {

			const { _userAuth, contract, dispatch } = this.props;

			dispatch(updateContractStatus({
				contractId: contract.contractId,
				contractStatus: status,
				token: _userAuth.token
			}));

		}


		if(status === 9 || status === 10){

			this.setState({
				endDate: null,
				startDate: null
			});

		}


		this.setState({
			actionDisabled: true
		});

		setTimeout(() => {

			this.setState({
				actionDisabled: false,
				// contractStatus: 2
			});

		}, 2000);

	}



	_verifyContractDates(){

		const { endDate, startDate } = this.state;

		if(!endDate || !startDate){

			return this.props.dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Fechas requeridas.',
				modalMessage: `Se debe especificar fecha de Inicio y fecha de Fin de contrato.`
			}));

		}

		const sStartDate = new Date(startDate);
		const sEndDate = new Date(endDate);

		this.props.dispatch(openDialog(Dialog, {

			modalHeader: `Confirmar fecha para solicitud de contratación`,
			modalBody: `Está solicitando cotización para contratar el anuncio a partir del ${ to2(sStartDate.getDate()) }/${ to2(sStartDate.getMonth() + 1) }/${ sStartDate.getFullYear() } hasta el ${ to2(sEndDate.getDate()) }/${ to2(sEndDate.getMonth() + 1) }/${ sEndDate.getFullYear() }.`,
			onSubmit: this._requestQuotation,
			submitButtonText: `Confirmar`

		}));

	}


}


function _mapStateToProps(state){

	const { userAuth } = state['base/auth'];
	const { campaignsStatus } = state['base/campaigns'];

	return {
		_campaignsStatus: campaignsStatus,
		_userAuth: userAuth
	}

}


export default connect(_mapStateToProps)(withStyles(styles)(SpaceContract));
