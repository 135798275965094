// @flow

const levels = {
    "trace": 0,
    "debug": 1,
    "info": 2,
    "log": 3,
    "warn": 4,
    "error": 5
};


/**
 * The global configuration options.
 */
var globalOptions = {};


var consoleTransport = console;

var globalTransports = [ consoleTransport ];

/**
 * Parses Error's object stack trace and extracts information about the last
 * caller before the log method was called.
 * @returns JS object with info about the caller - method name, file location,
 * line and column.
 */
function getCallerInfo() {
    var callerInfo = {
        methodName: "",
        fileLocation: "",
        line: null,
        column: null
    };
    //gets the part of the stack without the logger wrappers
    var error = new Error();
    var stack = error.stack? error.stack.split("\n") : [];
    if(!stack || stack.length < 1) {
        return callerInfo;
    }
    var m = null;
    if(stack[3]) {
        m = stack[3].match(/\s*at\s*(.+?)\s*\((\S*)\s*:(\d*)\s*:(\d*)\)/);
    }
    if(!m || m.length <= 4) {
        //Firefox && Safari
        if(stack[2].indexOf("log@") === 0){
            //Safari
            callerInfo.methodName = stack[3].substr(0, stack[3].indexOf("@"));
        } else {
            //Firefox
            callerInfo.methodName = stack[2].substr(0, stack[2].indexOf("@"));
        }
        return callerInfo;
    }

    callerInfo.methodName = m[1];
    callerInfo.fileLocation = m[2];
    callerInfo.line = m[3];
    callerInfo.column = m[4];
    return callerInfo;
}

/**
 * Logs messages using the transports and level from the logger.
 * @param logger a logger instance.
 * @param level the log level of the message. See the levels variable.
 * @param arguments array with arguments that will be logged.
 */
function log() {
    var logger = arguments[0], level = arguments[1],
        args = Array.prototype.slice.call(arguments, 2);
    if(levels[level] < logger.level) {
        return;
    }

    var callerInfo
        = !(logger.options.disableCallerInfo || globalOptions.disableCallerInfo) &&
            getCallerInfo();
    var transports = globalTransports.concat(logger.transports);
    for(var i = 0; i < transports.length; i++) {
        var t = transports[i];
        var l = t[level];
        if(l && typeof(l) === "function") {
            var logPrefixes = [];

            logPrefixes.push(new Date().toISOString());

            if (logger.id) {
                logPrefixes.push("[" + logger.id + "]");
            }

            if (callerInfo && callerInfo.methodName.length > 1) {
                logPrefixes.push("<" + callerInfo.methodName + ">: ");
            }

            var fullLogParts = logPrefixes.concat(args);

            l.bind(t).apply(t, fullLogParts);
        }
    }
}

class Logger {

    levels: {
        TRACE: "trace",
        DEBUG: "debug",
        INFO: "info",
        LOG: "log",
        WARN: "warn",
        ERROR: "error"
    };



    constructor(level, id, transports, options){

        this.id = id;
        this.options = options || {};
        this.transports = transports;
        if(!this.transports) {
            this.transports = [];
        }
        this.level = levels[level];
        var methods = Object.keys(levels);
        for(var i = 0; i < methods.length; i++){
            this[methods[i]] =
                log.bind(null, this, methods[i]);
        }

    }


    /**
     * Sets the log level for the logger.
     * @param level the new log level.
     */
    setLevel = function (level) {
        this.level = levels[level];
    };


    addGlobalTransport = function(transport) {
        if (globalTransports.indexOf(transport) === -1) {
            globalTransports.push(transport);
        }
    };


    removeGlobalTransport = function(transport) {
        var transportIdx = globalTransports.indexOf(transport);
        if (transportIdx !== -1) {
            globalTransports.splice(transportIdx, 1);
        }
    };


    setGlobalOptions = function(options) {
        globalOptions = options || {};
    };

}

const logger = new Logger();
export default logger;
