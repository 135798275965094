// @flow
import React, { Component } from 'react';

import { Button, Container, Divider, List, ListItem, ListItemText } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

import type { Dispatch } from 'redux';

import { getDetailedCampaignsList, setDisplayAddToCampaign, NewCampaignForm } from '../../campaigns';
import { openDialog } from '../../dialog';
import { Dialog } from '../../notifications';
import { connect } from '../../redux';

import { addToCampaign, setSelectedFavorite, setSelectedSpaceName } from '../actions';


type Props = {
	_selectedfavorite: Object,
	_selectedSpaceName: String,
	_userAuth: Object,
	dispatch: Dispatch

}

const styles = theme => ({

	root: {
		backgroundColor: '#fdfdfd',
		display: 'flex',
		height: '11.7rem',
		marginTop: '0.3rem',
		'&:hover':{
			backgroundColor: '#f7f7f7',
		},
		paddingLeft: 0,
		paddingRight: 0
	},
	buttonsContainer:{
		margin: '0.7rem '
	},
	content: {
		flex: '1 0 auto',
		margin: '1rem',
		textAlign: 'left'
	},
	campaignsList: {
		maxHeight: '66%',
		overflowY: 'scroll',
		width: '100%',
	},
	contentContainer:{
		backgroundColor: '#ffffff',
		borderColor: 'rgba(220, 220, 220, 0.5)',
		borderStyle: 'solid',
		borderWidth: '1px',
		boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 1px 3px 4px 2px rgb(0 0 0 / 14%), 1px 4px 8px 4px rgb(0 0 0 / 12%)',
		height: '73%',
		left: '50%',
		maxHeight: '77%',
		overflowY: 'hidden',
		paddingBottom: '1rem',
		paddingLeft: 0,
		paddingRight: 0,
		position: 'absolute',
		top: '50%',
		transform: 'translate(-50%, -50%)'
	},
	cover: {
		backgroundSize: 'contain',
		margin: '0.5rem',
		width: '33%',
	},
	dangerButton: {
		background: red[900],
		color: 'white',
		marginRight: '1rem'
	},
	disabled: {
		color: '#aaa',
	},
	mainContainer: {
		backgroundColor: 'rgba(250, 250, 250, 0.75)',
		border: 'solid 1px #333',
		height: '100%',
		left: 0,
		margin: 0,
		position: 'fixed',
		top: 0,
		width: '100%',
		zIndex: 1400
	},

	successBtn: {
		background: green[800],
		color: 'white',
		'&:hover': {
			background: green[900],
		}
	},

});



class AddToCampaignDialog extends Component<Props> {

	constructor(props){

		super(props);

		this._addToCampaign = this._addToCampaign.bind(this);
		this._displayExistingInCampaignWarning = this._displayExistingInCampaignWarning.bind(this);
		this._hideMe = this._hideMe.bind(this);

	}


	componentDidMount(){

		const { _userAuth, dispatch } = this.props;

		dispatch(getDetailedCampaignsList(_userAuth.token));

	}


	render(){

		const { _detailedCampaigns, classes, _selectedfavorite, _selectedSpaceName } = this.props;

		let spaceInAnyCampaign = false;

		for(let i = 0; i < _detailedCampaigns.length; i++){

			let searchResult, campaign = _detailedCampaigns[i];

			if(_selectedfavorite){
				searchResult = campaign.spaces.filter(space => space.spaceId === _selectedfavorite.postId);
			} else {
				searchResult = campaign.spaces.filter(space => space.spaceLink === _selectedSpaceName);
			}

			if(searchResult && searchResult.length > 0){
				spaceInAnyCampaign = true;
				break;
			}

		}



		return (

			<div className={ classes.mainContainer }>

				<Container className={ classes.contentContainer } maxWidth="sm">

					<h4>Agregar a campa&ntilde;a</h4>

					<div className={ classes.campaignsList }>

						{
							_detailedCampaigns.length ?

							<>


								<List component="nav" aria-label="campañas" style={{ marginBottom: '1.3rem', marginLeft: 0, marginRight: 0 }}>

									{
										_detailedCampaigns.map((campaign, i) => {

											let searchResult, alreadyInCampaign;

											if(_selectedfavorite){
												searchResult = campaign.spaces.filter(space => space.spaceId === _selectedfavorite.postId);
											} else {
												searchResult = campaign.spaces.filter(space => space.spaceLink === _selectedSpaceName);
											}

											alreadyInCampaign = searchResult?.length > 0;

											if(alreadyInCampaign){
												return (
													<div key={`campaign_${ i }`}>
														<ListItem>
															<ListItemText className={ classes.disabled } primary={ campaign.campaignName } />
														</ListItem>
														{
															i < _detailedCampaigns.length -1 && <Divider />
														}
													</div>
												)
											}

											return (

												<div key={`campaign_${ i }`}>
													<ListItem button onClick={ e => spaceInAnyCampaign ? this._displayExistingInCampaignWarning(campaign.campaignId) : this._addToCampaign(campaign.campaignId) }>
														<ListItemText primary={ campaign.campaignName } />
													</ListItem>
													{
														i < _detailedCampaigns.length -1 && <Divider />
													}
												</div>

											)
										})
									}

								</List>
							</>

							:

							<h3>A&uacute;n no se han creado campa&ntilde;as</h3>
						}

					</div>

					<div className={ classes.buttonsContainer }>

						<NewCampaignForm />

						<Button color="primary" onClick={ this._hideMe } variant="contained">Cerrar</Button>

					</div>

				</Container>

			</div>

		);

	}


	_addToCampaign(campaignId){

		const { _selectedfavorite, _selectedSpaceName, _userAuth, dispatch } = this.props;

		if(_selectedfavorite){
			dispatch(addToCampaign({
				adId: _selectedfavorite.postId,
				campaignId: campaignId,
				token: _userAuth.token
			}));
		} else {
			dispatch(addToCampaign({
				adTitle: _selectedSpaceName,
				campaignId: campaignId,
				token: _userAuth.token
			}));
		}

		this._hideMe();

		return true;

	}


	_displayExistingInCampaignWarning(campaignId){

		this.props.dispatch(openDialog(Dialog, {

			modalHeader: `El anuncio ya está agregado en otra campaña`,
			modalBody: `Está seguro que desea agregar el espacio a otra campaña?`,
			onSubmit: () => this._addToCampaign(campaignId),
			submitButtonText: `Confirmar`

		}));

	}


	_hideMe(){

		this.props.dispatch(setDisplayAddToCampaign(false));
		this.props.dispatch(setSelectedFavorite(undefined));
		this.props.dispatch(setSelectedSpaceName(undefined));

	}

}


function _mapStateToProps(state){

	const { detailedCampaigns } = state['base/campaigns'];
	const { userAuth } = state['base/auth'];
	const { selectedFavorite, selectedSpaceName } = state['base/favorites'];

	return {
		_detailedCampaigns: detailedCampaigns,
		_selectedfavorite: selectedFavorite,
		_selectedSpaceName: selectedSpaceName,
		_userAuth: userAuth
	}

}


export default connect(_mapStateToProps)(withStyles(styles)(AddToCampaignDialog));
