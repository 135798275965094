// @flow

import React, { Component } from 'react';

import { Alert } from '../../dialog';
import { connect } from '../../redux';


class Dialog extends Component {

    render(){

		const { contentKey, onSubmit, submitButtonText } = this.props;

        return (
            <Alert
                modalHeader={ this.props.modalHeader }
                modalBody={ this.props.modalBody }
					  cancelButtonText={ 'Cancelar' }
                contentKey={ contentKey || 'adxpace.dialog'}
						onSubmit={ onSubmit }
					  submitButtonText={ submitButtonText || 'OK' }
            />
        );

    }

}

export default connect()(Dialog);
