// @flow
import React, { Component } from 'react';

import { Button, Container, IconButton, InputAdornment ,TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import type { Dispatch } from 'redux';

import { CURRENT_DISPLAYING_SCREEN } from '../../app/constants';
import { setCurrentScreen } from '../../app/actions';
import { submitNewPassword } from '../../auth/actions';
import { openDialog } from '../../dialog';
import { NoticeDialog } from '../../notifications';
import { connect } from '../../redux';

import billboard_img from '../images/billboard.png';


type Props = {
	dispatch: Dispatch
}


const styles = theme => ({

	container: {
		left: '50%',
		position: 'absolute',
		top: '50%',
		transform: 'translate(-50%, -50%)'
	},

	grow: {
		flexGrow: 1
	},

	headerRight: {
		display: 'flex'
	},

	textField: {
		marginBottom: '1.5rem'
	},

	billboard_img: {
		width: '100px'
	}

});



class PasswordReset extends Component<Props> {


	constructor(props){

		super(props);

		this.state = {
			username: '',
			isPasswordBeingDisplayed: false
		}

		this._inputChanged = this._inputChanged.bind(this);
		this._loadLoginForm = this._loadLoginForm.bind(this);
		this._requestPasswordReset = this._requestPasswordReset.bind(this);
		this._togglePasswordVisibility = this._togglePasswordVisibility.bind(this);

	}


	componentDidMount(){

		const { token } = this.props.match.params;

		this.setState({
			token: token
		});

	}


	render(){

		const { classes } = this.props;

		return (

			<Container
				maxWidth='xs'
				className={ classes.container }
			>
				<img className={ classes.billboard_img } src={ billboard_img } alt='' />

				<br></br>
				<br></br>

				<h3>Establece una nueva contrase&ntilde;a (f&aacute;cil de recordar para t&iacute;.):</h3>

				<form noValidate autoComplete="off">

					<TextField
						className={ classes.textField }

						fullWidth
						id="password"
						InputProps={{
							endAdornment:
								<InputAdornment position="end">
									<IconButton
										aria-label="Visibilidad de la contraseña"
										onClick={ this._togglePasswordVisibility }
										>
										{ this.state.isPasswordBeingDisplayed ? <VisibilityOff /> : <Visibility /> }
									</IconButton>
								</InputAdornment>

						}}
						label="Contrasena"
						onChange={ this._inputChanged }
						value={ this.state.password }
						type={ this.state.isPasswordBeingDisplayed ? "text" : "password" }
						variant="outlined"
					/>

					<Button variant="contained" color="secondary" className={ classes.actionBtn } onClick={ this._requestPasswordReset }>Enviar</Button>


				</form>
				<br></br>
				<br></br>
				<hr></hr>
				<p><Button color="secondary" onClick={ this._loadLoginForm }>REGRESAR A INICIO DE SESI&Oacute;N</Button></p>
				<hr></hr>
				<p>Al continuar, aceptas las <Button color="secondary">Condiciones de uso</Button> y el <Button color="secondary">Aviso de Privacidad</Button> de ADXpace.</p>


			</Container>

		);

	}


	_inputChanged(event){

		const { id, value } = event.target;

		this.setState({
			[id]: value
		});

	}


	_loadLoginForm(){

		this.props.history.push('/');
		this.props.dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.LOGIN));

	}


	_requestPasswordReset(){

		if(!this.state.password){

			return this.props.dispatch(openDialog(NoticeDialog, {
				modalHeader: `Ingrese un password`,
				modalMessage: `No puede establecerse un password en blanco.`
			}));

		}

		const { password, token } = this.state;

		return this.props.dispatch(submitNewPassword({ password: password, token: token}));

	}


	_togglePasswordVisibility(){

		const passwordVisibility = !this.state.isPasswordBeingDisplayed;

		this.setState({
			isPasswordBeingDisplayed: passwordVisibility
		});

	}


}



export default withStyles(styles)(connect()(PasswordReset));
