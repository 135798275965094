//	@flow

import { ADD_TO_CAMPAIGN, ADD_TO_CAMPAIGN_FROM_CAMPAIGN, ADD_TO_FAVORITES, GET_FAVORITES, REMOVE_FROM_FAVORITES, SET_FAVORITES, SET_SELECTED_FAVORITE, SET_SELECTED_SPACE_NAME } from './actionTypes';


export function addToCampaign(params){

	return {
		type: ADD_TO_CAMPAIGN,
		params
	}

}


export function addToCampaignFromCampaign(params){

	return {
		type: ADD_TO_CAMPAIGN_FROM_CAMPAIGN,
		params
	}

}


export function addToFavorites(params){

	return {
		type: ADD_TO_FAVORITES,
		params
	}

}



export function getFavorites(token){

	return {
		type: GET_FAVORITES,
		token
	}

}


export function removeFromFavorites(params){

	return {
		type: REMOVE_FROM_FAVORITES,
		params
	}

}


export function setFavorites(favorites){

	return {
		type: SET_FAVORITES,
		favorites
	}

}



export function setSelectedFavorite(selectedFavorite){

	return {
		type: SET_SELECTED_FAVORITE,
		selectedFavorite
	}

}


export function setSelectedSpaceName(selectedSpaceName){

	return {
		type: SET_SELECTED_SPACE_NAME,
		selectedSpaceName
	}

}
