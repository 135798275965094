// @flow

import { LOGIN, REGISTER_USER, REQUEST_PASSWORD_RESET, SET_USER_AUTH, SET_USER_DATA, SET_USER_LOGGED_IN, SUBMIT_NEW_PASSWORD, USER_LOGOUT } from './actionTypes';


export function login(user: object){

	return {
		type: LOGIN,
		user
	}

}


export function logoutUser(){

	return {
		type: USER_LOGOUT
	}

}


export function registerUser(user: object){

	return {
		type: REGISTER_USER,
		user
	}

}


export function requestPasswordReset(username: String){

	return {
		type: REQUEST_PASSWORD_RESET,
		username
	}

}


export function setUserAuth(userAuth: object){

	return {
		type: SET_USER_AUTH,
		userAuth
	}

}



export function setUserData(userData: object){

    return {
        type: SET_USER_DATA,
        userData
    }

}


export function setUserLoggedIn(userLoggedIn: boolean){

    return {
        type: SET_USER_LOGGED_IN,
        userLoggedIn
    }

}



export function submitNewPassword(params: Object){

	return {
		type: SUBMIT_NEW_PASSWORD,
		params
	}

}
