import React, { Component } from 'react';
import { Container } from '@material-ui/core';

import type { Dispatch } from 'redux';

import { AddToCampaignDialog } from '../../favorites';
import { connect } from '../../redux';

import { settings } from '../../api/settings';

import Loader from './Loader';

const SERVER_URL = settings.endpoint;



type Props = {
	_displayAddToCampaign: Boolean,
	_iframePath: String,
	_isLoaderHidden: Boolean,
	dispatch: Dispatch
}

class Home extends Component<Props> {

	constructor(props){

		super(props);

		this.state = {
			innerWidth: 1024,
			height: 768,
			currentIframePath: ''
		}

		this._onResize = this._onResize.bind(this);

	}


	componentDidMount(){

		this._onResize();

		window.addEventListener('resize', this._onResize);

	}


	componentWillUnmount(){

		window.removeEventListener('resize', this._onResize);

	}


	render(){

		// const { innerWidth, innerHeight } = window;
		//
		// const height = innerHeight - window.convertRemToPixels(3.5) - 50;

		const { _iframePath } = this.props;
		const { innerWidth, height } = this.state;

		const path = _iframePath.indexOf('spaces') > -1 ? _iframePath : `/spaces${ _iframePath }`;

		return (

			<Container disableGutters maxWidth={ false } style={{ height: height }}>

				<iframe src={ `${ SERVER_URL.replace('/api', '') }${ path }` } width={ `${ innerWidth }` } height={ height }  title="spaces" id="mainContentsFrame"></iframe>

				{
					this.props._displayAddToCampaign && <AddToCampaignDialog />
				}

				{ this.props._isLoaderHidden ? null : <Loader /> }

			</Container>
		)

	}


	_onResize(){

		const { innerWidth, innerHeight } = window;

		const height = innerHeight - window.convertRemToPixels(3.5) - 40;

		this.setState({
			innerWidth: innerWidth,
			height: height
		});

	}

}


function _mapStateToProps(state){

	const { displayAddToCampaign } = state['base/campaigns'];
	const { iframePath } = state['base/home'];
	const { isLoaderHidden } = state['base/app'];

	return {
		_displayAddToCampaign: displayAddToCampaign,
		_iframePath: iframePath,
		_isLoaderHidden: isLoaderHidden
	}

}


export default connect(_mapStateToProps)(Home);
