//	@flow


import { add, addToCampaign, getFavorites, removeFromFavorites } from '../api/favorites';
import { MiddlewareRegistry } from '../redux';

import { openDialog } from '../dialog';
import { getDetailedCampaignsList, setDisplayAddToCampaign } from '../campaigns';
import { setMySpacesScreen } from '../mySpaces/actions';
import { SPACES_SCREENS } from '../mySpaces/constants';
import { NoticeDialog, setToastMessage, setToastVisible } from '../notifications';

import { setFavorites } from './actions';
import { ADD_TO_CAMPAIGN, ADD_TO_CAMPAIGN_FROM_CAMPAIGN, ADD_TO_FAVORITES, GET_FAVORITES, REMOVE_FROM_FAVORITES } from './actionTypes';



MiddlewareRegistry.register(store => next => action => {

	switch (action.type) {

		case ADD_TO_CAMPAIGN:
			return _addToCampaign(store, next, action);


		case ADD_TO_CAMPAIGN_FROM_CAMPAIGN:
			return _addToCampaignFromCampaign(store, next, action);

		case ADD_TO_FAVORITES:
			return _addToFavorites(store, next, action);

		case GET_FAVORITES:
			return _getFavorites(store, next, action);


		case REMOVE_FROM_FAVORITES:
			return _removeFromFavorites(store, next, action);

		default:
			return(next(action));

	}

});



async function _addToCampaign(store, next, action){

	const { dispatch } = store;

	const { adId, adTitle, campaignId, token } = action.params;

	try {

		let addSpaceToCampaignResponse = await addToCampaign(adId, campaignId, token, adTitle).catch(e=> {

			console.error(e);

			dispatch(setToastMessage('Error agregando espacio a campaña: No pudimos comunicarnos con el servidor. Verifique que hay una conexion activa a Internet.'));
			return dispatch(setToastVisible(true));

		});

		let { err } = addSpaceToCampaignResponse.data;

		if(err){

			console.log(addSpaceToCampaignResponse.data);

			dispatch(setToastMessage(`Error agregando espacio a campaña: ${ err }`));
			return dispatch(setToastVisible(true));

		}

		dispatch(setToastMessage('Espacio agregado a campaña'));
		dispatch(setToastVisible(true));

		return next(action);


	} catch(e) {

		console.error(e);

		dispatch(setToastMessage(`Error agregando a favoritos: ${ e.message }.`));
		return dispatch(setToastVisible(true));

	}

}


async function _addToCampaignFromCampaign(store, next, action){

	const { dispatch, getState } = store;

	dispatch(setDisplayAddToCampaign(false));

	const gState = getState();

	const { currentEditingCampaign } = gState['base/campaigns'];

	const { adId, adTitle, token } = action.params;


	try {

		let addSpaceToCampaignResponse = await addToCampaign(adId, currentEditingCampaign.campaignId, token, adTitle).catch(e=> {

			console.error(e);

			dispatch(setToastMessage('Error agregando espacio a campaña: No pudimos comunicarnos con el servidor. Verifique que hay una conexion activa a Internet.'));
			return dispatch(setToastVisible(true));

		});

		let { err } = addSpaceToCampaignResponse.data;

		if(err){

			console.log(addSpaceToCampaignResponse.data);

			dispatch(setToastMessage(`Error agregando espacio a campaña: ${ err }`));
			return dispatch(setToastVisible(true));

		}

		next(setMySpacesScreen(SPACES_SCREENS.CAMPAIGN_CONTRACTS));
		// next(getDetailedCampaignsList());
		next(setToastMessage('Espacio agregado a campaña'));
		next(setToastVisible(true));

		return dispatch(getDetailedCampaignsList(token));


	} catch(e) {

		console.error(e);

		dispatch(setToastMessage(`Error agregando a favoritos: ${ e.message }.`));
		return dispatch(setToastVisible(true));

	}

}



async function _addToFavorites(store, next, action){

	const { dispatch } = store;

	const { adTitle, token } = action.params;

	try {

		let storeFavoriteResponse = await add(adTitle, token).catch(e=> {

			console.error(e);

			dispatch(setToastMessage('Error agregando a favoritos: No pudimos comunicarnos con el servidor. Verifique que hay una conexion activa a Internet.'));
			return dispatch(setToastVisible(true));

		});

		let { err } = storeFavoriteResponse.data;

		if(err){

			console.log(storeFavoriteResponse.data);

			dispatch(setToastMessage(`Error agregando a favoritos: ${ err }`));
			return dispatch(setToastVisible(true));

		}

		dispatch(setToastMessage('Espacio agregado a favoritos'));
		dispatch(setToastVisible(true));
		// dispatch(setDisplayAddToCampaign(false));

		return next(action);


	} catch(e) {

		console.error(e);

		dispatch(setToastMessage(`Error agregando a favoritos: ${ e.message }.`));
		return dispatch(setToastVisible(true));

	}

}



async function _getFavorites(store, next, action){

	const { token } = action.params || action;

	const { dispatch } = store;


	try {

		let getFavoritesResponse = await getFavorites(token).catch(e=> {

			console.error(e);

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Error de comunicacion',
				modalMessage: `No pudimos comunicarnos con el servidor. Verifique que hay una conexion activa a Internet.`
			}));

		});

		let { err, favorites } = getFavoritesResponse.data;

		if(err){

			console.log(getFavoritesResponse.data);

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Error obteniendo lista de favoritos',
				modalMessage: `${ err }`
			}));

		}

		next(action);

		return dispatch(setFavorites(favorites));

	} catch(e) {

		console.error(e);

		return dispatch(openDialog(NoticeDialog, {
			modalHeader: 'Error al obtener favoritos.',
			modalMessage: `${ e }`
		}));

	}

}



async function _removeFromFavorites(store, next, action){

	const { dispatch } = store;

	const { favoriteId, token } = action.params;

	try {

		let removeFromFavoritesResponse = await removeFromFavorites(favoriteId, token).catch(e=> {

			console.error(e);

			dispatch(setToastMessage('Error agregando a favoritos: No pudimos comunicarnos con el servidor. Verifique que hay una conexion activa a Internet.'));
			return dispatch(setToastVisible(true));

		});

		let { err } = removeFromFavoritesResponse.data;


		if(err){

			console.log(removeFromFavoritesResponse.data);

			dispatch(setToastMessage(`Error eliminando favorito: ${ err }`));
			return dispatch(setToastVisible(true));

		}

		dispatch(setToastMessage('Espacio removido de favoritos'));
		dispatch(setToastVisible(true));

		return _getFavorites(store, next, action);


	} catch(e) {

		console.error(e);

		dispatch(setToastMessage(`Error agregando a favoritos: ${ e.message }.`));
		return dispatch(setToastVisible(true));

	}

}
