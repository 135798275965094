// @flow
import React, { Component } from 'react';

import { Button, Container, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import type { Dispatch } from 'redux';

import { CURRENT_DISPLAYING_SCREEN } from '../../app/constants';
import { setCurrentScreen } from '../../app/actions';
import { requestPasswordReset } from '../../auth/actions';
import { openDialog } from '../../dialog';
import { Dialog, NoticeDialog } from '../../notifications';

// import { login } from '../../auth/actions';

import { connect } from '../../redux';

import billboard_img from '../images/billboard.png';


type Props = {
	dispatch: Dispatch
}


const styles = theme => ({

	container: {
		left: '50%',
		position: 'absolute',
		top: '50%',
		transform: 'translate(-50%, -50%)'
	},

	grow: {
		flexGrow: 1
	},

	headerRight: {
		display: 'flex'
	},

	textField: {
		marginBottom: '1.5rem'
	},

	billboard_img: {
		width: '100px'
	}

});



class ForgotPassword extends Component<Props> {


	constructor(props){

		super(props);

		this.state = {
			username: ''
		}

		this._confirmPasswordReset = this._confirmPasswordReset.bind(this);
		this._inputChanged = this._inputChanged.bind(this);
		this._loadLoginForm = this._loadLoginForm.bind(this);
		this._sendPasswordResetRequest = this._sendPasswordResetRequest.bind(this);

	}


	render(){

		const { classes } = this.props;

		return (

			<Container
				maxWidth='xs'
				className={ classes.container }
			>
				<img className={ classes.billboard_img } src={ billboard_img } alt='' />

				<br></br>
				<br></br>

				<h3>Restablecer contrase&ntilde;a:</h3>

				<form noValidate autoComplete="off">

					<TextField
						className={ classes.textField }
						fullWidth
						id="username"
						label="Nombre de usuario (Correo Electrónico de registro)"
						onChange={ this._inputChanged }
						value={ this.state.username }
						variant="outlined" />

					<Button variant="contained" color="secondary" className={ classes.actionBtn } onClick={ this._confirmPasswordReset }>Enviar</Button>


				</form>
				<br></br>
				<br></br>
				<hr></hr>
				<p><Button color="secondary" onClick={ this._loadLoginForm }>REGRESAR A INICIO DE SESI&Oacute;N</Button></p>
				<hr></hr>
				<p>Al continuar, aceptas las <Button color="secondary">Condiciones de uso</Button> y el <Button color="secondary">Aviso de Privacidad</Button> de ADXpace.</p>


			</Container>

		);

	}


	_confirmPasswordReset(){

		const emailRE = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		if(!this.state.username || !emailRE.test(this.state.username)){

			return this.props.dispatch(openDialog(NoticeDialog, {
				modalHeader: `Cuenta de correo requerida`,
				modalMessage: `Ingrese una cuenta de correo válida`
			}));

		}

		return this.props.dispatch(openDialog(Dialog, {

			contentKey: `login.forgotPasswordConfirmation`,
			modalHeader: `Confirmar correo para envío de solicitud de restablecimiento de contraseña`,
			modalBody: `De existir un registro para la cuenta (correo electrónico) ${ this.state.username} se enviará un correo electrónico con instrucciones para restablecer su contraseña. Si en unos minutos no encuentra dicho correo en su bandeja de entrada, verifique la bandeja de spam.`,
			onSubmit: this._sendPasswordResetRequest,
			submitButtonText: `Confirmar`

		}));

	}



	_inputChanged(event){

		const { id, value } = event.target;

		this.setState({
			[id]: value
		});

	}


	_loadLoginForm(){

		this.props.dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.LOGIN));

	}


	_sendPasswordResetRequest(){


		const { username } = this.state;

		return this.props.dispatch(requestPasswordReset(username));

	}


}



export default withStyles(styles)(connect()(ForgotPassword));
