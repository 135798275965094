//	@flow

export const ADD_TO_CAMPAIGN = 'ADD_TO_CAMPAIGN';

export const ADD_TO_CAMPAIGN_FROM_CAMPAIGN = 'ADD_TO_CAMPAIGN_FROM_CAMPAIGN';

export const ADD_TO_FAVORITES = 'ADD_TO_FAVORITES';

export const GET_FAVORITES = 'GET_FAVORITES';

export const REMOVE_FROM_FAVORITES = 'REMOVE_FROM_FAVORITES';

export const SET_FAVORITES = 'SET_FAVORITES';

export const SET_SELECTED_FAVORITE = 'SET_SELECTED_FAVORITE';

export const SET_SELECTED_SPACE_NAME = 'SET_SELECTED_SPACE_NAME';
