//	@flow

import { SET_IFRAME_PATH } from './actionTypes';


export function setIframePath(iframePath){

	return{
		type: SET_IFRAME_PATH,
		iframePath
	}

}
