// @flow
import React, { Component } from 'react';

import { Button, Container, TextField, Typography  } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import type { Dispatch } from 'redux';

import { openDialog } from '../../dialog';
import { NoticeDialog } from '../../notifications';
import { connect } from '../../redux';

import { renameCampaign } from '../actions';


const styles = theme => ({

	root: {
		marginBottom: '0.7rem',
		width: '91%'
	},

	contentContainer:{
		backgroundColor: '#ffffff',
		border: 'solid 1px #999',
		left: '50%',
		padding: '2rem 1rem',
		position: 'absolute',
		top: '50%',
		transform: 'translate(-50%, -50%)'
	},

	mainContainer: {
		backgroundColor: 'rgba(250, 250, 250, 0.7)',
		border: 'solid 1px #333',
		height: '100%',
		left: 0,
		margin: 0,
		position: 'fixed',
		top: 0,
		width: '100%',
		zIndex: 1400
	},

});


type Props = {
	_userAuth: Object,
	dispatch: Dispatch
}


class CampaignRenameForm extends Component<Props> {

	constructor(props){

		super(props);

		this.state = {
			newCampaignName: ''
		}

		this._renameCampaign = this._renameCampaign.bind(this);
		this._updateCampaignNameDisplay = this._updateCampaignNameDisplay.bind(this);

	}


	componentDidMount(){

		this.setState({
			newCampaignName: this.props.campaign.campaignName
		});

	}


	render(){

		const { classes, hideForm } = this.props;
		const { newCampaignName } = this.state;


		return (

			<div className={ classes.mainContainer }>

				<Container className={ classes.contentContainer } maxWidth="sm">

					<Typography variant="subtitle1"  align="left" color="textPrimary" paragraph>
						Ingrese el nombre que desea para su campa&ntilde;a:
					</Typography>

					<TextField className={ classes.root } id="newCampaignName" label="Nombre para la campa&ntilde;a" onChange={ this._updateCampaignNameDisplay } size="small" value={ newCampaignName } variant="outlined" /> &nbsp; <br/> &nbsp; <br/>
					<Button variant="contained" color="secondary" onClick={ this._renameCampaign }>Renombrar campa&ntilde;a</Button> &nbsp; &nbsp; &nbsp;
					<Button variant="contained" color="primary" onClick={ hideForm }>Cancelar</Button>

				</Container>

			</div>

		);

	}



	_renameCampaign(){

		const { _userAuth, campaign, dispatch } = this.props;
		const { newCampaignName } = this.state;

		if(!newCampaignName){

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'Ingrese un nombre para su campaña.',
				modalMessage: `El nombre de la campaña debe tener algún valor.`
			}));

		}

		dispatch(renameCampaign({ campaignName: newCampaignName, campaignId: campaign.campaignId, token: _userAuth.token }));

		this.props.hideForm();

	}



	_updateCampaignNameDisplay(e){

		this.setState({
			newCampaignName: e.target.value
		});

	}

}


function _mapStateToProps(state){

	const { userAuth } = state['base/auth'];

	return {
		_userAuth: userAuth
	}

}


export default connect(_mapStateToProps)(withStyles(styles)(CampaignRenameForm));
