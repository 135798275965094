// @flow
import React, { Component } from 'react';

import { Button, Paper, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import type { Dispatch } from 'redux';

import { setToastMessage, setToastVisible } from '../../notifications';
import { connect } from '../../redux';

import { createCampaign, setDisplayingNewCampaignInput } from '../actions';

const styles = theme => ({

	root: {
		marginBottom: '0.7rem',
		marginTop: '0.7rem',
		width: '91%'
	},

});


type Props = {

	_isDisplayingNewCampaignInput: Boolean,
	_userAuth: Object,
	dispatch: Dispatch

}


class NewCampaignForm extends Component<Props> {

	constructor(props){

		super(props);

		this.state={
			newCampaignName: ''
		}

		this._createCampaign = this._createCampaign.bind(this);
		this._hideDisplayInput = this._hideDisplayInput.bind(this);
		this._toggleDisplayInput = this._toggleDisplayInput.bind(this);
		this._updateCampaignName = this._updateCampaignName.bind(this);

	}


	render(){

		const { classes } = this.props;
		const { newCampaignName } = this.state;
		const { _isDisplayingNewCampaignInput } = this.props;

		return (

			<Paper elevation={ 3 } style={{ marginBottom: '1.5rem', marginTop: '1.5rem', padding: "0.5rem 0" }}>

				{
					!_isDisplayingNewCampaignInput && <Button color="primary" onClick={ this._toggleDisplayInput }>Nueva campa&ntilde;a</Button>
				}



				{
					_isDisplayingNewCampaignInput &&
					<>
						<TextField className={ classes.root } id="newCampaignName" label="Nombre para la campa&ntilde;a" onChange={ this._updateCampaignName } size="small" value={ newCampaignName } variant="outlined" /> &nbsp;
						<Button variant="contained" color="secondary" onClick={ this._createCampaign }>Crear campa&ntilde;a</Button> &nbsp;
						<Button variant="contained" color="primary" onClick={ this._hideDisplayInput }>Cancelar</Button>
					</>

				}

			</Paper>

		)

	}


	_createCampaign(){

		const { _userAuth, dispatch } = this.props;
		const { newCampaignName } = this.state;

		if(!newCampaignName){

			dispatch(setToastMessage('El nombre de campaña es necesario.'));
			return dispatch(setToastVisible(true));

		}

		dispatch(setToastVisible(false));

		dispatch(createCampaign({ token: _userAuth.token, campaignName: newCampaignName }));

	}


	_hideDisplayInput(){

		this.props.dispatch(setDisplayingNewCampaignInput(false));

	}


	_toggleDisplayInput(){

		let displayInput = !this.props._isDisplayingNewCampaignInput;

		this.props.dispatch(setDisplayingNewCampaignInput(displayInput));

	}


	_updateCampaignName(event){

		const { id, value } = event.target;

		this.setState({
			[id]: value
		});

	}

}


function _mapStateToProps(state){

	const { userAuth } = state['base/auth'];
	const { isDisplayingNewCampaignInput } = state['base/campaigns'];

	return {
		_isDisplayingNewCampaignInput: isDisplayingNewCampaignInput,
		_userAuth: userAuth
	}

}


export default connect(_mapStateToProps)(withStyles(styles)(NewCampaignForm));
