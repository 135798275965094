//  @flow

import { ReducerRegistry } from '../redux';

import { SET_FAVORITES, SET_SELECTED_FAVORITE, SET_SELECTED_SPACE_NAME } from './actionTypes';


const STORE_NAME = 'base/favorites';

const DEFAULT_STATE = {

	favorites: [ ],
	selectedFavorite: undefined,
	selectedSpaceName: undefined

}


ReducerRegistry.register(
    STORE_NAME,
    (state = DEFAULT_STATE, action) => {

		switch (action.type) {

        case SET_FAVORITES:

            return {
                ...state,
                favorites: action.favorites
            }


			case SET_SELECTED_FAVORITE:
				return {
					...state,
					selectedFavorite: action.selectedFavorite
				}


			case SET_SELECTED_SPACE_NAME:
				return {
					...state,
					selectedSpaceName: action.selectedSpaceName
				}


        default:
            return state;

    }

});
