import axios from 'axios';

import { settings } from './settings';

const SERVER_URL = settings.endpoint;


function add(adTitle, token){

	const params = new URLSearchParams();

	params.append('adTitle', adTitle);


	const config = {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-access-token': token
		}
	}

	return axios.post(`${ SERVER_URL }/favorites/add`, params, config);

}


function addToCampaign(adId, campaignId, token, adTitle){

	const params = new URLSearchParams();

	params.append('spaceId', adId);
	params.append('campaignId', campaignId);
	params.append('adTitle', adTitle);


	const config = {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-access-token': token
		}
	}

	return axios.post(`${ SERVER_URL }/favorites/addToCampaign`, params, config);

}



function getFavorites(token){

	const config = {
		headers: {
			'x-access-token': token
		}
	};

	return axios.get(`${ SERVER_URL }/favorites/list`, config);

}



function removeFromFavorites(favoriteId, token){

	const params = new URLSearchParams();

	params.append('favoriteId', favoriteId);


	const config = {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-access-token': token
		}
	}

	return axios.post(`${ SERVER_URL }/favorites/remove`, params, config);

}


export { add, addToCampaign, getFavorites, removeFromFavorites };
