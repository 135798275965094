//	@flow

export const GET_MULTISPACE_DETAILS = 'GET_MULTISPACE_DETAILS';

export const GET_MULTISPACE_RESUME = 'GET_MULTISPACE_RESUME';

export const GET_MULTISPACE_SPACES = 'GET_MULTISPACE_SPACES';

export const SET_CURRENT_MULTISPACE_SPACE = 'SET_CURRENT_MULTISPACE_SPACE';

export const SET_MULTISPACE_ID = 'SET_MULTISPACE_ID';

export const SET_MULTISPACE_RESUME = 'SET_MULTISPACE_RESUME';

export const SET_MULTISPACE_SPACES = 'SET_MULTISPACE_SPACES';
