//  @flow

import { ReducerRegistry } from '../redux';

import { SET_TOAST_MESSAGE, SET_TOAST_VISIBLE } from './actionTypes';


const STORE_NAME = 'base/notifications';

const DEFAULT_STATE = {

	toastMessage: '',
	isToastVisible: false

}


ReducerRegistry.register(
    STORE_NAME,
    (state = DEFAULT_STATE, action) => {

		switch (action.type) {

			case SET_TOAST_MESSAGE:

				return {
					...state,
					toastMessage: action.message
				}


			case SET_TOAST_VISIBLE:

				return {
					...state,
					isToastVisible: action.toastVisible
				}


        default:
            return state;

    }

});
