//	@flow

import { SET_TOAST_MESSAGE, SET_TOAST_VISIBLE } from './actionTypes';


export function setToastMessage(message){

	return {
		type: SET_TOAST_MESSAGE,
		message
	}

}


export function setToastVisible(toastVisible){

	return {
		type: SET_TOAST_VISIBLE,
		toastVisible
	}

}
