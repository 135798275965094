//	@flow

export const LOGIN = 'LOGIN';

export const REGISTER_USER = 'REGISTER_USER';

export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';

export const SET_USER_AUTH = 'SET_USER_AUTH';

export const SET_USER_DATA = 'SET_USER_DATA';

export const SET_USER_LOGGED_IN = 'SET_USER_LOGGED_IN';

export const SUBMIT_NEW_PASSWORD = 'SUBMIT_NEW_PASSWORD';

export const USER_LOGOUT = 'USER_LOGOUT';
