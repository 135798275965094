//	@flow

import { GET_MULTISPACE_RESUME, GET_MULTISPACE_SPACES, SET_MULTISPACE_ID, SET_MULTISPACE_RESUME, SET_CURRENT_MULTISPACE_SPACE, SET_MULTISPACE_SPACES } from './actionTypes';



export function getMultispaceResume(multispaceId){

	return {
		type: GET_MULTISPACE_RESUME,
		multispaceId
	}

}


export function getMultispaceSpaces(multispaceId, childId){
	return {
		type: GET_MULTISPACE_SPACES,
		childId,
		multispaceId
	}
}


export function setMultispaceId(multispaceId){

	return {
		type: SET_MULTISPACE_ID,
		multispaceId
	}

}


export function setMultispaceResume(multispaceResume){

	return {
		type: SET_MULTISPACE_RESUME,
		multispaceResume
	}

}


export function setMultispaceSpace(multispaceSpace){

	return {
		type: SET_CURRENT_MULTISPACE_SPACE,
		multispaceSpace
	}

}


export function setMultispaceSpaces(multispaceSpaces){

	return {
		type: SET_MULTISPACE_SPACES,
		multispaceSpaces
	}

}
