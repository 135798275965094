// @flow
import React, { Component } from 'react';

import { Card, CardContent, CardHeader, Grid, CardActions, IconButton, Typography  } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import { Delete, Edit } from '@material-ui/icons';
import type { Dispatch } from 'redux';

import { setMySpacesScreen } from '../../mySpaces/actions';
import { SPACES_SCREENS } from '../../mySpaces/constants';
import { openDialog } from '../../dialog';
import { Dialog, NoticeDialog } from '../../notifications';
import { connect } from '../../redux';
import { getCampaignsStatus, getDetailedCampaignsList, removeCampaign, setCurrentEditingCampaign, setEditingCampaignIndex, setEditingCampaignSpaces } from '../actions';

import CampaignRenameForm from './campaignRenameForm';


const styles = theme => ({

	headerContainer: {
		padding: theme.spacing(3,0,3)
	},
	headerTitle: {
		color: "#FFFFFF"
	},
	headerSlogan: {
		color: "#FFFFFF"
	},
	cardGrid:{
		padding:'20px 0'
	},
	cardHeader: {
		padding: '0.7rem 1rem 0',
		textAlign: 'left',
	},
	tumb:{
		paddingBottom: '1rem',
		paddingLeft: '1rem',
		paddingRight: '1rem',
		paddingTop: '1rem',
		width:'100%'
	},
	tituloCard:{
		color: "#0830B1",
		paddingBottom: '1rem',
		paddingLeft: '1rem',
		paddingRight: '1rem',
		paddingTop: '1rem',
		width:'100%'
    },
	card:{
		cursor: 'pointer',
		display:'flex',
		flexDirection:'column',
		height: '23rem',
		backgroundColor: "#F4FAFA",
	},
	cardActive: {
		cursor: 'pointer',
		display:'flex',
		flexDirection:'column',
		height: '23rem',
		backgroundColor: "#F0E1E1",
	},
	cardContent:{
		flexGrow:1  ,
		display: 'inline-block',
		margin: '0 0.3rem',
		paddingBottom: '3rem',
	},

	campaignsContent:{
		justifyContent:"center"

	},
	campaignSpaces: {
		marginTop: '1.5rem'
	},
	campaignsList:{
		marginBottom: '0.7rem',
		marginTop: '0',
		paddingBottom: '1rem',
		paddingLeft: '1rem',
		paddingRight: '1rem',
		paddingTop: '1rem',

	},
	media: {
		height: 0,
		paddingTop: '56.25%', // 16:9
	},

	gridContenido:{
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '0.7rem',
		width: '90%'
	},

	title: {
		marginLeft: '2rem',
		marginTop: '1.5rem'
	}
});


type Props = {
	_currentEditingCampaign: Object,
	_detailedCampaigns: Array<Object>,
	_editingCampaignDetails: Array<Object>,
	_editingCampaignIndex: Number,
	_userAuth: Object,
	dispatch: Dispatch
}


const to2 = (num) => num < 10 ? `0${ num }` : `${ num }`;


function numberFormat(value, decimalPositions, decimalSeparator, thousandsSeparator){

	decimalPositions = isNaN(decimalPositions = Math.abs(decimalPositions)) ? 2 : decimalPositions;
    decimalSeparator = decimalSeparator === undefined ? "." : decimalSeparator;
    thousandsSeparator = thousandsSeparator === undefined ? "," : thousandsSeparator;
    let sign = value < 0 ? "-" : "";
    let i = String(parseInt(value = Math.abs(Number(value) || 0).toFixed(decimalPositions)));
	let k = i.length;
    let j = k > 3 ? k % 3 : 0;

   return sign + (j ? i.substr(0, j) + thousandsSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandsSeparator) + (decimalPositions ? decimalSeparator + Math.abs(value - i).toFixed(decimalPositions).slice(2) : "");

}



class Campaigns extends Component<Props> {


	constructor(props){

		super(props);

		this.state = {
			currentEditingCampaign: undefined,
			displayCampaignRenameForm: false
		}

		this._deleteCampaign = this._deleteCampaign.bind(this);
		this._displayCampaignRenameForm = this._displayCampaignRenameForm.bind(this);
		this._editCampaignTitle = this._editCampaignTitle.bind(this);
		this._hideRenameCampaignForm = this._hideRenameCampaignForm.bind(this);
		this._inputChanged = this._inputChanged.bind(this);
		this._loadCampaignsStatus = this._loadCampaignsStatus.bind(this);
		this._reloadDetailedCampaigns = this._reloadDetailedCampaigns.bind(this);
		this._requestDeleteCampaign = this._requestDeleteCampaign.bind(this);
		this._setEditingCampaignSpacesIndex = this._setEditingCampaignSpacesIndex.bind(this);

	}



	componentDidMount(){

		this._loadCampaignsStatus();
		this._reloadDetailedCampaigns();

	}



	render(){

		const { classes } = this.props;

		const { _detailedCampaigns, _editingCampaignIndex } = this.props;

		const _currentEditingCampaign = _detailedCampaigns?.length ? _detailedCampaigns[_editingCampaignIndex] : undefined;


		return (

			<div className={ classes.campaignsContent }>

				<Typography className={ classes.title } variant="h4"  align="left" color="textPrimary" >
					Mis Campañas
				</Typography>

				<Grid container className={ classes.gridContenido } direction="row" justifyContent="flex-end">

					{

						_detailedCampaigns?.map((campaign, index) => {

							const campaignCDate = campaign.cDate ? new Date(campaign.cDate) : undefined;
							const alreadyContracted = campaign.spaces.filter(space => space.contractStatus > 6);
							const notYetContracted = campaign.spaces.length - alreadyContracted.length;

							const listingPrice = campaign.spaces.reduce((prev, current) => current.contractStatus < 4 || (current.contractStatus > 7 && current.contractStatus < 13) || current.contractStatus === 14 ? prev + 30 / parseInt(current.spacePaymentPeriod) * parseFloat(current.spacePrice) : prev, 0);

							const quotedPrice = campaign.spaces.reduce((prev, current) => current.contractStatus > 3 && current.contractStatus < 7 ? prev + 30 / parseInt(current.spacePaymentPeriod) * parseFloat(current.quotationPrice) : prev, 0);

							const contractPrice = campaign.spaces.reduce((prev, current) => current.contractStatus === 7 || current.contractStatus === 13 ? prev + 30 / parseInt(current.spacePaymentPeriod) * parseFloat(current.quotationPrice) : prev, 0);


							return (

								<Grid item xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 } className={ classes.campaignsList } key={ `campaign_${ campaign.campaignId }` }>

									<Card className={ _currentEditingCampaign?.campaignId === campaign.campaignId ? classes.cardActive : classes.card } onClick={ (e) => this._setEditingCampaignSpacesIndex(index) }>

										<CardHeader
											action={
												<CardActions align="right">
													<IconButton aria-label="Renombrar" onClick={ e => this._editCampaignTitle(e, index) }>
														<Edit />
													</IconButton>
													<IconButton aria-label="Eliminar" onClick={ e => this._requestDeleteCampaign(e, index) }>
														<Delete />
													</IconButton>
												</CardActions>
											}
											className={ classes.cardHeader }
											title={ campaign.campaignName } />

										<CardContent className={ classes.cardContent } >

											{
												campaignCDate &&
												<Typography variant="subtitle2"  align="left" color="textPrimary" paragraph >
													<span>Creada el { to2(campaignCDate.getDate()) } de { to2(campaignCDate.getMonth() + 1) } de { campaignCDate.getFullYear() }</span>
													<br></br>
												</Typography>
											}

											{
												campaign.spaces.length > 0 ?
												<Typography variant="subtitle1"  align="left" color="textPrimary" paragraph >
													<span>Costo mensual estimado: &nbsp; </span><br/>
													{ listingPrice > 0 && <span> &nbsp; Espacios nuevos:<strong> $ { numberFormat(listingPrice, 0) }</strong><br/></span> }
													{ quotedPrice > 0 && <span> &nbsp; Espacios cotizados:<strong> $ { numberFormat(quotedPrice, 0) }</strong><br/></span> }
													{ contractPrice > 0 && <span> &nbsp; Espacios contratados:<strong> $ { numberFormat(contractPrice, 0) }</strong></span> }
													<br></br>
												</Typography>
												:
												<Typography variant="subtitle1"  align="left" color="textPrimary" paragraph >
													No se han agregado espacios a esta campa&ntilde;a.
												</Typography>
											}



											{
												notYetContracted > 0 && <Typography variant="subtitle2"  align="left" color="textPrimary">
												{ notYetContracted } Espacio{ notYetContracted > 1 && 's' } asociado{ notYetContracted > 1 && 's' } por contratar <br/></Typography>

											}
											{
												alreadyContracted.length > 0 && <Typography variant="subtitle2"  align="left" color="textPrimary" paragraph >
												{ alreadyContracted.length } Espacios asociado{ alreadyContracted.length > 1 && 's'} contratado{ alreadyContracted.length > 1 && 's'} <br/></Typography>

											}

										</CardContent>

									</Card>

								</Grid>

							)

						}

					)}

				</Grid>


				{
					this._displayCampaignRenameForm()
				}

			</div>

		)

	}


	_deleteCampaign(campaignId){

		const { _userAuth, dispatch } = this.props;

		return dispatch(removeCampaign({ token: _userAuth.token, campaignId: this.state.currentEditingCampaign.campaignId }));

	}



	_requestDeleteCampaign(event, index){

		event.stopPropagation();

		const { _detailedCampaigns, dispatch } = this.props;

		const campaign = _detailedCampaigns[index];

		let canDelete = true;

		if(campaign.spaces.length > 0){

			campaign.spaces.forEach(space => {

				if(space.contractStatus > 1){

					canDelete = false;

				}

			});

		}


		if(!canDelete){

			return dispatch(openDialog(NoticeDialog, {
				modalHeader: 'No es posible eliminar esta campaña.',
				modalMessage: `La campaña tiene uno o más de sus espacios con trámites o contratos en proceso. Cancele primero todos los trámites de los espacios asociados a la campaña para poderla eliminar.`
			}));

		}


		this.setState({
			currentEditingCampaign: this.props._detailedCampaigns[index]
		});


		return dispatch(openDialog(Dialog, {

			modalHeader: `Confirmar eliminación de campaña`,
			modalBody: `Está solicitando eliminar la campaña ${ campaign.campaignName }. Esta operación no puede deshacerse. Presione confirmar para continuar.`,
			onSubmit: this._deleteCampaign,
			submitButtonText: `Confirmar`

		}));

	}


	_displayCampaignRenameForm(){

		return this.state.displayCampaignRenameForm ? <CampaignRenameForm campaign={ this.state.currentEditingCampaign } hideForm={ this._hideRenameCampaignForm } /> : null;
		// return null;

	}



	_editCampaignTitle(event, index){

		event.stopPropagation();

		this.setState({
			currentEditingCampaign: this.props._detailedCampaigns[index],
			displayCampaignRenameForm: true
		});

	}


	_hideRenameCampaignForm(){

		this.setState({
			currentEditingCampaign: undefined,
			displayCampaignRenameForm: false
		});

	}


	_inputChanged(event){

		const { id, value } = event.target;

		this.setState({
			[id]: value
		});

	}


	_loadCampaignsStatus(){

		const { _userAuth, dispatch } = this.props;

		dispatch(getCampaignsStatus(_userAuth.token));

	}


	_reloadDetailedCampaigns(){

		const { _userAuth, dispatch } = this.props;

		dispatch(getDetailedCampaignsList(_userAuth.token));

	}



	_setEditingCampaignSpacesIndex(index){

		const { _detailedCampaigns, dispatch } = this.props;

		const campaign = _detailedCampaigns[index];

		dispatch(setCurrentEditingCampaign({
			campaignId: campaign.campaignId,
			campaignName: campaign.campaignName
		}));

		dispatch(setEditingCampaignIndex(index));
		dispatch(setEditingCampaignSpaces(campaign.spaces));
		dispatch(setMySpacesScreen(SPACES_SCREENS.CAMPAIGN_CONTRACTS));

	}

}


function _mapStateToProps(state){

	const { userAuth } = state['base/auth'];
	const { detailedCampaigns, editingCampaignIndex } = state['base/campaigns'];

	return {
		_editingCampaignIndex: editingCampaignIndex,
		_userAuth: userAuth,
		_detailedCampaigns: detailedCampaigns
	}

}


export default connect(_mapStateToProps)(withStyles(styles)(Campaigns));
