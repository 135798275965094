//	@flow

import React from 'react';

import { connect } from '../../redux';
import { _abstractMapStateToProps }  from '../functions';

import BaseDialog, { type Props as AbstractProps } from './BaseDialog';

type Props = AbstractProps;

/**
*	Generic alert dialog based on { @code BaseDialog }. Presents a dialog with only one button.
*	Can receive modalHeader text, modalBody text or component and modalFooter text properties.
*
**/
class Alert extends BaseDialog<Props> {

    componentDidMount(){

    }

    render(){

        const props = this.props;

        return (
            <BaseDialog { ...props } />
        )

    }

}


export default connect(_abstractMapStateToProps)(Alert);
