// @flow
import React, { Component } from 'react';

import { Typography  } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({

	footer: {
		backgroundColor: "#000000",
		bottom: 0,
		padding: theme.spacing(0.7, 0, 0.7),
		position: 'fixed',
		width: '100%',
		zIndex: 9999
	},
	headerSlogan: {
        color: "#FFFFFF"
	},

});


class Footer extends Component {



	render(){

		const { classes } = this.props;

		return (

			<footer className={ classes.footer }>
				<Typography variant="body1"  className={ classes.headerSlogan } align="center" >
					&copy; ADXPACE. Todos los derechos reservados { new Date().getFullYear() }
				</Typography>
			</footer>

		)

	}

}


export default withStyles(styles)(Footer);
