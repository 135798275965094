// @flow
import React, { Component } from 'react';

import { Box, Button, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';

// import { DateRangePicker } from 'react-date-range';
import Dayz from 'dayz';
import moment from 'moment';
// import { es } from 'react-date-range/dist/locale';

import { getSpaceCalendar } from '../../api/spaces';

import "dayz/dist/dayz.css";
import '../style/styles.css';

const MONTHS = [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ];

const to2 = (num) => num < 10 ? `0${ num }` : `${ num }`;


const styles = theme => ({
    centeredContent: {
        textAlign: 'center',
        '& button': {
            margin: 0,
            padding: 0,
            minWidth: '1rem!important',
            width: '2rem'
        }
    },
    mtb: {
        marginBottom: '0.3rem',
        marginLeft: '-1rem',
        marginTop: '0.7rem'
    },
    secondMonth: {
        [theme.breakpoints.down("xs")]: {
            display: 'none'
        },
    },
    title: {
        marginBottom: '-0.5rem',
        marginTop: '2rem'
    }
});


class SpaceCalendar extends Component {


    constructor(props){

        super(props);

        this.state = {
            calendarRanges: new Dayz.EventsCollection([ ]),
            currentDate: moment()
        }

        this._getMonth = this._getMonth.bind(this);
        this._getSpaceCalendar = this._getSpaceCalendar.bind(this);
        this._nextMonth = this._nextMonth.bind(this);
        this._prevMonth = this._prevMonth.bind(this);

    }


    componentDidMount(){

        this._getSpaceCalendar();

    }


    render(){

        const { classes } = this.props;
        const { currentDate } = this.state;
        const nextDate = currentDate.clone().add(1, 'M');

        return(
            <Grid container spacing= { 5 }>

                <Grid className={ classes.title } container>

                    <Grid className={ classes.centeredContent } item xs={ 1 }>
                        <Button onClick={ e => this._prevMonth() }>
                            <NavigateBefore color="primary" fontSize="large" />
                        </Button>
                    </Grid>


                    <Grid item xs={ 10 }>

                        <Box fontSize={ 19 } fontWeight="fontWeightMedium" textAlign="center"> Calendario del espacio <strong>{ this.props.spaceName }</strong></Box>

                    </Grid>


                    <Grid className={ classes.centeredContent } item xs={ 1 }>
                        <Button onClick={ e => this._nextMonth() }>
                            <NavigateNext color="primary" fontSize="large" />
                        </Button>
                    </Grid>

                </Grid>

                <Grid className={ classes.secondMonth } item xs={ 12 } sm={ 6 }>

                    <Grid container spacing={ 1 }>

                        <Grid item xs={ 12 }>

                            <Typography component="div">
                                <Box fontSize={ 18 } fontWeight="fontWeightMedium" textAlign="center">{ this._getMonth(currentDate.month()) } { currentDate.year() }</Box>
                            </Typography>
                        </Grid>

                        <Grid item xs={ 12 }>
                            <Dayz
                                date={ currentDate }
                                locale='es'
                                months={ 2 }
                                events={ this.state.calendarRanges }
                                showPreview={ false }
                            />
                        </Grid>

                    </Grid>

                </Grid>

                <Grid item xs={ 12 } sm={ 6 }>

                    <Grid container spacing={ 1 }>

                        <Grid item xs={ 12 }>
                            <Typography component="div">
                                <Box fontSize={ 18 } fontWeight="fontWeightMedium" textAlign="center">{ this._getMonth(currentDate.month() + 1) } { nextDate.year() }</Box>
                            </Typography>
                        </Grid>

                        <Grid item xs={ 12 }>
                            <Dayz
                                date={ nextDate }
                                locale='es'
                                months={ 2 }
                                events={ this.state.calendarRanges }
                                showPreview={ false }
                            />
                        </Grid>
                    </Grid>

                </Grid>

                <Grid className={ classes.mtb } container spacing={ 1 }>
                    <Grid item xs={ 1 } md={ 1 }></Grid>
                    <Grid item xs={ 2 } md={ 1 } style={{ backgroundColor: 'yellow' }} ></Grid>
                    <Grid item xs={ 3 } md={ 4 }>Apartado</Grid>
                    <Grid item xs={ 1 } md={ 1 }></Grid>
                    <Grid item xs={ 2 } md={ 1 } style={{ backgroundColor: 'orange' }}></Grid>
                    <Grid item xs={ 3 } md={ 4 }>Confirmado</Grid>
                    <Grid item xs={ 1 } md={ 1 }></Grid>
                    <Grid item xs={ 2 } md={ 1 } style={{ backgroundColor: '#fc2500' }}></Grid>
                    <Grid item xs={ 3 } md={ 4 }>Contratado</Grid>
                    <Grid item xs={ 1 } md={ 1 }></Grid>
                    <Grid item xs={ 2 } md={ 1 } style={{ borderWidth: 1, borderColor: '#333', borderStyle: 'solid' }}></Grid>
                    <Grid item xs={ 3 } md={ 4 }>Disponible</Grid>
                </Grid>
            </Grid>
        )

    }


    _getMonth(n){
        return MONTHS[n % 12];
    }


    _getSpaceCalendar(){

        const { spaceId, token } = this.props;

        getSpaceCalendar(token, spaceId)
		.then(res => {

			if(res.data.length){

                const calendar = res.data.map(r => {

                    let expiresIn = ``;

                    if(r.reservedQuotationExpiryDate && r.calendar_status_id === 1){

                        const now = moment();
                        const expiry = moment(r.reservedQuotationExpiryDate);
                        const diff = expiry.diff(now);

                        expiresIn = Math.round(to2(diff / 1000 / 3600));

                    }

                    return {
                        content: r.calendar_status_id === 1 ? 'Apartado' : r.calendar_status_id === 2 ? 'confirmado' : 'contratado',
                        range: moment.range(r.startDate, r.endDate),
                        key: `date_range_${ r.id }`,
                        render: e => (
                            <div className={r.calendar_status_id === 1 ? 'yellow' : r.calendar_status_id === 2 ? 'orange' : 'red'}>{ r.calendar_status_id === 1 ? 'Apartado' : r.calendar_status_id === 2 ? 'confirmado' : 'contratado' } { expiresIn && <Box component="span" fontSize={ 12 }>(Reservación expira en { expiresIn } horas)</Box> }</div>
                            )
                    }
                });

				this.setState({
					calendarRanges: new Dayz.EventsCollection([...calendar])
				});

			} else {
                this.setState({
                    calendarRanges: new Dayz.EventsCollection([])
                });
            }

		}).catch(e => {
			console.error(e);
		});

    }


    _nextMonth(){

        let { currentDate } = this.state;

        let nextMonth = currentDate.clone().add(1, 'M');

        this.setState({
            currentDate: nextMonth
        });

    }


    _prevMonth(){

        let { currentDate } = this.state;

        let nextMonth = currentDate.clone().add(-1, 'M');

        this.setState({
            currentDate: nextMonth
        });

    }

}


export default withStyles(styles)(SpaceCalendar);
