// @flow
import React, { Component } from 'react';

import { green, red } from '@material-ui/core/colors';
import { Button, Card, CardContent, CardMedia, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import type { Dispatch } from 'redux';

import { getPostMedia, getSpaceCategories, getSpaceDetails } from '../../api/spaces';
import { setCurrentPost, setCurrentScreen } from '../../app/actions';
import { CURRENT_DISPLAYING_SCREEN } from '../../app/constants';
import { setDisplayAddToCampaign } from '../../campaigns/actions';
import { setIframePath } from '../../home/actions';
import { getMultispaceSpaces } from '../../multispaces/actions.js';
import { connect } from '../../redux';

import { setSelectedFavorite } from '../actions';


type Props = {
	dispatch: Dispatch
}

const styles = theme => ({

	root: {
		backgroundColor: '#efefef',
		display: 'flex',
		// height: '11.7rem',
		marginBottom: '1.3rem',
		marginTop: '0.3rem',
		'&:hover':{
			backgroundColor: '#f7f7f7',
		},
		maxWidth: '100%',
		width: '100%'
	},
	buttonsContainer:{
		margin: '1.3rem 0.7rem',
		textAlign: 'center',
		'& button': {
			marginBottom: '0.5rem',
			marginRight: '1rem'
		}
	},
	categoriesContainer: {
		marginBottom: '1.7rem'
	},
	category: {
		backgroundColor: '#E83022',
		border: 'solid 1px #eee',
		borderBottomRightRadius: '0.5rem',
		borderTopRightRadius: '0.5rem',
		color: '#fff',
		fontSize: '1rem',
		fontWeight: '500',
		padding: '0.7rem 1rem',
		textAlign: 'center',
	},
	content: {
		flex: '1 0 auto',
		margin: '1rem',
		textAlign: 'left',
		width: '66%'
	},
	cover: {
		backgroundSize: 'contain',
		float: 'left',
		height: '11rem',
		margin: '0.5rem',
		width: '33%',
	},
	dangerButton: {
		background: red[900],
		color: 'white',

	},
	details: {
		margin: '0.7rem 1rem 1.5rem',
		textAlign: 'left',
		width: '100%'
	},
	leftPanel: {
		float: 'left',
		paddingTop: '1rem',
		textAlign: 'left',
		width: '60%'
	},
	successBtn: {
		background: green[800],
		color: 'white',
		'&:hover': {
			background: green[900],
		}
	}

});


function capitalize(str) {

	const lower = str.toLowerCase();
	return str.charAt(0).toUpperCase() + lower.slice(1);

}



function numberFormat(value, decimalPositions, decimalSeparator, thousandsSeparator){

	decimalPositions = isNaN(decimalPositions = Math.abs(decimalPositions)) ? 2 : decimalPositions;
    decimalSeparator = decimalSeparator === undefined ? "." : decimalSeparator;
    thousandsSeparator = thousandsSeparator === undefined ? "," : thousandsSeparator;
    let sign = value < 0 ? "-" : "";
    let i = String(parseInt(value = Math.abs(Number(value) || 0).toFixed(decimalPositions)));
	let k = i.length;
    let j = k > 3 ? k % 3 : 0;

   return sign + (j ? i.substr(0, j) + thousandsSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandsSeparator) + (decimalPositions ? decimalSeparator + Math.abs(value - i).toFixed(decimalPositions).slice(2) : "");

}


class FavoriteCard extends Component<Props> {

	constructor(props){

		super(props);

		this.state = {
			postThumb: '',
			spaceDetails: { }
		}

		this._addToCampaign = this._addToCampaign.bind(this);

	}



	componentDidMount(){

		getPostMedia(this.props.favorite.postId)
		.then(res => {

			if(res.data.pictures.length){

				this.setState({
					postThumb: res.data.pictures[0].guid
				});

			}

		}).catch(e => {
			console.error(e);
		});


		getSpaceCategories(this.props.token, this.props.favorite.postId)
		.then(res => {
			this.setState({
				categories: [...res.data.categories]
			});
		}).catch(e => {
			console.error(e);
		});



		getSpaceDetails(this.props.token, this.props.favorite.postId)
		.then(res => {

			const { spaceDetails } = res.data;

			this.setState({
				spaceDetails: spaceDetails
			});


		}).catch(e => {
			console.error(e);
		});

	}



	render(){

		const { classes, favorite } = this.props;
		const { categories, spaceDetails } = this.state;


		return(

			<Card className={ classes.root } onClick={ e => this._displaySpace(favorite.postName) }>

				<CardContent className={ classes.content }>

					<Typography component="h5" variant="h5">

						{ favorite.post_title }

					</Typography>

					{
						this.state.postThumb &&
						<CardMedia
							className={ classes.cover }
							image={ this.state.postThumb }
							title={ favorite.post_title }
						/>
					}

					<Grid container className={ classes.leftPanel }>

						{
							spaceDetails._cth_id_adxpace &&
							<>
								<Grid item xs={ 11 } sm={ 7 } md={ 9 }>
									<Typography variant="subtitle1"  align="right" color="textPrimary" gutterBottom >
										C&oacute;digo ADXpace: &nbsp;
									</Typography>
								</Grid>
								<Grid item xs={ 12 } sm={ 5 } md={ 3 }>
									<Typography align="right">
										<strong> { spaceDetails._cth_id_adxpace } </strong><br></br>
									</Typography>
								</Grid>
							</>
						}

						{
							spaceDetails._cth_periodo_contratación &&
							<>
								<Grid item xs={ 11 } sm={ 7 } md={ 9 }>
									<Typography variant="subtitle1"  align="right" color="textPrimary" gutterBottom >
										Per&iacute;odo de contrataci&oacute;n: &nbsp;
									</Typography>
								</Grid>
								<Grid item xs={ 12 } sm={ 5 } md={ 3 }>
									<Typography align="right">
										<strong> { spaceDetails._cth_periodo_contratación } d&iacute;as</strong><br></br>
									</Typography>
								</Grid>
							</>
						}

						{
							spaceDetails._cth_periodo_pago &&
							<>
								<Grid item xs={ 11 } sm={ 7 } md={ 9 }>
									<Typography variant="subtitle1"  align="right" color="textPrimary" gutterBottom >
										Per&iacute;odo de pago: &nbsp;
									</Typography>
								</Grid>
								<Grid item xs={ 12 } sm={ 5 } md={ 3 }>
									<Typography align="right">
										<strong> { spaceDetails._cth_periodo_pago } d&iacute;as</strong><br></br>
									</Typography>
								</Grid>
							</>
						}

						{
							spaceDetails._cth_descuento_volumen !== 'a:0:{}' &&

								<Grid item xs={ 12 } sm={ 11 }>
									<Typography variant="subtitle1"  align="right" color="textSecondary" gutterBottom >
										-- Descuento por volumen --<br></br>
									</Typography>
								</Grid>

						}


						{
							spaceDetails._cth_price &&
							<>
								<Grid item xs={ 11 } sm={ 7 } md={ 9 }>
									<Typography variant="subtitle1"  align="right" color="textPrimary" gutterBottom >
										Precio de lista: &nbsp;
									</Typography>
								</Grid>
								<Grid item xs={ 12 } sm={ 5 } md={ 3 }>
									<Typography align="right">
										<strong> $ { numberFormat(spaceDetails._cth_price, 0) } </strong><br></br>
									</Typography>
								</Grid>
							</>
						}


					</Grid>

					<Grid className={ classes.categoriesContainer } container>

						{
							categories && categories.length > 0 ?

								categories.map((cat, i) =>

									<Grid className={ classes.category } item key={ `category_${i}` } xs={ 4 } sm={ 3 } md={ 2 }>
										{ cat.name }
									</Grid>

								)

							: null
						}

					</Grid>


					<Grid className={ classes.details } container>



						<Grid item xs={ 10 }>
							{ spaceDetails._cth_calle } { spaceDetails._cth_numero }, { spaceDetails._cth_colonia }, { spaceDetails._cth_municipio }, { spaceDetails._cth_zip_code }<br/>&nbsp;<br/>
							{ spaceDetails._cth_observaciones } <br/>&nbsp;<br/>
						</Grid>

						{
							spaceDetails._cth_afluencia_anual &&
							<Grid className={ classes.spaceFeature } item xs={ 6 }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									Afluencia anual: &nbsp; <strong> { spaceDetails._cth_afluencia_anual && capitalize(spaceDetails._cth_afluencia_anual) } </strong><br></br>
								</Typography>
							</Grid>
						}

						{
							spaceDetails._cth_base &&
							<Grid className={ classes.spaceFeature } item xs={ 4 }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									Base: &nbsp; <strong> { spaceDetails._cth_base }m </strong><br></br>
								</Typography>
							</Grid>
						}

						{
							spaceDetails._cth_altura &&
							<Grid className={ classes.spaceFeature } item xs={ 4 }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									Altura: &nbsp; <strong> { spaceDetails._cth_altura }m </strong><br></br>
								</Typography>
							</Grid>
						}

						{
							spaceDetails._cth_vista &&
							<Grid className={ classes.spaceFeature } item xs={ 4 }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									Vista: &nbsp; <strong> { spaceDetails._cth_vista && capitalize(spaceDetails._cth_vista) } </strong><br></br>
								</Typography>
							</Grid>
						}

						{
							spaceDetails._cth_tamano_pantalla &&
							<Grid className={ classes.spaceFeature } item xs={ 4 }>
								<Typography variant="subtitle1"  align="right" color="textPrimary">
									Tama&ntilde;o de pantalla: &nbsp; <strong> { spaceDetails._cth_tamano_pantalla && spaceDetails._cth_tamano_pantalla }" </strong><br></br>
								</Typography>
							</Grid>
						}

						<Grid className={ classes.divider } item xs={ 12 }>
						</Grid>


					</Grid>



					<div className={ classes.buttonsContainer }>

						<Button variant="contained" color="primary" className={ classes.dangerButton } onClick={ this.props.onRemove }>Quitar de mis favoritos</Button>

						<Button variant="contained" className={ classes.successBtn } onClick={ this._addToCampaign }>Agregar a campa&ntilde;a</Button>

					</div>



				</CardContent>




			</Card>

		)

	}


	_addToCampaign(e){

		e.stopPropagation();

		this.props.dispatch(setSelectedFavorite(this.props.favorite));
		this.props.dispatch(setDisplayAddToCampaign(true));

	}


	_displaySpace(path){

		const { dispatch, favorite } = this.props;
		const { spaceDetails } = this.state;

		console.log(spaceDetails.postParent, favorite.postId);

		if(spaceDetails.listingType === "Multiespacios (child)"){

			dispatch(getMultispaceSpaces(spaceDetails.postParent, favorite.postId));
			dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.SPACE_DETAIL));

		} else {

			dispatch(setIframePath(`/index.php/listing/${ path }`));
			dispatch(setCurrentScreen(CURRENT_DISPLAYING_SCREEN.WELCOME));

		}



	}


}


export default withStyles(styles)(connect()(FavoriteCard));
