import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { compose, createStore } from 'redux';
import Thunk from 'redux-thunk';

// import { createMuiTheme } from "@material-ui/core";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import '@fontsource/roboto';

import { App } from './components/app';

import {
    MiddlewareRegistry,
    PersistenceRegistry,
    ReducerRegistry,
    StateListenerRegistry
} from './components/redux';

import reportWebVitals from './reportWebVitals';

import './middlewares';
import './reducers';


const theme = createTheme({

	palette: {
		primary: {
			main: '#E73022'
		},
		secondary: {
			main: '#0369D9'
		}
	},

	typography: {
		fontFamily: 'Roboto',
		h6: {
			fontSize: '1.3rem'
		}
	},

	overrides: {
		MuiButton: {
			root: {
				fontSize: '1rem',
				fontWeight: '500',
				height: '2.7rem',
				padding: '0.7rem 1.7rem'
			},

		},

		MuiContainer: {
			root: {
				paddingLeft: 0,
				paddingRight: 0
			}
		}
	}

});


function _createStore() {

    const reducer = ReducerRegistry.combineReducers();

    let middleware = MiddlewareRegistry.applyMiddleware(Thunk);

    let devToolsExtension;

    if (typeof window === 'object'
            && (devToolsExtension = window.devToolsExtension)) {
        middleware = compose(middleware, devToolsExtension());
    }

    const store = createStore(
        reducer, PersistenceRegistry.getPersistedState(), middleware);

    StateListenerRegistry.subscribe(store);

    return store;
}




ReactDOM.render(
	<Provider store={ _createStore() }>
		
			<ThemeProvider theme={ theme }>
				<App />
			</ThemeProvider>
		
	</Provider>
	, document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
