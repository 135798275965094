// @flow
import React, { Component } from 'react';

import { Button, Grid, Modal } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import type { Dispatch } from 'redux';

import { SPACES_SCREENS } from '../../mySpaces/constants';
import { setMySpacesScreen } from '../../mySpaces/actions';
import { connect } from '../../redux';

import { mapStyle } from '../mapStyle';

import GroupRequest from './groupRequest';
import SpaceContract from './spaceContract';


const styles = theme => ({

	addButton: {
		borderWidth: '2px',
		fontSize: '1.4rem',
		marginTop: '1rem',
		padding: '0.7rem 1rem',
		position: 'sticky',
		top: '39rem'
	},

	mapContainer:{

		backgroundColor: "#cccccc",
		height:'50vh',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '8px',
		position: 'sticky !important',
		top: '7rem',
		width:'97%',

	},

	mapSection: {
		[theme.breakpoints.down("sm")]: {
            display: "none"
        },
	},

	modalContainer: {
		position: 'absolute',
	    width: '90%',
	    backgroundColor: theme.palette.background.paper,
	    border: '2px solid #000',
	    boxShadow: theme.shadows[5],
	    padding: theme.spacing(2, 4, 3),
	    left: '50%',
	    top: '50%',
	    transform: 'translate(-50%, -50%)'
	}

});


type Props = {
	_currentMySpacesScreen: Number,
	_detailedCampaigns: Array<Object>,
	_editingCampaignIndex: Number,
	dispatch: Dispatch
}


class SpaceContracts extends Component<Props> {


	constructor(props){

		super(props);

		this.state = {
			isGroupRequestDisplayed: false,
			mapMarkers: [ ]
		}

		this._addMarkerToMap = this._addMarkerToMap.bind(this);
		this._displayGroupRequest = this._displayGroupRequest.bind(this);
		this._displaySearchIframe = this._displaySearchIframe.bind(this);
		this._hideGroupRequest = this._hideGroupRequest.bind(this);
		this._removeSpaceMarker = this._removeSpaceMarker.bind(this);
		this._startMarkerBounce = this._startMarkerBounce.bind(this);
		this._stopMarkerBounce = this._stopMarkerBounce.bind(this);

	}


	componentDidMount(){

		const { _detailedCampaigns, _editingCampaignIndex } = this.props;

		const _editingCampaignSpaces = _detailedCampaigns[_editingCampaignIndex].spaces;

		_editingCampaignSpaces.length > 0 &&
		window.subscribeToMapa(() => {

			this.google = window.google;

			var mapOptions = {
				zoom: 13,
				disableDoubleClickZoom: true,
				draggable: true,
				scrollwheel: true,
				panControl: false,
				center: new this.google.maps.LatLng( 25.444348 , -100.977792  ),
				mapTypeId: this.google.maps.MapTypeId.TERRAIN,
				disableDefaultUI: false
			};

			this.mapa_ = new this.google.maps.Map( document.getElementById('canvasMapa') ,mapOptions);

			const styledMapOptions = { map: this.mapa_, name: 'minimial' };
			const sMapType = new this.google.maps.StyledMapType( mapStyle, styledMapOptions);
			this.mapa_.mapTypes.set('minimal', sMapType);
			this.mapa_.setMapTypeId('minimal');

		});

	}


	static getDerivedStateFromProps(props, state){

		return { }

	}



	render(){

		const { _currentMySpacesScreen, _detailedCampaigns, _editingCampaignIndex, classes } = this.props;

		const _editingCampaignSpaces = _detailedCampaigns[_editingCampaignIndex].spaces;

		const { isGroupRequestDisplayed } = this.state;

		const pendingRequestContracts = _editingCampaignSpaces.filter(contract => contract.contractStatus === 1);

		return (

			<>

			<Grid container>

				<Grid item md={ 7 } xs={ 12 }>

					<Grid container spacing={ 2 } >

						{
							_editingCampaignSpaces.length === 0 &&
							<Grid item style={{ marginTop: '5rem', textAlign: 'center' }} xs={ 12 }>
								No se han agregado espacios a esta campa&ntilde;a
							</Grid>
						}

						{
							_editingCampaignSpaces.map((contractDetails, index) =>

								<Grid item key={ `contract_${ index }` } xs={ 12 }>

									<SpaceContract contract={ contractDetails } key={ `space_contract_${ index }` } setMarker={ this._addMarkerToMap } animateMarker={ this._startMarkerBounce } stopMarkerAnimation={ this._stopMarkerBounce } deleteMarker={ this._removeSpaceMarker }  />

								</Grid>

							)
						}

					</Grid>

				</Grid>

				<Grid className={ classes.mapSection } item sm={ 5 }>

					{
						_editingCampaignSpaces.length > 0 &&

						<div id="canvasMapa" className={ classes.mapContainer }/>

					}

					{
						_currentMySpacesScreen === SPACES_SCREENS.CAMPAIGN_CONTRACTS &&
						<Button className={ classes.addButton } variant="outlined" color="primary" onClick={ this._displaySearchIframe }>Agregar espacios a esta campa&ntilde;a</Button>
					}


				</Grid>

				{
					pendingRequestContracts.length > 0 &&
					<Grid item sm={ 12 }>
	
						<Button type="button" onClick={ this._displayGroupRequest }>Cotizar espacios de la campaña</Button>
	
					</Grid>
				}

			</Grid>

			<Modal
				open={ isGroupRequestDisplayed }
				onClose={ this._hideGroupRequest }
			>
				<div className={ classes.modalContainer }>
					<GroupRequest closeModal={ this._hideGroupRequest } />
				</div>
			</Modal>

			</>

		);

	}


	_addMarkerToMap(name, lat, lng){

		const { mapMarkers } = this.state;

		const existingMarker = mapMarkers.find(el => el.title === name);


		if(!existingMarker){

			const google = this.google;

			const position = new google.maps.LatLng(lat, lng);

			const marker = new google.maps.Marker({
				position,
				title: name,
				map: this.mapa_,

			});

			this.setState({
				mapMarkers: [ ...this.state.mapMarkers, marker ]
			}, () => {

				const bounds = new google.maps.LatLngBounds();

				this.state.mapMarkers.map(marker =>

					bounds.extend({
						lat: marker.position.lat(),
						lng: marker.position.lng()
					})

				);

				this.mapa_.fitBounds(bounds);

				if(this.mapa_.getZoom() > 17)
					this.mapa_.setZoom(17);

			});

			return marker;

		}



		return existingMarker;

	}


	_displayGroupRequest(){

		this.setState({
			isGroupRequestDisplayed: true
		});

	}


	_displaySearchIframe(){

		this.props.dispatch(setMySpacesScreen(SPACES_SCREENS.SPACES_BROWSING));

		const container = document.getElementById('spacesContainer');
		container.scrollTop = 0;

	}


	_hideGroupRequest(){

		this.setState({
			isGroupRequestDisplayed: false
		});

	}


	_removeSpaceMarker(name){

		const { mapMarkers } = this.state;

		let marker = mapMarkers.find(e => e.title === name);

		marker.setMap(null);

	}


	_startMarkerBounce(name){

		const { mapMarkers } = this.state;

		const existingMarker = mapMarkers.find(el => el.title === name);

		if(existingMarker.getAnimation() !== null)
			return;

		existingMarker.setAnimation(this.google.maps.Animation.BOUNCE);

	}


	_stopMarkerBounce(name){

		const { mapMarkers } = this.state;

		const existingMarker = mapMarkers.find(el => el.title === name);

		existingMarker.setAnimation(null);

	}

}


function _mapStateToProps(state){

	const { detailedCampaigns, editingCampaignIndex } = state['base/campaigns'];
	const { currentMySpacesScreen } = state['base/mySpaces'];

	return {
		_currentMySpacesScreen: currentMySpacesScreen,
		_detailedCampaigns: detailedCampaigns,
		_editingCampaignIndex: editingCampaignIndex
	}

}


export default connect(_mapStateToProps)(withStyles(styles)(SpaceContracts));
