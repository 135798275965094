//  @flow

import React from 'react';
import { Button, Container, Grid } from '@material-ui/core';

import { styles } from '../styles';

import AbstractDialog, {
    type Props as AbstractProps
} from './AbstractDialog';

export type Props = AbstractProps & {

}


/**
*
*	Base dialog skeleton with header, body and footer support.
*
**/
class BaseDialog extends AbstractDialog<Props> {

    render(){

        const { cancelButtonText, modalHeader, modalBody, modalFooter, okButtonText, onSubmit, submitButtonText } = this.props;

        return (

            <div style={ styles.dialogContainer } >

                <div style={ styles.modal_view }>

                    {
                        modalHeader ? (

                            <div style={ styles.modal_header }>
                                { modalHeader }
                            </div>

                        ) : null
                    }

							<Container style={ styles.modal_body } >

								{
									typeof modalBody === 'string' ?

									(
										<p style={ styles.modal_content } >

											{ modalBody }

										</p>
									) :

									(
										<Container style={ styles.modal_content }>
											{ modalBody }
										</Container>
									)

								}

								<Grid container style={ styles.buttonGroup }>
									<Grid item xs={ onSubmit ? 6 : 12 }>
										{
											onSubmit && typeof onSubmit === 'function' ?

											<Button style={ styles.cancelButton } onClick={ () => this._onCancel() }>

													{ cancelButtonText ? cancelButtonText : 'Cancel' }

											</Button>

											:

											<Button style={ styles.okButton } onClick={ () => this._onCancel() }>

													{ okButtonText ? okButtonText : 'Ok' }

											</Button>

										}
									</Grid>

									<Grid item xs={ 6 }>

									{
										onSubmit && typeof onSubmit === 'function' ?

										<Button style={ styles.okButton } onClick={ () => this._onSubmit(onSubmit) }>


												{ submitButtonText ? submitButtonText : 'Submit' }


										</Button>

										: null

									}
									</Grid>
								</Grid>

							</Container>

                    {
                        modalFooter ? (

                            <div style={ styles.modal_header }>
                                <p >{ modalFooter }</p>
                            </div>

                        ) : null
                    }


                </div>

            </div>
        )

    }


	_onCancel: () => void;

	_onSubmit: ?string => boolean;

	/**
     * Renders the content of the dialog.
     *
     * @returns {ReactElement}
     */
    _renderContent: () => Object

}


export default BaseDialog;
