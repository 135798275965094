// @flow

import React, { Component } from 'react';

import { Alert } from '../../dialog';
import { connect } from '../../redux';


class NoticeDialog extends Component {

    render(){

		const { contentKey, modalHeader, modalMessage } = this.props;

        return (
            <Alert
                modalHeader={ modalHeader }
                modalBody={ modalMessage }
                contentKey={ contentKey || 'generic.noticeDialog'}
            />
        );

    }

}

export default connect()(NoticeDialog);
