//  @flow

import { ReducerRegistry } from '../redux';

import { SET_MY_SPACES_SCREEN } from './actionTypes';
import { SPACES_SCREENS } from './constants';


const STORE_NAME = 'base/mySpaces';

const DEFAULT_STATE = {

	currentMySpacesScreen: SPACES_SCREENS.MENU

}


ReducerRegistry.register(
    STORE_NAME,
    (state = DEFAULT_STATE, action) => {

		switch (action.type) {

			case SET_MY_SPACES_SCREEN:

				return {
					...state,
					currentMySpacesScreen: action.currentMySpacesScreen
				}

			default:
				return state;

		}

	}

);
